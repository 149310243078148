import React, { useEffect, useState } from 'react';
import { Select2DropDown } from '../whealth-core-web/components/index';
import { setLocations } from 'redux/Slices';
import { useDispatch, useSelector } from 'react-redux';
import useApiManager from 'networking/ApiManager';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

function ClinicsFilterView(props) {
  console.log('ClinicsFilterView props: ', props);
  const { filterData, setFilterData, clearFiltersData } = props;
  const [selectedClinic, setSelectedClinic] = useState();
  const locations = useSelector((state) => state.sliceReducer.locations);
  const ApiClient = useApiManager();
  const dispatch = useDispatch();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const [clinicLocations, setClinicLocations] = useState([]);
  useEffect(() => {
    let obj = { clinic: selectedClinic };
    setFilterData(obj);
  }, [selectedClinic]);

  useEffect(() => {
    setSelectedClinic(filterData.clinic);
  }, [filterData]);

  useEffect(() => {
    if (!filterData?.clinic) setSelectedClinic();
  }, [clearFiltersData]);

  // useEffect(() => {
  //   const getData = async () => {
  //     //const res = await ApiClient.searchLocations();
  //     const res = await userData.role == 'doctor' ? ApiClient.getFilteredLocationsForDoctor() : ApiClient.getFilteredLocations();
  //     console.log('API Response:', res);
  //     if (res.status == 200) {
  //       let locationsData = [];
  //       res.data.map((item) => locationsData.push({ 
  //         id: JSON.stringify(item?.id), 
  //         title: `${item?.name} - (${item?.disabled ? "inactive" : "active"})` 
  //       }));
  //       console.log('Processed Locations Data:', locationsData);
  //       dispatch(setLocations(locationsData));
  //       setClinicLocations(locationsData);
  //     }
  //   };
  //   try {
  //     getData();
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await (userData.role === 'doctor' && !userData.is_clinic_head ? ApiClient.getFilteredLocationsForDoctor() : ApiClient.getFilteredLocations());
        console.log('API Response:', res);
        if (res.status === 200) {
          let locationsData = res.data.map((item) => ({
            id: JSON.stringify(item?.id),
            title: `${item?.name} - (${item?.disabled ? "inactive" : "active"})`
          }));
          console.log('Processed Locations Data:', locationsData);
          dispatch(setLocations(locationsData));
          setClinicLocations(locationsData);
        }
      } catch (e) {
        console.log('Error fetching locations:', e);
      }
    };
    getData();
  }, []);

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>Hospitals</div>;
  };
  console.log('clinicLocations: ', clinicLocations);
  const renderChips = () => {
    return (
      <>
        <div className='position-relative'>
          <div className='mt-3' />
          <Select2DropDown
            container={document.getElementById('clinic-dropdown-container')}
            className='zindex-popover'
            placement='bottomStart'
            placeholder='Select Hospital'
            data={userData.role == 'doctor' ? clinicLocations : locations}
            labelStyle={{ fontWeight: 'var(--lightFont)' }}
            onSelect={(data) => {
              setSelectedClinic(data);
            }}
            value={selectedClinic || ''}
            msgStyle={{ color: 'red' }}
            style={{ position: 'relative', width: '100%' }}
          />
          <div id='clinic-dropdown-container' style={{ position: 'absolute', bottom: '0px' }}></div>
        </div>
      </>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default ClinicsFilterView;

import React, { useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { plainLogo, loginpageLogo } from 'res/images';
import { Dropdown } from 'rsuite';
import { s3Url } from 'whealth-core-web/components/Helper';
import { profileImage, teamRoles, titlizeString } from 'whealth-core-web/components/Helper.js';
import useApiManager from 'networking/ApiManager';

function SideBar(props) {
  const navigate = useNavigate();
  const ApiClient = useApiManager();
  const [activeLink, setActiveLink] = useState('');
  const [sideBarOpen, serSideBarOpen] = useState(true);
  // const sidebarImage = sideBarOpen ? sideMenuSwitcher : sideMenuSwitcherExp;
  const sideBarClass = sideBarOpen ? 'sidebar-active' : 'sidebar-close';
  const getCockpitData = useGetCockpitDataQuery();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};

  let configData = getCockpitData?.data?.data?.business_config;

  const handleLogout = () => {
    ApiClient.currentAdminProfile()
      .then((res) => {
        window.webengage.user.logout(res.data.uuid);
        localStorage.clear();
        window.location.href = '/';
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const linksData = [
    {
      icon: 'home',
      title: 'Home',
      link: '/dashboard',
      key: 'home',
      hide: userData.role == 'ee' || userData.role == 'ee_manager' || userData.role == 'doctor',
    },
    {
      icon: 'person',
      title: 'Patient',
      link: '/patient',
      key: 'patient-direct', // 'patient'
      hide: userData.role != 'doctor',
    },
    {
      showDropdown: true,
      dropDown: {
        title: 'Patient',
        icon: 'person',
        data: [
          {
            icon: 'person',
            title: 'Patient',
            link: '/patient',
            key: 'patient',
          },
          {
            icon: 'segment',
            title: 'Segments',
            link: '/segments',
            key: 'segment',
            condition: 'enable_segment',
          },
        ],
      },
      hide: userData.role == 'ee' || userData.role == 'ee_manager' || userData.role == 'doctor',
    },
    {
      icon: 'calendar_month',
      title: 'Appointment',
      link: '/appointment',
      key: 'appointment',
      condition: 'enable_appointment',
      hide: userData.role == 'doctor'
      // hide: userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      icon: 'description',
      title: 'Article',
      link: '/articles',
      key: 'article',
      condition: 'enable_article',
      hide: userData.role == 'ee' || userData.role == 'ee_manager' || userData.role == 'doctor',
    },
    {
      icon: 'people_alt',
      title: 'Team',
      link: '/team',
      key: 'team',
      hide: userData.role == 'health_coach' || userData.role == 'ee' || userData.role == 'ee_manager' || userData.role == 'doctor',
    },
    {
      icon: 'settings',
      title: 'Settings',
      link: '/settings',
      key: 'settings',
      condition: 'enable_settings',
      hide:
        userData.role == 'doctor' ||
        userData.role == 'diet_health_coach' ||
        userData.role == 'fitness_health_coach' ||
        userData.role == 'health_coach' ||
        userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      icon: 'notifications',
      title: 'Notifications',
      link: '/notifications',
      key: 'notifications',
      condition: 'side_bar_notification',
      hide: userData.role == 'ee' || userData.role == 'ee_manager' || userData.role == 'doctor',
    },
    {
      icon: 'analytics',
      title: 'Reports',
      link: '/reports',
      key: 'side_bar_report',
      condition: 'side_bar_report',
      // hide: userData.role == 'ee' || userData.role == 'ee_manager',
    },
    {
      icon: 'shopping_cart',
      title: 'orders',
      link: '/orders',
      key: 'orders',
      hide: userData.role == 'ee' || userData.role == 'ee_manager' || configData?.findIndex((item) => item.key == 'enable_order' && item.isActive) == -1,
    },
    {
      hide: true,
      icon: 'manage_accounts',
      title: 'Cockpit',
      link: '/cockpit',
      key: 'cockpit',
    },
  ];

  let renderLink = (item, index) => {
    return (
      <NavLink
        key={index}
        to={item.link}
        className={({ isActive }) => {
          if (isActive) {
            setActiveLink(item.link);
            setBodyClass();
            return 'sideBarText activeLink';
          } else {
            return 'sideBarText';
          }
        }}
      >
        <div className='d-flex sideLinks'>
          {/* <img src={icon} className="sidebarIcon" /> */}
          <span class='material-icons'>{item.icon}</span>
          <div className='sidebarNavText'>{item.title} </div>
        </div>
      </NavLink>
    );
  };

  const renderMenuItem = (data) => {
    let filterData = [];
    data.data.map((item, index) => {
      let isShow = false;
      if (configData && Array.isArray(configData)) {
        if (item.condition) {
          configData.map((confItem) => {
            if (confItem.key == item.condition && confItem.isActive) {
              isShow = true;
            }
          });
        } else {
          isShow = true;
        }
      }
      isShow && filterData.push(item);
    });

    if (filterData.length == 1) {
      return renderLink(filterData[0], 1);
    } else if (!filterData.length) {
      return renderLink(data.data[0], 1);
    } else {
      return (
        <div className='d-flex sideLinks sideLinks-submenu'>
          <span class='material-icons'>{data.icon}</span>
          <Dropdown title={data.title}>
            {filterData.map((item, index) => {
              return renderLink(item, index);
            })}
          </Dropdown>
        </div>
      );
    }
  };

  const currentAdminProfile = () => {
    navigate(`/team/${userData.id}/edit`, {
      state: { userData },
    });
  };

  const UserAvatar = () => {
    return (
      <div style={{ minWidth: '45px', maxWidth: '200px' }} className='userDp profile-dropdown-menu ms-2 mb-4 fixed-bottom'>
          <Dropdown
            noCaret
            placement= 'rightEnd' //'bottomEnd'
            title={
              <img
                src={profileImage(userData.gender, userData.profile_url)}
                className='nav-link dropdown-toggle userImage'
                data-bs-toggle='dropdown'
                href='#'
                role='button'
                aria-expanded='false'
              />
            }
          >
            <Dropdown.Item>
              <div className='d-flex  dropdown-profile '>
                <div className='dropdown-profile-pic '>
                  <img
                    src={profileImage(userData.gender, userData.profile_url)}
                    className='nav-link dropdown-toggle userImage'
                    data-bs-toggle='dropdown'
                    href='#'
                    role='button'
                    aria-expanded='false'
                  />
                </div>
                <div className='dropdown-profile-name capitalize'>
                  {userData.full_name}
                  <span className='d-flex'>{teamRoles(userData.role)}</span>
                </div>
              </div>
            </Dropdown.Item>
            {(userData.role != 'ee' && userData.role != 'doctor') ? (
              <Dropdown.Item>
                <div onClick={currentAdminProfile} className='profilesub_menu'>
                  <span class='material-icons-outlined'>settings</span>
                  My Profile
                </div>
              </Dropdown.Item>
            ) : null}
            <Dropdown.Item>
              <div
                className='profilesub_menu'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  let windoConfirm = window.confirm('Are you sure you want to sign out?');
                  if (windoConfirm) {
                    handleLogout();
                  }
                }}
              >
                <span class='material-icons-outlined'>logout</span>
                Sign Out
              </div>
            </Dropdown.Item>
          </Dropdown>
          {
            sideBarOpen ? (
              <span className='profile-name-sidebar ms-2'>
                {userData.full_name}
              </span>
            ) : null
          }
      </div>
    )
  }

  const setBodyClass = () => {
    if (sideBarOpen) {
      document.body.classList.remove('bodyActive');
    } else {
      document.body.classList.add('bodyActive');
    }
  };

  const renderLinks = useMemo(() => {
    return linksData.map((item, index) => {
      if (item.hide) {
        return;
      }

      let returnEmpty = false;
      if (configData && Array.isArray(configData)) {
        configData.map((confItem) => {
          if (confItem.key == item.condition && !confItem.isActive) {
            returnEmpty = true;
          }
        });
      }
      if (returnEmpty) {
        return;
      }
      if (item.showDropdown && item.dropDown.data) {
        return renderMenuItem(item.dropDown);
      }
      return renderLink(item, index);
    });
  }, [activeLink, sideBarOpen, configData, userData]);

  const navBarLogo = useMemo(() => {
    return (
      <div className='d-flex flex-column sidebarLogo'>
        <img src={s3Url('logo.svg', loginpageLogo)} className='logo' />
        <img src={s3Url('dash-short-logo.svg', plainLogo)} className='sm-logo' />
      </div>
    );
  });

  return (
    <div className={`articleListSideBar stickyPosition ${sideBarClass}`}>
      <div className='sidebarScreen'>
        {navBarLogo}
        {userData?.role && renderLinks}
      </div>
      
      <UserAvatar />

      <div
        onClick={() => {
          serSideBarOpen(!sideBarOpen);
          setBodyClass();
        }}
        className='side_switcher_bar'
      >
        <span class='material-icons'></span>
      </div>
    </div>
  );
}

export default SideBar;

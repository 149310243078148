import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import auth_axios from './AuthAxios';
import axios from './Axios';
import endPoints from './endPoints';
import patientAxios from './PatientAxios';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const PATIENT_BASE_URL = 'https://dashboard.whealthstudio.com/api/v1/users/';
function useApiManager() {
  const urlParams = new URLSearchParams(window.location.search);
  const patientToken = urlParams.get('token_str');

  patientAxios.interceptors.request.use((request) => {
    request.headers['Authorization'] = `Bearer ${patientToken}`;
    return request;
  });

  const patientEndPoints = {
    GETARTICLE: (id) => `articles/${id}/`,
  };

  const endPoints = {
    ADDSUBSOURCEOPTION: `profile_questions/add_option/`,
    DUPLICATEQUESTION: (id, question_id) => `patients/${id}/behaviour_questionnaire/${question_id}/clone/`,
    DASHBOARD: 'admins/dashboard/',
    GETCONFIG: 'admins/dashboard/config/',
    SETZOHOCREDS: 'admins/zoho_tokens/',
    GETZOHOMEETINGS: 'admins/zoho_bookings/?date=month',
    GETZOHOMEETINGSAVAILABILITY: (date) => `admins/zoho_bookings/availability/?date=${date}`,
    CREATEZOHOMEETING: 'zoho_meetings/',
    DELETEZOHOMEETING: 'zoho_meetings/',
    UPDATEZOHOMEETING: 'zoho_meetings/',
    ARTICLES: 'articles/',
    ARTICLECOUNT: 'articles/count/',
    WEBENGAGE: 'web_engage/',
    FCM: 'fcm/',
    SENDOTP: 'admins/send_otp/',
    VERIFYOTP: 'admins/verify_otp/',
    ARTICLECATEGORY: 'categories/',
    NOTESCATEGORY: 'notes/categories/',
    NOTESTEMPLATE: 'notes/template/',
    GETALLDEPARTSTATUSES: 'department_status/',
    SEARCHDEPARTSTATUSES: 'department_status/search/',
    ARTICLETAGS: 'tags/',
    ADMINPROFILE: (id) => (id ? `admin_profiles/${id}/` : 'admin_profiles/'),
    USERPROFILE: 'admins/profile/',
    ADMINPROFILECOUNT: 'admin_profiles/count/',
    PATIENTCOUNT: 'patients/count/',
    SEARCHTAGS: 'tags/search/',
    SEARCATEGORIES: 'categories/search/',
    SEARCHARTICLE: 'articles/search/',
    SEARCHTEAM: 'admin_profiles/search/',
    PATIENTSEARCH: 'patients/search/',
    PATIENTLIST: 'patients/',
    REFRESH_TOKEN: 'token/refresh/',
    PROFILEQUESTIONS: 'profile_questions/',
    MEASURINGEVENT: 'measuring_events/',
    CONSUTDATAGET: 'admins/consultations/',
    GOALCREATION: 'goals/',
    GOALSEARCH: 'goals/search/',
    UNASIGNADMIN: 'unassign_admin/',
    ASIGNADMIN: 'assign_admin/',
    MEDICINECATEGORIES: 'medicine_categories/',
    MEDICINES: 'medicines/',
    MEDICINESSEARCH: 'medicines/search/',
    MEDICINECATEGORIESEARCH: 'medicines/unique_category_search/',
    PRESCRIPTIONS: 'prescriptions/',
    PATIENTSERVICE: 'patient_service/',
    PATIENTPAYMENTSERVICE: 'patient_service_payment/',

    BEHAVIORQUESTIONCREATE: 'behaviour_questionnaire/',
    BEHAVIORQUESTIONSEARCH: 'behaviour_questions/search/',
    BEHAVIORQUESTIONSCATEGORIES: 'behaviour_question_categories/',
    BEHAVIORQUESTIONCREATEPREVIOUS: 'behaviour_questionnaire/previous_questions/',
    AUDITLOG: 'audit_logs/',
    DASHBOARDCONFIG: 'admins/dashboard/config/',
    PROFILEQUESTIONSSEARCH: 'profile_questions/search/',
    PATIENTPROFILETIMELINE: 'timeline/',
    PATIENTPROFILEAUDITLOG: 'modellogs/',
    NOTES: 'notes/',
    BEHAVIOURQUESTION: 'behaviour_questions/',
    REGIMETEMPLATE: 'behaviour_question_templates/',
    GETCAREMANAGERS: 'admin_profiles/search/?role=health_coach',
    GETSERVICEDOCTORES: 'admin_profiles/search/?role=doctor',
    GETPATIENTSERVICEDOCTORES: 'service/get_doctors/',
    DIETPLAN: `diet_plan_templates/`,
    ORDERS: 'orders/',
    // DIETPLAN: `diet_plan_templates/`,
    DIETPLANS: `diet_plans/`,
    DIETPLANTEMPLATE: `diet_plan_templates/`,

    PRESCRIPTIONSCHEMA: (id, schema_id) => {
      if (schema_id) {
        return `patients/${id}/prescriptions/schema/${schema_id}/`;
      } else {
        return `patients/${id}/prescriptions/schema/`;
      }
    },

    ASSIGNTASK: 'admins/assigned_tasks/',
    REPORTTAB: 'reports/',
    PAYEETAB: 'patient_service_payment/',
    ADHERENCE: 'adherence/',
    CAREPLAN: 'care_plan/',
    STATUS: 'status/',
    DAYPART: 'day_part/',
    SEGMENTDEFINITION: 'segment/',
    SEGMENTDEFINITIONSEARCH: 'segment/search',
    GETLOCATIONS: 'locations/',
    APPOINTMENTSERVICE: 'service/',
    PRC: 'service_category/',
    SEARCHLOCATIONS: 'locations/search/',
    DAYPARTSSEARCH: 'day_part/search/',
    DIETPLAN: `diet_plan_templates/`,
    DIETPLANS: `diet_plans/`,
    DIETPLANTEMPLATE: `diet_plan_templates/`,
    DEPARTMENTS: 'department/',
    SEARCHDEPARTMENTS: 'department/search/',
    GETAPPOINTMENT: 'appointments/',
    GETAVAILABLESLOT: 'appointments/available_slots/',
    UPDATEDEPARTMENTOBJECT: (departmentId) => `department/${departmentId}/`,
    UPDATELOCATIOB: (locationId) => `locations/${locationId}/`,
    UPDATEAPPOINTMENTSERVICE: (serviceId) => `service/${serviceId}/`,
    UPDATEPRC: (PRCID) => `service_category/${PRCID}/`,

    CREATEDEPARTMENT: (patientId) => `patients/${patientId}/post_department/`,
    UPDATEDEPARTMENT: (patientId) => `patients/${patientId}/assign_department/`,
    PRESCRIPTIONDEPARTMENT: (patientId) => `patients/${patientId}/assigned_departments/`,
    DELETEDEPARTMENT: (patientId) => `patients/${patientId}/delete_department/`,
    GETFAMILYMEMBERS: (patientId) => `patients/${patientId}/get_family_members/`,
    GETDOCTORS: (query, id) => `admin_profiles/search_depatments_doctor/?full_name=${query}&department_id=${id}`,
    UPDATEPATIENTLOCATION: (id) => `patients/${id}/assign_location/`,
    MOBILEBANNER: 'mobile_banners/',
    PRESIGNERURL: 'presigner/',
    WEIGHTGRAPH: (id) => `patients/${id}/weight_graph/`,
    PAINGRAPH: (id) => `patients/${id}/pain_graph/`,

    // GETPATIENTMEETINGS: (id) => `patients/${id}/zoho_meetings/`,
    GETPATIENTMEETINGS: (id) => `patient/${id}/zoho_bookings/`,
    CREATEPATIENTMEETINGS: (id) => `admins/${id}/zoho_bookings/`,
    GETDOCTORSERVICES: (id) => `admins/${id}/services/`,
    UPDATEPATIENTMEETINGS: (id) => `admins/reshedule/zoho_bookings/${id}/`,
    TEAMGET: (id) => `admin_profiles/${id}/`,
    TEAMWORKINGHOUR: (id) => `working_slots/?doctor_id=${id}`,
    DELETETEAMWORKINGHOUR: (id) => `working_slots/${id}/appointment_slot_delete`,
    GETALLDOCTOR: 'appointment_slots/get_doctor_appointments/',
    SLOTSCOUNT: (bookingDate, doctorId) => `appointment_slots/get_time_slot/?booking_date=${bookingDate}&doctor_id=${doctorId}&location_id=all`,
    TASKS: (id) => `patients/${id}/tasks/`,
    APPOINMENT_PAYMENT_URL: (id, oid) => `admins/${id}/payment/generate_payment_url/${oid}/`,
    REFRESH_PAYMENT_URL: (payment_id) => `admins/payment/payment_url_info/${payment_id}`,
    APPOINTMENTSSCHEDULE: (consult_datetime, doctor_id) => `admins/doctor_appointments/?consult_datetime=${consult_datetime}&doctor_id=${doctor_id}`,

    ACTIVITYLOGS: (id) => `patients/${id}/activity_logs/`,
    ACTIVITYLOGSONCHANGE: (id) => `patients/${id}/activity_logs/latest_activity/`,
    SEAECHTASKASSIGNTOSEARCH: (id) => `patients/${id}/tasks/assign_to_search/`,
    ACTIVEPRESCTIPTION: (id) => `patients/${id}/prescriptions/active/`,
    Attatchgoal: (id) => `/goals/${id}/`,
    GETSLOTSCONSULTATION: (date, doctorId, locationId) =>
      `appointment_slots/get_time_slot/?booking_date=${date}&doctor_id=${doctorId}&location_id=${locationId}`,

    PATIENTGOALS: (id, goalId) => {
      if (goalId) {
        return `patients/${id}/goals/${goalId}/`;
      } else {
        return `patients/${id}/goals/`;
      }
    },

    dietPlanList: (id, pid) => {
      if (pid) {
        return `patients/${id}/diet_plan_templates/${pid}/`;
      } else {
        return `patients/${id}/diet_plan_templates/`;
      }
    },

    PATIENTGOALSSEARCH: (id) => {
      if (id) {
        return `patients/${id}/goals/search/`;
      }
    },
    GETPATIENTREPORTS: (id, reportid) => {
      if (reportid) {
        return `patients/${id}/reports/${reportid}/`;
      } else {
        return `patients/${id}/reports/`;
      }
    },
    GETPATIENTREPORTSERVICE: (id, reportid, step) => {
      if (reportid) {
        return `patients/${id}/reports/${reportid}/`;
      } else {
        return `patients/${id}/reports/?status=${`step_${step}`}`;
      }
    },
    GETPATIENTPAYEE: (id, reportid) => {
      if (reportid) {
        return `patients/${id}/patient_service_payment/${reportid}/`;
      } else {
        return `patients/${id}/patient_service_payment/`;
      }
    },
    PRESCRIPTIONSLIST: (id, pid) => {
      if (pid) {
        return `patients/${id}/prescriptions/${pid}/`;
      } else {
        return `patients/${id}/prescriptions/`;
      }
    },
    dietPlanTemplateList: (id, pid) => {
      if (pid) {
        return `patients/${id}/diet_plans/${pid}/`;
      } else {
        return `patients/${id}/diet_plans/`;
      }
    },
    behaviourList: (id, pid) => {
      if (pid) {
        return `patients/${id}/behaviour_questionnaire/${pid}/`;
      } else {
        return `patients/${id}/behaviour_questionnaire/`;
      }
    },

    PRESCRIPTIONMEDICINE: (id, pid, mid) => {
      if (mid) {
        return `patients/${id}/prescriptions/${pid}/medicines/${mid}/`;
      } else {
        return `patients/${id}/prescriptions/${pid}/medicines/`;
      }
    },
    SUBSCRIPTION: (id) => `patients/${id}/subscriptions/extend/`,
    PRESCRIPTIONPDFDOWNLOAD: (id, pid) => `/patients/${id}/prescriptions/${pid}/pdf/`,
    QUESTIONPDFDOWNLOAD: (id, pid) => `/patients/${id}/behaviour_questionnaire/${pid}/pdf/`,
    DIETPLANPDFDOWNLOAD: (id, pid) => `/patients/${id}/diet_plan_templates/${pid}/pdf/`,
    DIETPLANPDF: (id, pid) => `/patients/${id}/diet_plans/${pid}/pdf/`,
    PROFILEANSWERS: (id) => `/patients/${id}/profile_answers/`,
    PROFILEANSWERSCHECKIN: (id, checkInId) => `/patients/${id}/prescriptions/${checkInId}/update_schema/`,
    ACTIVITYLOGACTION: (id, logId) => `/patients/${id}/activity_logs/${logId}/`,
    PRESCRIPTIONSUMMERY: (id) => `patients/${id}/prescription_summary/`,
    SUMMARYPDFDOWNLOAD: (id, summeryId) => `/patients/${id}/prescription_summary/${summeryId}/pdf/`,
    PRESCRIPTIONSUMMERYPUBLISH: (id, summeryId) => `patients/${id}/prescription_summary/${summeryId}/publish/`,
    PRESCRIPTIONSUMMERYUNPUBLISH: (id, summeryId) => `patients/${id}/prescription_summary/${summeryId}/unpublish/`,
    PRESCRIPTIONSUMMERYGETSINGLE: (id, summeryId) => `patients/${id}/prescription_summary/${summeryId}/`,
    PUBLISHEDPRESCRIPTION: (id) => `patients/${id}/prescriptions/default_published_prescription/`,
    GETCRPSESSIONLEFT: (id, patientId, crpType) => `patients/${id}/patient_service/${patientId}/get_sessions_left`,
  };

  // create auth apis here

  const sendOTP = async (params) => {
    return axios.post(endPoints.SENDOTP, params);
  };

  const verifyOTP = async (params) => {
    return axios.post(endPoints.VERIFYOTP, params);
  };

  const statusDietPlanPublish = async (id, DietPlanId) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLAN + DietPlanId + '/' + 'publish' + '/');
  };

  const createDietPlan = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLAN, params);
  };

  const getDietPlan = async (id, params, pid) => {
    return auth_axios.get(endPoints.dietPlanList(id, pid), {
      params: { ...params },
    });
  };

  const updateDietPlan = async (id, pid, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLAN + pid + '/', data);
  };

  const duplicateDietplan = async (id, question_id) => {
    return auth_axios.get(endPoints.DUPLICATEQUESTION(id, question_id));
  };

  const dietPlanPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.DIETPLANPDFDOWNLOAD(id, pid));
  };

  const deleteDietPlan = async (id, dietPlanId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLAN + dietPlanId + '/');
  };

  // create apis here

  const getConfig = async () => {
    return auth_axios.get(endPoints.GETCONFIG);
  };

  const prescriptionSchema = async (patient_id, schema_id, params) => {
    return auth_axios.get(endPoints.PRESCRIPTIONSCHEMA(patient_id, schema_id), {
      params,
    });
  };

  const setZohoCreds = async (params) => {
    return auth_axios.post(endPoints.SETZOHOCREDS, params);
  };

  const prescriptionSummeryCreate = async (id, params) => {
    return auth_axios.post(endPoints.PRESCRIPTIONSUMMERY(id), params);
  };

  const getZohoMeetings = async () => {
    return auth_axios.get(endPoints.GETZOHOMEETINGS);
  };

  const getZohoMeetingsAvailability = async (date) => {
    return auth_axios.get(endPoints.GETZOHOMEETINGSAVAILABILITY(date));
  };

  const prescriptionMDTSummeryList = async (id, params) => {
    return auth_axios.get(endPoints.PRESCRIPTIONSUMMERY(id), { params });
  };

  const getBehaviourQuestionCategories = async (id, params) => {
    return auth_axios.get(endPoints.BEHAVIORQUESTIONSCATEGORIES);
  };

  const prescriptionMDTSummerygetSingle = async (id, summeryId) => {
    return auth_axios.get(endPoints.PRESCRIPTIONSUMMERYGETSINGLE(id, summeryId));
  };

  const getZohoMeetingsForPatient = async (id, params) => {
    return auth_axios.get(endPoints.GETPATIENTMEETINGS(id), { params });
  };

  const getZohoMeeting = async (id) => {
    return auth_axios.get(endPoints.GETZOHOMEETINGS + id);
  };

  const getDocServices = async (id) => {
    return auth_axios.get(endPoints.ADMINPROFILE() + id + '/services/');
  };

  const getLocationByAdmin = async (id, params) => {
    return auth_axios.get(endPoints.ADMINPROFILE(id) + 'locations/', {
      params: { ...params },
    });
  };

  const getAvailableSlots = async (params) => {
    return auth_axios.get(endPoints.GETAVAILABLESLOT, {
      params: { ...params },
    });
  };

  const createZohoMeeting = async (params) => {
    return auth_axios.post(endPoints.CREATEZOHOMEETING, params);
  };

  const createZohoBooking = async (params, id) => {
    return auth_axios.post(endPoints.CREATEPATIENTMEETINGS(id), params);
  };

  const updateZohoBooking = async (params, id) => {
    return auth_axios.post(endPoints.UPDATEPATIENTMEETINGS(id), params);
  };

  const teamGet = async (id) => {
    return auth_axios.get(endPoints.TEAMGET(id));
  };

  const getTeamWorkingHours = async (id, isExtraSlot) => {
    return auth_axios.get(endPoints.TEAMWORKINGHOUR(id) + `&is_extra_slot=${isExtraSlot ? 1 : 0}`);
  };

  const getAllDoctorList = async () => {
    return auth_axios.get(endPoints.GETALLDOCTOR);
  };

  const getSlotsCount = async (bookingDate, doctorId) => {
    return auth_axios.get(endPoints.SLOTSCOUNT(bookingDate, doctorId));
  };

  const generatePaymentLink = async (user, order, data) => {
    return auth_axios.post(endPoints.APPOINMENT_PAYMENT_URL(user, order), data);
  };

  const refreshPaymentLink = async (id) => {
    return auth_axios.get(endPoints.REFRESH_PAYMENT_URL(id));
  };

  const getAppointmentSchedule = async (consult_datetime, doctor_id) => {
    return auth_axios.get(endPoints.APPOINTMENTSSCHEDULE(consult_datetime, doctor_id));
  };

  const deleteTeamWorkingHours = async (id) => {
    return auth_axios.delete(endPoints.DELETETEAMWORKINGHOUR(id));
  };

  const deleteZohoMeeting = async (id) => {
    return auth_axios.delete(endPoints.DELETEZOHOMEETING + id + '/');
  };

  const updateZohoMeeting = async (id, params) => {
    return auth_axios.put(endPoints.UPDATEZOHOMEETING + id + '/', params);
  };

  const createNotify = async (segmentId) => {
    return auth_axios.post(BASE_URL + endPoints.SEGMENTDEFINITION + segmentId + '/notify/');
  };

  const createRegisterEvent = async (segmentId) => {
    return auth_axios.post(BASE_URL + endPoints.SEGMENTDEFINITION + segmentId + '/register_event/');
  };

  const updateNotify = async (segmentId, params) => {
    return auth_axios.patch(BASE_URL + endPoints.SEGMENTDEFINITION + segmentId + '/', params);
  };

  const activityLogsCreate = async (id, params) => {
    return auth_axios.post(endPoints.ACTIVITYLOGS(id), params);
  };

  const createProfileAnswers = async (id, params) => {
    return auth_axios.post(endPoints.PROFILEANSWERS(id), params);
  };

  const createSubscription = async (id, params) => {
    return auth_axios.post(endPoints.SUBSCRIPTION(id), params);
  };

  const createProfileAnswersNextCheckIn = async (id, checkInId, params) => {
    return auth_axios.patch(endPoints.PROFILEANSWERSCHECKIN(id, checkInId), params);
  };

  const createTasks = async (params, id) => {
    return auth_axios.post(endPoints.TASKS(id), params);
  };

  // const createBehaviourQuestion = async (params, id) => {
  //   return auth_axios.post(endPoints.BEHAVIOURQUESTION(id), params);
  // };

  const createBehaviourQuestion = async (params) => {
    return auth_axios.post(endPoints.BEHAVIOURQUESTION, params);
  };

  const createRegimeTemplate = async (params) => {
    return auth_axios.post(endPoints.REGIMETEMPLATE, params);
  };

  const updateRegimeTemplate = async (id, params) => {
    return auth_axios.patch(endPoints.REGIMETEMPLATE + id + '/', params);
  };

  const createDietTemplate = async (params) => {
    return auth_axios.post(endPoints.DIETPLANTEMPLATE, params);
  };

  const getDietTemplateList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.DIETPLANTEMPLATE,
      method: 'get',
      params: { ...pages },
    });
  };

  const getDietTemplate = async (id) => {
    return auth_axios.get(endPoints.DIETPLANTEMPLATE + id + '/');
  };

  const searchDietTemplateList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.DIETPLANTEMPLATE + 'search/',
      method: 'get',
      params: { ...pages },
    });
  };

  const deleteDietTemplate = async (id) => {
    return auth_axios.delete(endPoints.DIETPLANTEMPLATE + id + '/');
  };

  const updateDietTemplate = async (id, params) => {
    return auth_axios.patch(endPoints.DIETPLANTEMPLATE + id + '/', params);
  };

  const getCareManagers = async () => {
    return auth_axios.get(endPoints.GETCAREMANAGERS);
  };

  const getCareManagersByClinic = async (id) => {
    return auth_axios.get(endPoints.ADMINPROFILE() + 'search/?role=health_coach,health_coach_lead&clinic=' + id);
  };

  const getRevenueManagersByClinic = async (id) => {
    return auth_axios.get(endPoints.ADMINPROFILE() + 'search/?role=revenue_manager&clinic=' + id);
  };
  const getCareManagersByCareManager = async (id) => {
    return auth_axios.get(endPoints.ADMINPROFILE() + 'search/?role=health_coach_lead' + id);
  };
  const getRevenueManagersByRevenueManager = async (id) => {
    return auth_axios.get(endPoints.ADMINPROFILE() + 'search/?role=revenue_manager' + id);
  };

  const prescriptionCreate = async (params, id, extraParam) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS, params, extraParam);
  };

  const patientServiceCreate = async (formData, id, extraParam) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTSERVICE, formData, {
        ...extraParam,
        headers: {
            ...extraParam?.headers,
            'Content-Type': 'multipart/form-data',
        },
    });
  };

  const patientPaymentServiceCreate = async (params, id, extraParam) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTPAYMENTSERVICE, params, extraParam);
  };
  const consultationCreate = async (params, patientId) => {
    return auth_axios.post(endPoints.PATIENTLIST + patientId + '/consultation/', params);
  };

  const consultationUpdate = async (params, patientId) => {
    return auth_axios.post(endPoints.PATIENTLIST + patientId + '/consultation/' + params.id + '/checkin/', params);
  };

  const consultationList = async (params, patientId) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/appointments/', { params: { ...params } });
  };

  const consultationDetail = async (consultationId, patientId) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/consultation/' + consultationId + '/');
  };

  const consultationDelete = async (consultationId, patientId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + patientId + '/consultation/' + consultationId + '/');
  };

  const consultationClose = async (consultationId, patientId) => {
    return auth_axios.post(endPoints.PATIENTLIST + patientId + '/consultation/' + consultationId + '/closed/');
  };

  const patientAttendenceStatus = async (consultationId, patientId, selectedStatus) => {
    console.log('selectedStatus: ', selectedStatus);
    const body = {
      patient_attendance_status: selectedStatus,
    };
    return auth_axios.post(endPoints.PATIENTLIST + patientId + '/consultation/' + consultationId + '/patient_attendance_status/', body);
  };

  const mdtSUmmaryUnpublish = async (id, mdt_id) => {
    return auth_axios.post(endPoints.PRESCRIPTIONSUMMERYUNPUBLISH(id, mdt_id));
  };
  const mdtSUmmaryPublish = async (id, mdt_id) => {
    return auth_axios.post(endPoints.PRESCRIPTIONSUMMERYPUBLISH(id, mdt_id));
  };

  const behaviorQuestionCreate = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATE, params);
  };

  const statusActivate = async (id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + 'activate' + '/');
  };

  const statusOnboarding = async (id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + 'onboarding' + '/');
  };

  const statusLapsed = async (id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + 'lapsed' + '/');
  };

  const notes = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.NOTES, params);
  };

  const statusPublish = async (id, prescriptionId) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS + prescriptionId + '/' + 'publish' + '/');
  };

  const statusQuestionPublish = async (id, prescriptionId) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATE + prescriptionId + '/' + 'publish' + '/');
  };

  const statusUnpublished = async (id, prescriptionId) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS + prescriptionId + '/' + 'unpublish' + '/');
  };

  const painGraph = async (id, date) => {
    return auth_axios.get(endPoints.PAINGRAPH(id), { params: { ...date } });
  };

  const createMedicines = async (params) => {
    return auth_axios.post(endPoints.MEDICINES, params);
  };

  const searchMedicinesName = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.MEDICINESSEARCH,
      params: { search_str: params },
      method: 'get',
    });
  };

  const createDepartmentModel = async (params) => {
    return auth_axios.post(endPoints.DEPARTMENTS, params);
  };

  const createClinicModel = async (params) => {
    return auth_axios.post(endPoints.GETLOCATIONS, params);
  };

  const createAppointmentService = async (params) => {
    return auth_axios.post(endPoints.APPOINTMENTSERVICE, params);
  };
  const createPRC = async (params) => {
    return auth_axios.post(endPoints.PRC, params);
  };

  const createMedicinesApprove = async (id) => {
    return auth_axios.post(endPoints.MEDICINES + id + '/publish/');
  };
  const createMedicinesUnApprove = async (id) => {
    return auth_axios.post(endPoints.MEDICINES + id + '/unpublish/');
  };
  const createMedicinesDraft = async (params) => {
    return auth_axios.post(endPoints.MEDICINES + '?status=draft', params);
  };

  const createPatient = async (params) => {
    return auth_axios.post(endPoints.PATIENTLIST, params);
  };

  const createPatientStatus = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + 'update_status' + '/', params);
  };

  // const updatePatientSubStatus = async (params, id) => {
  //   return auth_axios.patch(endPoints.PATIENTLIST + id + '/', params);
  // };

  const createFollowUp = async (consultationId, patientId, params) => {
    return auth_axios.post(endPoints.PATIENTLIST + patientId + '/consultation/' + consultationId + '/create_follow_up/', { ...params });
  };

  const createProfileQuestion = async (params) => {
    return auth_axios.post(endPoints.PROFILEQUESTIONS, params);
  };

  const createPatientGoal = async (id, params) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.GOALCREATION, params);
  };
  const createPatientReport = async (id, params) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.REPORTTAB, params);
  };
  const createPatientPaymentDocs = async (id, params) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.PAYEETAB + 'record_payment/', params);
  };

  const createMeasuringEvent = async (params) => {
    return auth_axios.post(endPoints.MEASURINGEVENT, params);
  };

  const createGoalCreation = async (params) => {
    return auth_axios.post(endPoints.GOALCREATION, params);
  };

  const createProfile = async (params) => {
    return auth_axios.post(endPoints.ADMINPROFILE(), params);
  };

  const createArticles = async (params) => {
    return auth_axios.post(endPoints.ARTICLES, params);
  };

  const createCategories = async (params) => {
    return auth_axios.post(endPoints.ARTICLECATEGORY, params);
  };

  const createNotesCategories = async (params) => {
    return auth_axios.post(endPoints.NOTESCATEGORY, params);
  };

  const createNotesTemplate = async (params) => {
    return auth_axios.post(endPoints.NOTESTEMPLATE, params);
  };

  const createDepartmentStatus = async (params) => {
    return auth_axios.post(endPoints.GETALLDEPARTSTATUSES, params);
  };

  const CompeleteTasks = async (id, taskId) => {
    return auth_axios.post(endPoints.TASKS(id) + taskId + '/' + 'completed/');
  };

  const createArticleTags = async (params) => {
    return auth_axios.post(endPoints.ARTICLETAGS, params);
  };

  // delete apis here
  const deleteRegimeTemplate = async (id) => {
    return auth_axios.delete(endPoints.REGIMETEMPLATE + id + '/');
  };

  const deleteTask = async (pid, id) => {
    return auth_axios.delete(endPoints.TASKS(pid) + id + '/');
  };

  const prescriptionMDTSummeryDelete = async (id, summeryId) => {
    return auth_axios.delete(endPoints.PRESCRIPTIONSUMMERYGETSINGLE(id, summeryId));
  };

  const deleteMeasuringEvent = async (id) => {
    return auth_axios.delete(endPoints.MEASURINGEVENT + id + '/');
  };

  const deletebanner = async (id) => {
    return auth_axios.delete(endPoints.MOBILEBANNER + id + '/');
  };
  const deleteGoals = async (id) => {
    return auth_axios.delete(endPoints.GOALCREATION + id + '/');
  };

  const deleteNotes = async (id, notesId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.NOTES + notesId + '/');
  };

  const deleteReports = async (id, reportid) => {
    return await auth_axios({
      url: BASE_URL + endPoints.GETPATIENTREPORTS(id, reportid),
      method: 'delete',
    });
  };
  const deletePayee = async (id, reportid) => {
    return await auth_axios({
      url: BASE_URL + endPoints.GETPATIENTPAYEE(id, reportid),
      method: 'delete',
    });
  };
  const deleteAttatchGoal = async (id, notesId, params) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.GOALCREATION + notesId + '/', {
      params: { ...params },
    });
  };
  const profileQuestionDelete = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.PROFILEQUESTIONS + id + '/',
      method: 'delete',
    });
  };
  const profileBehaviorQuestionDelete = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.BEHAVIOURQUESTION + id + '/',
      method: 'delete',
    });
  };

  const deleteArticleImage = async (id) => {
    return auth_axios.delete(`${endPoints.ARTICLES + id}/remove_image/`);
  };

  const deleteQuestions = async (id, questionId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATE + questionId + '/');
  };

  const deleteMedicines = async (id) => {
    return auth_axios.delete(`${endPoints.MEDICINES + id}/`);
  };
  const deletePrescription = async (id, questionId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS + questionId + '/');
  };
  const deleteService = async (id, questionId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTSERVICE + questionId + '/');
  };

  const articleCategoryDelete = async (id) => {
    return auth_axios.delete(`${endPoints.ARTICLECATEGORY + id}/`);
  };

  const notesCategoryDelete = async (id) => {
    return auth_axios.delete(`${endPoints.NOTESCATEGORY + id}/`);
  };

  const departmentStatusDelete = async (id) => {
    return auth_axios.delete(`${endPoints.GETALLDEPARTSTATUSES + id}/`);
  };

  const articleTagsDelete = async (id) => {
    return auth_axios.delete(`${endPoints.ARTICLETAGS + id}/`);
  };

  // get apis here

  const getNotes = async (id, page) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.NOTES, {
      params: { ...page },
    });
  };

  const getCategoriesTampletNotes = async (id, notesId) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.NOTES + notesId + '/', {});
  };

  const getActivePrescription = async (id, page) => {
    return auth_axios.get(endPoints.ACTIVEPRESCTIPTION(id));
  };

  const duplicateRegimeQuestions = async (id, question_id) => {
    return auth_axios.get(endPoints.DUPLICATEQUESTION(id, question_id));
  };

  const searchTaskAssign = async (id, qry) => {
    return auth_axios.get(endPoints.SEAECHTASKASSIGNTOSEARCH(id), {
      params: { ...qry },
    });
  };

  const getPublishedPrescriptionData = async (id) => {
    return auth_axios.get(endPoints.PUBLISHEDPRESCRIPTION(id));
  };

  const allStatus = async (status) => {
    return auth_axios.get(endPoints.STATUS, { params: { ...status } });
  };

  const getGoals = async (page) => {
    return auth_axios.get(endPoints.GOALCREATION, { params: { ...page } });
  };
  const getMedicinecategories = async (page) => {
    return auth_axios.get(endPoints.MEDICINECATEGORIES, {
      params: { ...page },
    });
  };

  const getAttatchgoal = async (id, page) => {
    return auth_axios.get(endPoints.Attatchgoal(id));
  };

  const getSlotsConsultation = async (date, doctorId, locationId) => {
    return auth_axios.get(endPoints.GETSLOTSCONSULTATION(date, doctorId, locationId));
  };

  const getAssignTask = async (page) => {
    return auth_axios.get(endPoints.ASSIGNTASK, {
      params: { ...page },
    });
  };

  const getSearchSegment = async (qry) => {
    return auth_axios.get(BASE_URL + endPoints.SEGMENTDEFINITIONSEARCH, {
      params: {
        ...qry,
      },
    });
  };

  const getSegment = async () => {
    return auth_axios.get(BASE_URL + endPoints.SEGMENTDEFINITION);
  };

  const showTasks = async (id, page) => {
    return auth_axios.get(endPoints.TASKS(id), { params: { ...page } });
  };

  const getPresignedUrl = async (params) => {
    return auth_axios.get(endPoints.PRESIGNERURL, { params: { ...params } });
  };
  const weightGraph = async (id, date) => {
    return auth_axios.get(endPoints.WEIGHTGRAPH(id), { params: { ...date } });
  };

  const getDashBoardConfigData = async () => {
    return auth_axios.get(endPoints.DASHBOARDCONFIG);
  };

  const getAUDITLOG = async (pageQry) => {
    return auth_axios.get(endPoints.AUDITLOG, { params: { ...pageQry } });
  };

  const prescriptionList = async (id, params, pid) => {
    return auth_axios.get(endPoints.PRESCRIPTIONSLIST(id, pid), {
      params: { ...params },
    });
  };

  const createWebEngage = async (audience, notificationTemplateId, params) => {
    return auth_axios.post(endPoints.WEBENGAGE + audience + '/notification_templates/' + notificationTemplateId + '/notification_messages/', params);
  };

  const createFcm = async (audience, notificationTemplateId, params) => {
    return auth_axios.post(endPoints.FCM + audience + '/notification_templates/' + notificationTemplateId + '/notification_messages/', params);
  };

  const getFcmData = async (audience) => {
    return auth_axios.get(endPoints.FCM + audience + '/notification_templates/');
  };

  const getSingleFcmData = async (audience, id) => {
    return auth_axios.get(endPoints.FCM + audience + '/notification_templates/' + id + '/');
  };

  const getWebEngageData = async (audience) => {
    return auth_axios.get(endPoints.WEBENGAGE + audience + '/notification_templates/');
  };

  const getSingleWebEngageData = async (audience, id) => {
    return auth_axios.get(endPoints.WEBENGAGE + audience + '/notification_templates/' + id + '/');
  };

  const behaviourQuestionList = async (id, params, pid) => {
    return auth_axios.get(endPoints.behaviourList(id, pid), {
      params: { ...params },
    });
  };

  const prescriptionMedicine = async (id, pid, mid, params) => {
    return auth_axios.get(endPoints.PRESCRIPTIONMEDICINE(id, pid, mid), {
      params: { ...params },
    });
  };

  const patientProfileTimeline = async (id, page) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTPROFILETIMELINE, { params: { ...page } });
  };

  const patientProfileAuditLog = async (id, page) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTPROFILEAUDITLOG, { params: { ...page } });
  };

  const prescriptionData = async (id, pid) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS + pid + '/');
  };
  const singleDataService = async (id, pid) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTSERVICE + pid + '/');
  };

  const singleDataPaymentService = async (id, pid) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTPAYMENTSERVICE + pid + '/');
  };

  const getCarePlanNotification = async (id, pages) => {
    return auth_axios.get(endPoints.ADMINPROFILE() + id + '/notifications/', {
      params: { ...pages },
    });
  };

  const getCarePlanMarkDoneNotification = async (id, notificationId, params) => {
    return auth_axios.patch(endPoints.ADMINPROFILE() + id + '/notifications/' + notificationId + '/mark/', params);
  };

  const getCallLog = async (id) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/patient_bmi_performance/');
  };

  const questionData = async (id, pid) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATE + pid + '/');
  };

  const questionDataPrevious = async (id) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATEPREVIOUS);
  };

  const medicineCategoriesGet = async () => {
    return auth_axios.get(endPoints.MEDICINECATEGORIES);
  };

  const getMedicines = async (pageQry, params) => {
    return auth_axios.get(endPoints.MEDICINES + '?status=published', {
      params: { ...pageQry },
    });
  };

  const getMedicinesSetting = async (pageQry, params) => {
    return auth_axios.get(endPoints.MEDICINES, { params: { ...pageQry } });
  };

  const getDayParts = async (pageQry, params) => {
    return auth_axios.get(endPoints.DAYPART, { params: { ...pageQry } });
  };

  const search = async (method, qry, id) => {
    let searchQry = endPoints[method];
    if (id) {
      searchQry = endPoints[method](id);
    }
    return auth_axios.get(searchQry, {
      params: qry,
    });
  };

  const getMeasuringEvent = async (pages) => {
    return auth_axios.get(endPoints.MEASURINGEVENT, { params: { ...pages } });
  };

  const getConsultationDataApi = async (pages) => {
    return auth_axios.get(endPoints.CONSUTDATAGET, { params: { ...pages } });
  };
  const getBannerDetail = async (pages) => {
    return auth_axios.get(endPoints.MOBILEBANNER, { params: { ...pages } });
  };
  const activityLogsOnchange = async (id, params) => {
    return auth_axios.get(endPoints.ACTIVITYLOGSONCHANGE(id), {
      params: { ...params },
    });
  };

  const getGoalEvent = async () => {
    return auth_axios.get(endPoints.GOALCREATION);
  };

  const searchTags = async (qry) => {
    return auth_axios.get(endPoints.SEARCHTAGS, {
      params: {
        search_str: qry,
      },
    });
  };

  const getProfileQuestions = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.PROFILEQUESTIONS,
      method: 'get',
      params: { ...pages },
    });
  };

  const getBehaviourQuestions = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.BEHAVIOURQUESTION,
      method: 'get',
      params: { ...pages },
    });
  };

  const getTemplateList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.REGIMETEMPLATE,
      method: 'get',
      params: { ...pages },
    });
  };

  const searchTemplateList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.REGIMETEMPLATE + 'search/',
      method: 'get',
      params: { ...pages },
    });
  };

  const getRegimeTemplate = async (id) => {
    return auth_axios.get(endPoints.REGIMETEMPLATE + id + '/');
  };

  const getCarePlanTemplate = async (id) => {
    return auth_axios.get(endPoints.CAREPLAN + id + '/');
  };

  const getCareSearch = async (qry) => {
    return auth_axios.get(endPoints.CAREPLAN + 'search/', {
      params: {
        search_str: qry,
      },
    });
  };

  const getCarePlanDropDownData = async () => {
    return auth_axios.get(endPoints.CAREPLAN + 'meta/');
  };

  const searchCategory = async (qry) => {
    return auth_axios.get(endPoints.SEARCATEGORIES, {
      params: {
        search_str: qry,
      },
    });
  };

  const searchNotesCategory = async (qry) => {
    return auth_axios.get(endPoints.NOTESCATEGORY + 'search/', {
      params: {
        title: qry,
      },
    });
  };

  const searchNotesTemplate = async (qry) => {
    return auth_axios.get(endPoints.NOTESTEMPLATE + 'search/', {
      params: {
        search_str: qry,
      },
    });
  };

  const getAdminProfilesCount = async (filters) => {
    return auth_axios.get(BASE_URL + endPoints.ADMINPROFILECOUNT, {
      params: { ...filters },
    });
  };

  const getPatientCount = async (qryParams) => {
    return auth_axios.get(BASE_URL + endPoints.PATIENTCOUNT, {
      params: {
        ...qryParams,
      },
    });
  };

  const getDashBoardData = async (qryParams) => {
    return auth_axios({
      url: BASE_URL + endPoints.DASHBOARD,
      method: 'get',
      params: { ...qryParams },
    });
  };

  const currentAdminProfile = async (token) => {
    return auth_axios({
      url: BASE_URL + endPoints.USERPROFILE,
      method: 'get',
    });
  };

  const getAdminProfile = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ADMINPROFILE(id),
      method: 'get',
    });
  };

  const getPatientProfile = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.PATIENTLIST + id + '/',
      method: 'get',
    });
  };

  const getPatientDetails = async (id) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/');
  };

  const getPatientgoals = async (id, goalid, params) => {
    return await auth_axios({
      url: BASE_URL + endPoints.PATIENTGOALS(id, goalid),
      method: 'get',
      params: { ...params },
    });
  };

  const searchPatientGoals = async (id, params) => {
    return await auth_axios({
      url: BASE_URL + endPoints.PATIENTGOALSSEARCH(id),
      method: 'get',
      params: { ...params },
    });
  };

  const getPatientReports = async (id, reportid, params) => {
    return await auth_axios({
      url: BASE_URL + endPoints.GETPATIENTREPORTS(id, reportid),
      method: 'get',
      params: { ...params },
    });
  };

  const getPatientReportsService = async (id, reportid, step, params) => {
    return await auth_axios({
      url: BASE_URL + endPoints.GETPATIENTREPORTSERVICE(id, reportid, step),
      method: 'get',
      params: { ...params },
    });
  };

  const getPatientPaymentDocs = async (id, reportid, params) => {
    return await auth_axios({
      url: BASE_URL + endPoints.GETPATIENTPAYEE(id, reportid),
      method: 'get',
      params: { ...params },
    });
  };

  const getDepartment = async (page) => {
    let url = BASE_URL + endPoints.DEPARTMENTS;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const prescriptionDepartments = async (id, data) => {
    return await auth_axios.get(endPoints.PRESCRIPTIONDEPARTMENT(id), data);
  };

  const searchDepartments = async (query) => {
    let url = BASE_URL + endPoints.SEARCHDEPARTMENTS;
    if (query) {
      url += '?name=' + query;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const getPatients = async (qryParams) => {
    return auth_axios.get(BASE_URL + endPoints.PATIENTLIST, {
      params: {
        ...qryParams,
      },
    });
  };

  const getAdminProfiles = async (qryParams) => {
    return auth_axios.get(BASE_URL + endPoints.ADMINPROFILE(), {
      params: { ...qryParams },
    });
  };

  const getSignleArticle = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLES + `${id}/`,
      method: 'get',
    });
  };

  const getArticles = async (qryParams) => {
    return await auth_axios({
      url: BASE_URL + endPoints.ARTICLES,
      method: 'get',
      params: { ...qryParams },
    });
  };

  const getArticlesCount = async (filters) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLECOUNT,
      method: 'get',
      params: { ...filters },
    });
  };

  const searchGoals = async (page) => {
    return auth_axios.get(endPoints.GOALSEARCH, { params: { ...page } });
  };

  const getOrders = async (pages) => {
    return auth_axios.get(endPoints.ORDERS, { params: { ...pages } });
  };

  const getSearchArticles = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.SEARCHARTICLE + params,
      method: 'get',
    });
  };

  const searchMedicines = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.MEDICINESSEARCH + '?status=published',
      params: { search_str: params },
      method: 'get',
    });
  };

  const dayPartGet = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.DAYPARTSSEARCH,
      params: { search_str: params },
      method: 'get',
    });
  };

  const searchBEHAVIOURQUESTION = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.BEHAVIORQUESTIONSEARCH,
      params: { search_str: params },
      method: 'get',
    });
  };

  const searchMedicinesByCategories = async (params) => {
    return auth_axios({
      url: BASE_URL + endPoints.MEDICINECATEGORIESEARCH,
      params: { search_str: params },
      method: 'get',
    });
  };

  const getSearchTeam = async (qry) => {
    return auth_axios.get(BASE_URL + endPoints.SEARCHTEAM, {
      params: {
        ...qry,
      },
    });
  };

  const getSearchPatients = async (params,id="") => {
    return auth_axios({
      url: BASE_URL + endPoints.PATIENTSEARCH,
      method: 'get',
      params: { search_str: params,patient_id:id },
    });
  };

  const searchPatients = async (query) => {
    let url = BASE_URL + endPoints.PATIENTSEARCH;
    if (query) {
      url += '?full_name=' + query;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const articleCategoryGet = async (page) => {
    let url = BASE_URL + endPoints.ARTICLECATEGORY;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const notesCategoryGet = async (page) => {
    let url = BASE_URL + endPoints.NOTESCATEGORY;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const notesCategoryGetByTemplate = async (id) => {
    return auth_axios({
      url: BASE_URL + endPoints.NOTESCATEGORY + `${id}/template_by_category/`,
      method: 'get',
    });
  };

  const getAllDepartmentStatues = async (page) => {
    let url = BASE_URL + endPoints.GETALLDEPARTSTATUSES;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const searchDepartmentStatues = async (query) => {
    let url = BASE_URL + endPoints.SEARCHDEPARTSTATUSES;
    if (query) {
      url += '?name=' + query;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const articleTagsGet = async (page, qry) => {
    let url = BASE_URL + endPoints.ARTICLETAGS;
    return auth_axios({
      url: url,
      method: 'get',
      params: {
        ...qry,
        ...(page ? { page: page } : {}),
      },
    });
  };
  const updateOnnboardingStatus = async (id, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/', data);
  };

  const updateDepartmentFieldOption = async (departmentId, data) => {
    return auth_axios.patch(endPoints.DEPARTMENTS + departmentId + '/add_option/', data);
  };

  const prescriptionPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.PRESCRIPTIONPDFDOWNLOAD(id, pid));
  };

  const summaryPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.SUMMARYPDFDOWNLOAD(id, pid));
  };

  const questionPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.QUESTIONPDFDOWNLOAD(id, pid));
  };

  const searchOrders = async (pages) => {
    return auth_axios.get(endPoints.ORDERS + 'search/', {
      params: { ...pages },
    });
  };
  // update Apis Here

  const createSubSourceOption = async (data) => {
    return auth_axios.patch(endPoints.ADDSUBSOURCEOPTION, data);
  };

  const prescriptionMDTSummeryUpdate = async (id, summeryId, params) => {
    return auth_axios.patch(endPoints.PRESCRIPTIONSUMMERYGETSINGLE(id, summeryId), params);
  };

  const updateActivityLogs = async (id, activityId, params) => {
    return auth_axios.patch(endPoints.ACTIVITYLOGACTION(id, activityId), params);
  };

  const updateTasks = async (params, id, tid) => {
    return auth_axios.patch(endPoints.TASKS(id) + tid + '/', params);
  };

  const updatePrescription = async (id, pid, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.PRESCRIPTIONS + pid + '/', data);
  };

  const updateServiceData = async (id, pid, formData) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTSERVICE + pid + '/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
  };

  const updatePaymentServiceData = async (id, pid, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTPAYMENTSERVICE + pid + '/', data);
  };

  const updateBehaviorPrescription = async (id, pid, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.BEHAVIORQUESTIONCREATE + pid + '/', data);
  };

  const dashboardConfigration = async (data) => {
    return auth_axios.patch(endPoints.DASHBOARDCONFIG, data);
  };

  const updateMeasuringEvent = async (id, data) => {
    return auth_axios.patch(endPoints.MEASURINGEVENT + id + '/', data);
  };

  const updateBannerData = async (id, data) => {
    return auth_axios.patch(endPoints.MOBILEBANNER + id + '/', data);
  };

  const updateMedicines = async (id, data) => {
    return auth_axios.patch(endPoints.MEDICINES + id + '/', data);
  };

  const updatePatientAssignData = async (id, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/', data);
  };

  const updatePatientGOAL = async (id, data, goalid) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + 'goals' + '/' + goalid + '/', data);
  };

  const updateReports = async (id, data, reportid) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + 'reports' + '/' + reportid + '/', data);
  };
  const updatePayeeDetails = async (id, data, reportid) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + 'patient_service_payment' + '/' + reportid + '/update_record_payment/', data);
  };

  const finalServiceButtonApi = async (id, data, reportid) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + 'patient_service' + '/' + reportid + '/', data);
  };

  const assingAdmin = async (id, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.ASIGNADMIN, data);
  };

  const unasignAdmin = async (id, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.UNASIGNADMIN, data);
  };

  const updateGoals = async (id, data) => {
    return auth_axios.patch(endPoints.GOALCREATION + id + '/', data);
  };

  const updateQuestionData = async (data, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.PROFILEQUESTIONS + id + '/',
      method: 'patch',
      data: data,
    });
  };

  const updateBehaviourQuestionData = async (data, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.BEHAVIOURQUESTION + id + '/',
      method: 'patch',
      data: data,
    });
  };

  const updateStatus = async (id, status) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLES + id + '/' + status + '/',
      method: 'patch',
    });
  };

  const updateProfile = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ADMINPROFILE(id),
      method: 'patch',
      data: params,
    });
  };

  const updatePatient = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.PATIENTLIST + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateArticles = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLES + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateCategories = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLECATEGORY + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateNotesCategories = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.NOTESCATEGORY + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateDepartmentStatus = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.GETALLDEPARTSTATUSES + id + '/',
      method: 'patch',
      data: params,
    });
  };
  const prescriptionDoctorList = async (id, listFor) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/assigned_admins/?role=' + listFor);
  };

  const updateNotes = async (params, id, notesUpdateId) => {
    return auth_axios({
      url: BASE_URL + endPoints.PATIENTLIST + id + '/' + endPoints.NOTES + notesUpdateId + '/',
      method: 'patch',
      data: params,
    });
  };

  const searchProfileQuestionList = async (pages) => {
    return auth_axios({
      url: BASE_URL + endPoints.PROFILEQUESTIONSSEARCH,
      method: 'get',
      params: { ...pages },
    });
  };

  const updateNotesTemplate = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.NOTESTEMPLATE + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const uploadFileToServer = async (data, url, onUploadProgress) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'multipart/form-data');

    return axios({
      url: url,
      method: 'put',
      data: data,
      headers: myHeaders,
      onUploadProgress: onUploadProgress,
    });
  };

  const updateTags = async (params, id) => {
    return auth_axios({
      url: BASE_URL + endPoints.ARTICLETAGS + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const createCarePlan = async (data) => {
    return await auth_axios.post(endPoints.CAREPLAN, data);
  };

  const createAdherence = async (data) => {
    return await auth_axios.post(endPoints.ADHERENCE, data);
  };
  const getAdherence = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.ADHERENCE, {
      params: { ...pageQry },
    });
  };

  const getCarePlan = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.CAREPLAN, {
      params: { ...pageQry },
    });
  };

  const notesTemplateGet = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.NOTESTEMPLATE, {
      params: { ...pageQry },
    });
  };

  const getResource = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.STATUS, {
      params: { ...pageQry },
    });
  };

  const createResourceStatus = async (data) => {
    return await auth_axios.post(endPoints.STATUS, data);
  };

  const updateAdherence = async (id, params) => {
    return auth_axios({
      url: BASE_URL + endPoints.ADHERENCE + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateCarePlan = async (id, params) => {
    return auth_axios({
      url: BASE_URL + endPoints.CAREPLAN + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const updateResourceStatus = async (id, params) => {
    return auth_axios({
      url: BASE_URL + endPoints.STATUS + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const deleteAdherence = async (id) => {
    return auth_axios.delete(endPoints.ADHERENCE + id + '/');
  };
  const deleteCarePlan = async (id) => {
    return auth_axios.delete(endPoints.CAREPLAN + id + '/');
  };
  const deleteResourceStatus = async (id) => {
    return auth_axios.delete(endPoints.STATUS + id + '/');
  };
  const deleteNotesTemplate = async (id) => {
    return auth_axios.delete(endPoints.NOTESTEMPLATE + id + '/');
  };
  const getDayPartSearch = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.DAYPARTSSEARCH, {
      params: { ...pageQry },
    });
  };

  const searchAttatchGoal = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.GOALSEARCH, {
      params: { ...pageQry },
    });
  };

  // only patient apis Here ===================================

  const getSignleArticlePatient = async (id) => {
    return patientAxios.get(patientEndPoints.GETARTICLE(id));
  };

  const createDayPart = async (data) => {
    return await auth_axios.post(endPoints.DAYPART, data);
  };

  const createMobileBanner = async (data) => {
    return await auth_axios.post(endPoints.MOBILEBANNER, data);
  };

  const getDayPart = async (pageQry) => {
    return auth_axios.get(BASE_URL + endPoints.DAYPART, {
      params: { ...pageQry },
    });
  };

  const deleteDayPartTime = async (id) => {
    return auth_axios.delete(endPoints.DAYPART + id + '/');
  };

  const updateDayPart = async (id, params) => {
    return auth_axios({
      url: BASE_URL + endPoints.DAYPART + id + '/',
      method: 'patch',
      data: params,
    });
  };

  const createSegmentDefinition = async (data) => {
    return await auth_axios.post(endPoints.SEGMENTDEFINITION, data);
  };

  const getLocations = async (page, params) => {
    let url = BASE_URL + endPoints.GETLOCATIONS;
    if (page) {
      url += '?page=' + page;
      if (params) {
        url = url + '&allClinic=true';
      }
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };


  const getFilteredLocations = async () => {
    let url = BASE_URL + endPoints.GETLOCATIONS + '?allClinic=true&remove_only_procedure_clinics=true&disable_pagination=true';
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const getFilteredLocationsForDoctor = async () => {
    let url = BASE_URL + endPoints.GETLOCATIONS + '?allClinic=true&disable_pagination=true&role=doctor&remove_only_procedure_clinics=true';
    return auth_axios({
      url: url,
      method: 'get',
    });
  }

  const getEnableAppointmentService = async () => {
    let url = BASE_URL + endPoints.APPOINTMENTSERVICE + 'search/?search_str=&enable=true';
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const getAppointmentService = async (qry, enable) => {
    return auth_axios.get(endPoints.APPOINTMENTSERVICE, {
      params: {
        page: qry,
        enable: enable,
      },
    });
  };

  const getServiceCategories = async (pages) => {
    return auth_axios.get(endPoints.PRC + 'search/', { params: { ...pages } });
  };

  const getServiceDoctor = async (pages) => {
    return auth_axios.get(endPoints.GETSERVICEDOCTORES, {
      params: { ...pages },
    });
  };

  const getPatientServiceDoctor = async (params) => {
    return auth_axios.get(endPoints.GETPATIENTSERVICEDOCTORES, { params: { ...params } });
  };

  const getServiceDropdownSearch = async (qry) => {
    return auth_axios.get(endPoints.APPOINTMENTSERVICE + 'search/', {
      params: {
        search_str: qry,
        enable: true,
      },
    });
  };

  const getPRC = async (page) => {
    let url = BASE_URL + endPoints.PRC;
    if (page) {
      url += '?page=' + page;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const getConsultation = async (pages) => {
    return auth_axios.get(endPoints.CONSUTDATAGET, { params: { ...pages } });
  };

  const searchLocations = async (query) => {
    let url = BASE_URL + endPoints.SEARCHLOCATIONS;
    if (query) {
      url += '?name=' + query;
    }
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const updatePatientLocation = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATEPATIENTLOCATION(id), data);
  };

  const getDoctors = async (query, id) => {
    return await auth_axios.get(endPoints.GETDOCTORS(query, id));
  };

  const deleteDepartment = async (id, data) => {
    return await auth_axios.patch(endPoints.DELETEDEPARTMENT(id), data);
  };

  const getFamilyMembers = async (id, data) => {
    return await auth_axios.get(endPoints.GETFAMILYMEMBERS(id), data);
  };

  const createDepartment = async (id, data) => {
    return await auth_axios.post(endPoints.CREATEDEPARTMENT(id), data);
  };

  const updateDepartment = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATEDEPARTMENT(id), data);
  };

  const updateDepartmentModel = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATEDEPARTMENTOBJECT(id), data);
  };

  const deleteDepartmentModel = async (id) => {
    return await auth_axios.delete(endPoints.UPDATEDEPARTMENTOBJECT(id));
  };

  const updateClinic = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATELOCATIOB(id), data);
  };
  const updatAppointmentService = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATEAPPOINTMENTSERVICE(id), data);
  };

  const updatPRCDATA = async (id, data) => {
    return await auth_axios.patch(endPoints.UPDATEPRC(id), data);
  };

  const deleteClinic = async (id) => {
    return await auth_axios.delete(endPoints.UPDATELOCATIOB(id));
  };

  const getClinicAdmin = async (id, params) => {
    return auth_axios.get(BASE_URL + endPoints.UPDATELOCATIOB(id) + 'admins/', {
      params: { ...params },
    });
  };

  const getAppointment = async (params) => {
    let url = BASE_URL + endPoints.GETAPPOINTMENT + params;
    return auth_axios({
      url: url,
      method: 'get',
    });
  };

  const getAppointmentDetail = async (id) => {
    return auth_axios.get(endPoints.GETAPPOINTMENT + id);
  };

  const createAppointment = async (data) => {
    return await auth_axios.post(endPoints.GETAPPOINTMENT, data);
  };

  const updateAppointment = async (id, data) => {
    return await auth_axios.patch(endPoints.GETAPPOINTMENT + id + '/', data);
  };

  const recordPayment = async (id, data) => {
    return await auth_axios.patch(endPoints.GETAPPOINTMENT + id + '/record_payment/', data);
  };

  const cancelAppointment = async (id, data) => {
    return await auth_axios.post(endPoints.GETAPPOINTMENT + id + '/cancel/',data);
  };
  const deleteAppointmentService = async (id) => {
    return await auth_axios.delete(endPoints.UPDATEAPPOINTMENTSERVICE(id));
  };

  const deletePRC = async (id) => {
    return await auth_axios.delete(endPoints.UPDATEPRC(id));
  };

  const getDietPlanTemplateList = async (id, params, pid) => {
    return auth_axios.get(endPoints.dietPlanTemplateList(id, pid), {
      params: { ...params },
    });
  };

  const getSingleAppointment = async (id, consultationId) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/consultation/' + consultationId);
  };

  const dietPlansPdfDownload = (id, pid) => {
    return auth_axios.get(endPoints.DIETPLANPDF(id, pid));
  };

  const deleteDietsPlan = async (id, dietPlanId) => {
    return auth_axios.delete(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS + dietPlanId + '/');
  };

  const DietPlanStatusPublish = async (id, DietPlanId) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS + DietPlanId + '/' + 'publish' + '/');
  };

  const DietPlanCreate = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS, params);
  };

  const DietPlanUpdate = async (id, pid, data) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS + pid + '/', data);
  };
  const getOrderDetail = async (id) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/orders/');
  };

  const updateOrderDetail = async (id, params) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/orders/', params);
  };

  const teleConsultationCreate = async (params, patientId) => {
    return auth_axios.post(endPoints.PATIENTLIST + patientId + '/tele_consultations/', params);
  };
  const teleConsultationList = async (params, patientId) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/tele_consultations/', { params: { ...params } });
  };

  const patientServiceList = async (patientId, params) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/patient_service/', { params: { ...params } });
  };

  const teleConsultationDetail = async (consultationId, patientId, params) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/tele_consultations/' + consultationId + '/room_sessions/', {
      params: { ...params },
    });
  };

  const modalTeleConsultationDetail = async (consultationId, patientId, params) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/tele_consultations/' + consultationId + '/recording_assets/', {
      params: { ...params },
    });
  };

  const modalDownloadButtonDetail = async (consultationId, patientId, params) => {
    return auth_axios.get(endPoints.PATIENTLIST + patientId + '/tele_consultations/' + consultationId + '/presigned_url_of_recording/', {
      params: { ...params },
    });
  };
  const consultationEdit = async (params, id, consultatinId) => {
    return auth_axios.patch(endPoints.PATIENTLIST + id + '/' + 'consultation' + '/' + consultatinId + '/', params);
  };

  const DietPlanCreatePublish = async (params, id) => {
    return auth_axios.post(endPoints.PATIENTLIST + id + '/' + endPoints.DIETPLANS + 'save_publish' + '/', params);
  };

  const getCrpSessionLeft = async (id, serviceId, crpType, params) => {
    return auth_axios.get(endPoints.PATIENTLIST + id + '/' + endPoints.PATIENTSERVICE + serviceId + '/get_sessions_left', {
      params: { ...params, crp_type: crpType },
    });
  };

  // const getCrpSessionLeft = async (params, id, serviceId, crpType) => {
  //   return auth_axios.get(endPoints.GETCRPSESSIONLEFT(id, serviceId, crpType));
  // };

  // patient Apis ===================================

  return {
    getCareManagersByCareManager,
    getPatientServiceDoctor,
    getPatientReportsService,
    deletePayee,
    finalServiceButtonApi,
    updatePayeeDetails,
    getPatientPaymentDocs,
    createPatientPaymentDocs,
    deleteReports,
    singleDataPaymentService,
    patientPaymentServiceCreate,
    updatePaymentServiceData,
    patientServiceList,
    getServiceDoctor,
    getServiceDropdownSearch,
    deleteService,
    updateServiceData,
    singleDataService,
    createFcm,
    patientServiceCreate,
    getServiceCategories,
    getPRC,
    createPRC,
    deletePRC,
    updatPRCDATA,
    refreshPaymentLink,
    generatePaymentLink,
    deleteAppointmentService,
    createAppointmentService,
    getEnableAppointmentService,
    getAppointmentService,
    updatAppointmentService,
    consultationEdit,
    teleConsultationCreate,
    teleConsultationList,
    teleConsultationDetail,
    modalTeleConsultationDetail,
    modalDownloadButtonDetail,
    getOrderDetail,
    updateOrderDetail,
    getFcmData,
    getSingleFcmData,
    createSubSourceOption,
    statusDietPlanPublish,
    getDietPlan,
    updateDietPlan,
    searchOrders,
    createDietPlan,
    deleteDietPlan,
    dietPlanPdfDownload,
    getDoctors,
    getCarePlan,
    updateClinic,
    deleteClinic,
    getClinicAdmin,
    getAppointment,
    getAppointmentDetail,
    createAppointment,
    updateAppointment,
    recordPayment,
    cancelAppointment,
    updateDepartmentModel,
    deleteDepartmentModel,
    updateDepartment,
    getWebEngageData,
    createWebEngage,
    getSingleWebEngageData,
    createDepartment,
    deleteDepartment,
    getDepartment,
    searchDepartments,
    updatePatientLocation,
    searchLocations,
    getLocations,
    getConfig,
    setZohoCreds,
    getDocServices,
    getLocationByAdmin,
    getAvailableSlots,
    getZohoMeetings,
    getZohoMeetingsAvailability,
    getZohoMeetingsForPatient,
    getZohoMeeting,
    deleteZohoMeeting,
    updateZohoMeeting,
    createZohoMeeting,
    createZohoBooking,
    updateZohoBooking,
    searchNotesTemplate,
    getDayParts,
    updateOnnboardingStatus,
    updateDepartmentFieldOption,
    dayPartGet,
    updateReports,
    getBannerDetail,
    getPresignedUrl,
    searchBEHAVIOURQUESTION,
    getPatientReports,
    profileQuestionDelete,
    createPatientReport,
    getPatientProfile,
    createPatient,
    createPatientStatus,
    behaviourQuestionList,
    CompeleteTasks,
    prescriptionDepartments,
    getAssignTask,
    statusOnboarding,
    updateNotesTemplate,
    statusLapsed,
    getMedicinecategories,
    updateNotes,
    deleteNotes,
    getNotes,
    painGraph,
    statusQuestionPublish,
    notes,
    updateBehaviorPrescription,
    statusPublish,
    statusUnpublished,
    statusActivate,
    getAUDITLOG,
    updateNotify,
    createNotify,
    createRegisterEvent,
    getCarePlanDropDownData,
    getBehaviourQuestions,
    getTemplateList,
    searchTemplateList,
    getRegimeTemplate,
    getPatients,
    patientProfileTimeline,
    patientProfileAuditLog,
    getPatientDetails,
    getPatientgoals,
    profileBehaviorQuestionDelete,
    updateArticles,
    articleTagsGet,
    getMedicinesSetting,
    articleCategoryGet,
    getArticlesCount,
    searchPatientGoals,
    getArticles,
    getSignleArticle,
    articleTagsDelete,
    articleCategoryDelete,
    departmentStatusDelete,
    verifyOTP,
    sendOTP,
    questionDataPrevious,
    questionData,
    createArticles,
    createCategories,
    createArticleTags,
    createDepartmentStatus,
    getAllDepartmentStatues,
    searchDepartmentStatues,
    createProfile,
    updateCategories,
    updateCarePlan,
    updateDepartmentStatus,
    updateTags,
    getSearchArticles,
    getAdminProfiles,
    currentAdminProfile,
    getAdminProfilesCount,
    getAdminProfile,
    createMedicinesUnApprove,
    updateProfile,
    deleteArticleImage,
    updateStatus,
    searchTags,
    searchCategory,
    getSearchTeam,
    getDashBoardData,
    getPatientCount,
    getSearchPatients,
    searchPatients,
    notesCategoryGetByTemplate,
    getGoalEvent,
    createBehaviourQuestion,
    getCarePlanTemplate,
    createRegimeTemplate,
    updateRegimeTemplate,
    deleteRegimeTemplate,
    getCareManagers,
    questionPdfDownload,
    search,
    behaviorQuestionCreate,
    createProfileQuestion,
    getProfileQuestions,
    profileQuestionDelete,
    updateQuestionData,
    createMeasuringEvent,
    getMeasuringEvent,
    createGoalCreation,
    deleteMeasuringEvent,
    createFollowUp,
    deletebanner,
    updateMeasuringEvent,
    getGoals,
    deleteGoals,
    updateGoals,
    updatePatientAssignData,
    deleteCarePlan,
    unasignAdmin,
    updatePatient,
    assingAdmin,
    deleteAttatchGoal,
    getConsultation,
    createPatientGoal,
    getCarePlanNotification,
    medicineCategoriesGet,
    createMedicines,
    notesTemplateGet,
    createDepartmentModel,
    createClinicModel,
    getMedicines,
    createMedicinesApprove,
    deleteMedicines,
    updatePatientGOAL,
    updateMedicines,
    searchMedicines,
    searchGoals,
    getOrders,
    consultationCreate,
    consultationUpdate,
    consultationList,
    consultationDetail,
    consultationDelete,
    consultationClose,
    patientAttendenceStatus,
    prescriptionCreate,
    prescriptionList,
    prescriptionData,
    updatePrescription,
    dashboardConfigration,
    getDashBoardConfigData,
    searchMedicinesByCategories,
    weightGraph,
    activityLogsOnchange,
    createTasks,
    showTasks,
    deleteTask,
    updateTasks,
    deleteResourceStatus,
    activityLogsCreate,
    createSubscription,
    prescriptionPdfDownload,
    createAdherence,
    getAdherence,
    updateAdherence,
    deleteAdherence,
    createProfileAnswers,
    createCarePlan,
    prescriptionMedicine,
    getCareSearch,
    createMobileBanner,
    allStatus,
    updateActivityLogs,
    createDayPart,
    getDayPart,
    deleteDayPartTime,
    updateDayPart,
    getDayPartSearch,
    getAttatchgoal,
    getSlotsConsultation,
    searchTaskAssign,
    getActivePrescription,
    searchAttatchGoal,
    updateBehaviourQuestionData,
    duplicateRegimeQuestions,
    deleteQuestions,
    deletePrescription,
    createMedicinesDraft,
    prescriptionSchema,
    prescriptionSummeryCreate,
    prescriptionMDTSummeryList,
    prescriptionMDTSummerygetSingle,
    prescriptionMDTSummeryDelete,
    prescriptionMDTSummeryUpdate,
    mdtSUmmaryPublish,
    mdtSUmmaryUnpublish,
    summaryPdfDownload,
    uploadFileToServer,
    updateBehaviourQuestionData,
    updateBannerData,
    getPublishedPrescriptionData,
    getBehaviourQuestionCategories,
    getSegment,
    updateResourceStatus,
    searchMedicinesName,
    teamGet,
    getResource,
    createResourceStatus,
    getCategoriesTampletNotes,
    deleteNotesTemplate,
    createProfileAnswersNextCheckIn,
    getSearchSegment,
    createNotesTemplate,
    getDietTemplateList,
    getDietTemplate,
    searchDietTemplateList,
    deleteDietTemplate,
    createDietTemplate,
    updateDietTemplate,
    getDietPlanTemplateList,
    dietPlansPdfDownload,
    deleteDietsPlan,
    DietPlanStatusPublish,
    DietPlanCreate,
    DietPlanUpdate,
    DietPlanCreatePublish,
    getTeamWorkingHours,
    deleteTeamWorkingHours,
    getAllDoctorList,
    getSlotsCount,
    getAppointmentSchedule,
    getSingleAppointment,
    // only patient apis Here ===================================
    getSignleArticlePatient,
    createSegmentDefinition,
    notesCategoryGet,
    notesCategoryDelete,
    getCallLog,
    createNotesCategories,
    updateNotesCategories,
    searchNotesCategory,
    searchProfileQuestionList,
    prescriptionDoctorList,
    getConsultationDataApi,
    getCarePlanMarkDoneNotification,
    getCareManagersByClinic,
    getRevenueManagersByClinic,
    getFamilyMembers,
    getRevenueManagersByRevenueManager,
    getCrpSessionLeft,
    getFilteredLocations,
    getFilteredLocationsForDoctor,
  };
}

export default useApiManager;

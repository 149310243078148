import React, { useEffect, useState } from 'react';
import './AppointmentVer2.scss';
import { DatePicker } from 'rsuite';
import Select2DropDown from '../common/Select2DropDown';
import useApiManager from 'networking/ApiManager';
import CoreInputBox from '../CoreInputBox';
import { dateFormatStringSingleDate, timeFormat } from '../Helper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import CommonRenderCheckBox from '../CommonRenderCheckBox';
import CoreCheckBox from '../CoreCheckBox';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

function AppointmentForm(props) {
  let environment = process.env.REACT_APP_ENVIRONMENT;
  let serviceID = 3;
  let userID = 2676;
  let {
    createField,
    isBabyMd,
    setCreateField,
    handleClinicSearch,
    allClinic,
    searchClinicList,
    onClick,
    errors,
    setIsLoading,
    disablePatient,
    hideRecord,
    appointmentID,
    appointmentDetail,
    isClinicEnable,
    activeDate,
    isNivanCare,
    userData: usrData,
  } = props;
  const appointmentRecipientOptions = [
    { title: 'Self', id: 'self' },
    { title: 'Family', id: 'family' },
  ];
  const ApiClient = useApiManager();
  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;
  const [patientId, setPatientId] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [patientList, setPatientList] = useState([]);
  const [searchPatientList, setSearchPatientList] = useState([]);
  const [searchPatientStr, setSearchPatientStr] = useState('');
  const [doctorList, setDoctorList] = useState([]);
  const [searchDocStr, setSearchDocStr] = useState('');
  const [searchDoctorList, setSearchDoctorList] = useState([]);
  const [clinicList, setClinicList] = useState([]);
  const [searchClinicStr, setSearchClinicStr] = useState('');
  const [doctorServices, setDoctorServices] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentFrom, setAppointmentFrom] = useState(null);
  const [appointmentTo, setAppointmentTo] = useState(null);
  const [showAllSlots, setShowAllSlots] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [filteredDoctorList, setFilteredDoctorList] = useState('');
  const [newDefaultService, setServiceDefaultVal] = useState('');
  const [isBreak, setIsBreak] = useState(false);
  const [appointmentRecipient, setAppointmentRecipient] = useState(null);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState(null);
  const [familyMemberDetails, setFamilyMemberDetails] = useState({
    family_member_name: null,
    relationship: null,
    family_member_age: null,
    family_member_gender: null,
    family_member_phone: null,
  });
  const [patientServiceList, setPatientServiceList] = useState(null);
  const [selectedServiceObject, setSelectedServiceObject] = useState(null);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [crpSessionLeft, setCrpSessionLeft] = useState(null);
  const [selectedServiceTitle, setSelectedServiceTitle] = useState(null);
  const [selectedSpecialityObject, setSelectedSpecialityObject] = useState(null);
  const [crpServices, setCrpServices] = useState([]); // Add this new state
  const [currentAppointmentDetail, setCurrentAppointmentDetail] = useState(null);

  // Add this useEffect to update crpServices whenever patientServiceList changes
  useEffect(() => {
    if (patientServiceList) {
      const crpServicesList = patientServiceList.filter(service => service?.service?.title === 'crp');
      setCrpServices(crpServicesList);
    }
  }, [patientServiceList]);

  const navigate = useNavigate();
  const location = useLocation();
  let userData = useGetLoginUserDataQuery()?.data?.data || usrData || {};
  const isDoctorLoggedIn = userData?.role == 'doctor';
  const { patientId: urlPatientId, consultationId: pid } = useParams();
  
  const isActiveSlots = (index) => {
    return index === activeIndex;
  };
  // Get appointment details from either props or location state
  const effectiveAppointmentDetail = appointmentDetail || location?.state?.appointmentDetail;
  
  useEffect(() => {
    getPatients();
    setPatientId(urlPatientId);
    if (effectiveAppointmentDetail) {
      setPatientId(effectiveAppointmentDetail?.user?.id);
      setSelectedServiceId(effectiveAppointmentDetail?.patient_service_id);
      
      // Set service title if available
      if (effectiveAppointmentDetail.service?.title) {
        setSelectedServiceTitle(effectiveAppointmentDetail.service.title);
      }
    }
  }, []);
  // useEffect(() => {
  //   if (patientId && selectedServiceObject) {
  //     getCrpSessionLeft(patientId, selectedServiceObject.id, selectedSpeciality);
  //   }
  // }, []);

  useEffect(() => {
    if (patientId || urlPatientId) {
      getPatientServiceList(patientId || urlPatientId);
      if (selectedServiceId) {
        handleServiceSelect(selectedServiceId);
      }
    }
  }, [selectedServiceId]);

  useEffect(() => {
    if(patientId) {
      getPatientServiceList(patientId);
    }
  }, [patientId])

  useEffect(() => {
    if (pid && (!appointmentDetail || appointmentDetail?.title === "")) {
      getAppointmentDetail(pid);
    }
  }, [pid, appointmentDetail]);

  useEffect(() => {
    if (patientServiceList?.length > 0 && currentAppointmentDetail?.patient_service) {
      const serviceObject = patientServiceList.find(
        (service) => service?.id === (appointmentDetail?.patient_service || currentAppointmentDetail?.patient_service)
      );

      if (serviceObject) {
        setSelectedServiceObject(serviceObject);
      } else {
        console.log("Service object not found in patient service list for ID:", appointmentDetail.patient_service);
      }
    }
  }, [patientServiceList, currentAppointmentDetail]);

  useEffect(() => {
    if (createField?.service_id == serviceID) {
      setIsBreak(true);
    } else {
      setIsBreak(false);
    }
  }, [createField?.service_id]);

  useEffect(() => {
    if (activeDate) {
      setAppointmentDate(new Date(activeDate));
    }
  }, [activeDate]);

  useEffect(() => {
    if (allClinic?.length) {
      setClinicList(allClinic);
    }
  }, [allClinic]);

  useEffect(() => {
    if (location?.state?.patientName) setCreateField({ ...createField, user_id: location?.state?.patientName });
  }, [location?.state?.patientName]);

  useEffect(() => {
    if ((createField.consult_type == null || createField.consult_type == '') && isBabyMd) {
      setCreateField({
        ...createField,
        consult_type: 'physical',
      });
    }
  }, [isBabyMd]);

  useEffect(() => {
    if (clinicList.length == 1 && isBabyMd) {
      setCreateField({
        ...createField,
        location_id: clinicList[0]?.id,
      });
    }
  }, [clinicList]);

  useEffect(() => {
    if (newDefaultService && isBabyMd) {
      setCreateField({
        ...createField,
        service_id: newDefaultService?.id,
      });
    }
  }, [newDefaultService]);

  useEffect(() => {
    if (filteredDoctorList.length == 1 && isBabyMd) {
      setCreateField({
        ...createField,
        consultant_id: filteredDoctorList[0]?.id,
      });
    }
  }, [filteredDoctorList]);

  useEffect(() => {
    if (effectiveAppointmentDetail?.service?.id && doctorServices?.findIndex((item1) => item1?.id == effectiveAppointmentDetail?.service?.id) == -1) {
      doctorServices.push(effectiveAppointmentDetail?.service);
      setDoctorServices([...doctorServices]);
    }
  }, [effectiveAppointmentDetail?.service?.id, doctorServices]);

  useEffect(() => {
    if (effectiveAppointmentDetail?.consultant?.id && doctorList?.findIndex((item1) => item1?.id == effectiveAppointmentDetail?.consultant?.id) == -1) {
      doctorList.push(effectiveAppointmentDetail?.consultant);
      setDoctorList([...doctorList]);
    }
  }, [effectiveAppointmentDetail?.consultant?.id, doctorList]);

  useEffect(() => {
    if (createField?.location_id || !isClinicEnable) {
      getDoctor();
    } else {
      setDoctorList([]);
    }
  }, [createField?.location_id, isClinicEnable]);

  useEffect(() => {
    let patientData = [...patientList];
    if (effectiveAppointmentDetail?.user?.id && patientData?.findIndex((item3) => item3?.id == effectiveAppointmentDetail?.user?.id) == -1) {
      patientData.push(effectiveAppointmentDetail?.user);
      setPatientList(patientData);
    }
  }, [patientList, effectiveAppointmentDetail?.user?.id]);

  const getPatients = () => {
    setIsLoading(true);
    ApiClient.getSearchPatients(searchPatientStr)
      .then((res) => {
        setIsLoading(false);
        let patientData = res.data;
        setPatientList(patientData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (createField?.consultant_id && createField?.location_id && appointmentDate) {
      getAvailableSlots();
    } else {
      setAvailableSlots([]);
    }
  }, [createField?.consultant_id, createField?.location_id, appointmentDate]);

  useEffect(() => {
    if (createField?.consult_datetime) {
      setAppointmentDate(new Date(createField?.consult_datetime));
    }
    if (createField?.consult_end_datetime) {
      setAppointmentDate(new Date(createField?.consult_end_datetime));
    }
  }, [createField?.consult_datetime, createField?.consult_end_datetime]);

  const getPatientServiceList = (id) => {
    setIsLoading(true)
    ApiClient.patientServiceList(id)
      .then((res) => {
        setPatientServiceList(res.data);
        setIsLoading(false);
        
        // Get patient_service from either source
        const patientServiceId = effectiveAppointmentDetail?.patient_service || 
                                effectiveAppointmentDetail?.patient_service_id || 
                                location?.state?.appointmentDetail?.patient_service;
        
        // If we have a patient_service ID, find it in the list
        if (patientServiceId) {
          // Try to find the exact service by ID
          let serviceObject = res.data.find(service => service.id === patientServiceId);
          
          // If not found by ID but we know it's a CRP service, find by service title
          if (!serviceObject && (effectiveAppointmentDetail?.service?.title === 'crp' || 
                                location?.state?.appointmentDetail?.service?.title === 'crp')) {
            serviceObject = res.data.find(service => service?.service?.title === 'crp');
          }
          
          if (serviceObject) {
            setSelectedServiceObject(serviceObject);
            // Get crp_type from either source
            const crpType = effectiveAppointmentDetail?.crp_type || 
                           location?.state?.appointmentDetail?.crp_type;
            // If we have crp_type, set it
            if (crpType) {
              setSelectedSpeciality(crpType);
              // Update createField with the values
              setCreateField(prevState => ({
                ...prevState,
                patient_service: patientServiceId,
                crp_type: crpType
              }));
            }
          } else {
            // Create a placeholder object with the information we have
            const appointmentSource = effectiveAppointmentDetail || location?.state?.appointmentDetail;
            
            if (appointmentSource) {
              setSelectedServiceObject({
                id: patientServiceId,
                crp_package_name: appointmentSource.patient_service_name || "Package",
                created_at: appointmentSource.created_at || new Date().toISOString(),
                service_category: { 
                  title: appointmentSource.service_category || "Service" 
                }
              });
            }
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error fetching patient details: ", err);
      });
  };

  const getAvailableSlots = () => {
    let params = {
      doctor_id: createField?.consultant_id,
      date: dateFormatStringSingleDate(appointmentDate),
      location_id: createField?.location_id,
    };
    setIsLoading(true);
    ApiClient.getAvailableSlots(params)
      .then((res) => {
        setIsLoading(false);
        setAvailableSlots(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getAppointmentDetail = (pid) => {
    setIsLoading(true);
    ApiClient.getAppointmentDetail(pid)
      .then((res) => {
        setIsLoading(false);
        setCurrentAppointmentDetail(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handlePatientSearch = (search_str) => {
    if (search_str?.length > 2) {
      setSearchPatientStr(search_str);
      setIsLoading(true);
      ApiClient.getSearchPatients(search_str)
        .then((res) => {
          setIsLoading(false);
          setSearchPatientList(res.data.length > 0 ? res.data : [{ id: null, full_name: `Add Your New Patient: ${search_str}`, phone: '' }]);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setSearchPatientStr('');
      setSearchPatientList([]);
    }
  };

  const getDoctor = () => {
    setIsLoading(true);
    if (createField?.location_id && isClinicEnable) {
      ApiClient.getClinicAdmin(createField?.location_id, { role: 'doctor' })
        .then((res) => {
          setIsLoading(false);
          setDoctorList(res.data);
          const filteredDoctorList = res?.data?.filter((item4) => item4?.role == 'doctor');
          setFilteredDoctorList(filteredDoctorList);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      let params = { role: 'doctor', remove_inactive_admins: true };
      ApiClient.getSearchTeam(params)
        .then((res) => {
          setIsLoading(false);
          setDoctorList(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  const handleDoctorSearch = (search_str) => {
    if (search_str?.length > 2) {
      setSearchDocStr(search_str);
      if (createField?.location_id && isClinicEnable) {
        setIsLoading(true);
        let params = { search_str };
        ApiClient.getClinicAdmin(createField?.location_id, params)
          .then((res) => {
            setIsLoading(false);
            setSearchDoctorList(res.data);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      } else {
        setIsLoading(true);
        let params = { search_str, role: 'doctor', remove_inactive_admins: true };
        ApiClient.getSearchTeam(params)
          .then((res) => {
            setIsLoading(false);
            setSearchDoctorList(res.data);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    } else {
      setSearchDocStr('');
      setSearchDoctorList([]);
    }
  };

  useEffect(() => {
    if (createField?.consultant_id) {
      getDoctorServices(createField?.consultant_id);
    }
  }, [createField?.consultant_id]);

  useEffect(() => {
    const matchedOption = appointmentRecipientOptions.find((option) => option.id === createField?.appointment_recipient);
    setAppointmentRecipient(matchedOption ? matchedOption.id : null);
  }, [createField?.appointment_recipient]);

  useEffect(() => {
    if (patientId && selectedServiceObject) {
      getCrpSessionLeft(patientId, selectedServiceObject.id, selectedSpeciality);
    }
  }, [selectedSpeciality]);

  const getCrpSessionLeft = (id, serviceId, crpType) => {
    ApiClient.getCrpSessionLeft(id, serviceId, crpType)
      .then((res) => {
        setCrpSessionLeft(res.data);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }

  const getDoctorServices = (ids) => {
    setIsLoading(true);
    ApiClient.getDocServices(ids)
      .then((res) => {
        setIsLoading(false);
        setDoctorServices(res.data);
        const newDefaultService = res.data.find((item) => item.title == 'Consultation');
        setServiceDefaultVal(newDefaultService);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const toggleShowAllSlots = () => {
    setShowAllSlots(!showAllSlots);
  };
  const renderDuration = () => {
    let duration =
      new Date(createField?.consult_end_datetime).getHours() * 60 +
      new Date(createField?.consult_end_datetime).getMinutes() -
      (new Date(createField?.consult_datetime).getHours() * 60 + new Date(createField?.consult_datetime).getMinutes());
    let durationHours = parseInt(duration / 60);
    let durationMinutes = duration % 60;
    let value = '';
    if (durationHours > 0) {
      value += durationHours + ' Hours ';
    }
    if (durationMinutes > 0) {
      value += durationMinutes + ' Mins';
    }
    value = createField?.consult_datetime && createField?.consult_end_datetime ? value : '';
    return <CoreInputBox labelStyle={{ fontSize: 10 }} label='Duration' disabled value={value} />;
  };

  useEffect(() => {
    if (createField?.user_id && appointmentRecipient == 'family') {
      getFamilyMembers(createField.user_id);
    }
  }, [createField?.user_id, appointmentRecipient]);

  const getFamilyMembers = (userId) => {
    setIsLoading(true);
    ApiClient.getFamilyMembers(userId)
      .then((res) => {
        setIsLoading(false);
        setFamilyMembers(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const renderFamilyMemberForm = () => {
    if (appointmentRecipient !== 'family' || appointmentID) return null;

    return (
      <div className='family-member-form'>
        <br />
        <h5>Family Member Details</h5>
        <br />
        <div className='mb-2'>
          <Select2DropDown
            label='Select Family Member'
            retuired
            data={[
              ...familyMembers.map((member) =>
                member.user_profile ? { id: member.user_profile.id, title: member.user_profile.full_name } : { id: member.id, title: member.full_name }
              ),
              { id: 'new', title: '+ Add New Family Member' },
            ]}
            onSelect={(id) => {
              if (id === 'new') {
                setSelectedFamilyMember(null);
                setFamilyMemberDetails({
                  family_member_name: '',
                  family_member_age: '',
                  family_member_gender: '',
                  family_member_phone: '',
                  relationship: '',
                });
              } else {
                const member = familyMembers.find((m) => (m.user_profile && m.user_profile.id === parseInt(id)) || m.id === parseInt(id));
                setSelectedFamilyMember(member);
                setFamilyMemberDetails({
                  family_member_name: member.user_profile ? member.user_profile.full_name : member.full_name,
                  family_member_age: member.user_profile ? member.user_profile.age : member.age || '',
                  family_member_gender: member.user_profile ? member.user_profile.gender : member.gender,
                  family_member_phone: member.user_profile ? member.user_profile.phone : member.phone,
                  relationship: member.relation,
                });
              }
            }}
            value={selectedFamilyMember?.user_profile?.id || selectedFamilyMember?.id || 'new'}
            showMSG={errors?.user_id}
          />
        </div>
        <div className='mb-2'>
          <CoreInputBox
            label={
              <>
                <span className='requiredInput'>*</span> Name
              </>
            }
            value={familyMemberDetails.family_member_name}
            setValue={(val) => setFamilyMemberDetails({ ...familyMemberDetails, family_member_name: val })}
            disabled={!!selectedFamilyMember}
            showMSG={errors?.family_member_name}
          />
        </div>
        <div className='mb-2'>
          <CoreInputBox
            label='Age'
            validateNumbers
            retuired
            value={familyMemberDetails.family_member_age}
            setValue={(val) => setFamilyMemberDetails({ ...familyMemberDetails, family_member_age: val })}
            disabled={!!selectedFamilyMember}
            showMSG={errors?.family_member_age}
          />
        </div>
        <div className='mb-2'>
          <Select2DropDown
            label='Gender'
            retuired
            value={familyMemberDetails.family_member_gender}
            data={[
              { title: 'Male', id: 'male' },
              { title: 'Female', id: 'female' },
              { title: 'Other', id: 'other' },
            ]}
            onSelect={(val) => setFamilyMemberDetails({ ...familyMemberDetails, family_member_gender: val })}
            disabled={!!selectedFamilyMember}
            showMSG={errors?.family_member_gender}
          />
        </div>
        <div className='mb-2'>
          <CoreInputBox
            validateNumbers
            maxLength={10}
            label={
              <>
                <span className='requiredInput'>*</span> Phone
              </>
            }
            value={familyMemberDetails.family_member_phone}
            setValue={(val) => setFamilyMemberDetails({ ...familyMemberDetails, family_member_phone: val })}
            disabled={!!selectedFamilyMember}
            showMSG={errors?.family_member_phone}
          />
        </div>
        <div className='mb-2'>
          <Select2DropDown
            label='Relation'
            data={[
              { title: 'Mother-Son', id: 'Mother-Son' },
              { title: 'Mother-Daughter', id: 'Mother-Daughter' },
              { title: 'Father-Son', id: 'Father-Son' },
              { title: 'Father-Daughter', id: 'Father-Daughter' },
              { title: 'Spouse', id: 'Spouse' },
              { title: 'Sibling', id: 'Sibling' },
              { title: 'Grandparent-Grandchild', id: 'Grandparent-Grandchild' },
              { title: 'Aunt/Uncle-Niece/Nephew', id: 'Aunt/Uncle-Niece/Nephew' },
              { title: 'Cousin', id: 'Cousin' },
              { title: 'Other', id: 'Other' },
            ]}
            onSelect={(val) => setFamilyMemberDetails({ ...familyMemberDetails, relationship: val })}
            value={familyMemberDetails.relationship || ''}
            disabled={!!selectedFamilyMember}
          />
        </div>
      </div>
    );
  };

  function getServiceTitleById(service_id, service_arr) {
    if (!Array.isArray(service_arr)) {
      return "Invalid service_arr: Must be an array"; // Handle invalid input
    }

    for (const service of service_arr) {
      if (service.id === service_id) {
        return service.title;
      }
    }

    return null; // Return null if no matching service is found
  }

  const handleServiceSelect = (id) => {
    setSelectedServiceId(id);
    setCreateField({ ...createField, service_id: id });

    // Find the service title from doctorServices and update state
    const selectedService = doctorServices.find(service => service.id === id);
    setSelectedServiceTitle(selectedService ? selectedService.title : null);

    // Check if patientServiceList exists before trying to find
    if (patientServiceList) {
      // First try to find by exact ID match
      let serviceObject = patientServiceList.find(
        (service) => service.id === id
      );
      
      // If not found by ID, try to find by service title
      if (!serviceObject) {
        serviceObject = patientServiceList.find(
          (service) => service?.service?.title === 'crp'
        );
      }

      // Update the selectedServiceObject state
      if (serviceObject) {
        setSelectedServiceObject(serviceObject);
      }
    } else {
      console.log("patientServiceList is not available yet");
    }
  };

  // Add this useEffect to handle appointment details when editing
  useEffect(() => {
    if (effectiveAppointmentDetail && patientServiceList) {
      
      // If this is a CRP appointment, find the corresponding service
      if (effectiveAppointmentDetail.patient_service_id) {
        // Try to find the service in patientServiceList
        const serviceObject = patientServiceList.find(
          service => service.id === effectiveAppointmentDetail.patient_service_id
        );
        
        if (serviceObject) {
          //setSelectedServiceObject(serviceObject);
          
          // Set the speciality if available
          if (effectiveAppointmentDetail.crp_type) {
            setSelectedSpeciality(effectiveAppointmentDetail.crp_type);
            
            // Update createField with the values from the appointment
            setCreateField(prevState => ({
              ...prevState,
              patient_service: effectiveAppointmentDetail.patient_service_id,
              crp_type: effectiveAppointmentDetail.crp_type
            }));
          }
        } else {
          console.log("Service object not found in patient service list");
          
          // If we can't find it in the list, create a placeholder object
          // This is useful when the API hasn't returned the full details yet
          // setSelectedServiceObject({
          //   id: effectiveAppointmentDetail.patient_service_id,
          //   crp_package_name: effectiveAppointmentDetail.patient_service_name || "Package",
          //   created_at: effectiveAppointmentDetail.created_at || new Date().toISOString(),
          //   service_category: { 
          //     title: effectiveAppointmentDetail.service_category || "Service" 
          //   }
          // });
        }
      }
      
      // Set the service title if we have a service in the appointment detail
      if (effectiveAppointmentDetail.service?.title) {
        setSelectedServiceTitle(effectiveAppointmentDetail.service.title);
      }
    }
  }, [effectiveAppointmentDetail, patientServiceList]);

  // Add this useEffect to properly initialize form values when editing an appointment
  useEffect(() => {
    if (effectiveAppointmentDetail && (appointmentID || location?.state?.edit === 'edit')) {
      // Set service_id from the appointment detail
      if (effectiveAppointmentDetail.service?.id) {
        setCreateField(prev => ({
          ...prev,
          service_id: selectedServiceObject?.service?.id || effectiveAppointmentDetail.service.id
        }));
      }
      
      // Get patient_service from either source
      const patientServiceId = effectiveAppointmentDetail.patient_service || 
                              effectiveAppointmentDetail.patient_service_id || 
                              location?.state?.appointmentDetail?.patient_service;
      
      // Set patient_service and crp_type if this is a CRP appointment
      if (patientServiceId) {
        // Get crp_type from either source
        const crpType = effectiveAppointmentDetail.crp_type || 
                       location?.state?.appointmentDetail?.crp_type;
        setCreateField(prev => ({
          ...prev,
          patient_service: patientServiceId,
          crp_type: crpType || null
        }));
        
        // Set selectedSpeciality if available
        if (crpType) {
          setSelectedSpeciality(crpType);
        }
      }
    }
  }, [effectiveAppointmentDetail, appointmentID, location?.state?.edit]);

  function formatToIST(utcDateString) {
    // Convert the UTC date string to a Date object
    const utcDate = new Date(utcDateString);
  
    // Format the date in Indian Standard Time (IST)
    const options = {
      timeZone: 'Asia/Kolkata',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
  
    // Use toLocaleString to format the date
    const istDateString = utcDate.toLocaleString('en-IN', options);
  
    return istDateString;
  }

  // Add this useEffect to ensure the service is properly selected when editing
  useEffect(() => {
    if ((effectiveAppointmentDetail || location?.state?.appointmentDetail) && doctorServices.length > 0) {
      // Get service ID from either source
      const serviceId = effectiveAppointmentDetail?.service?.id || 
                       location?.state?.appointmentDetail?.service?.id;
      
      if (serviceId) {
        // Find the matching service in doctorServices
        const matchingService = doctorServices.find(service => service.id === serviceId);
        
        if (matchingService) {
          // Directly set the service_id in createField
          setCreateField(prev => ({
            ...prev,
            service_id: matchingService.id
          }));
          
          // Set the selected service title
          setSelectedServiceTitle(matchingService.title);
          
          // If this is a CRP service, also handle the CRP-specific fields
          if (matchingService.title === 'crp' && selectedServiceObject) {
            // Get patient_service from either source
            const patientServiceId = effectiveAppointmentDetail?.patient_service || 
                                    effectiveAppointmentDetail?.patient_service_id || 
                                    location?.state?.appointmentDetail?.patient_service;
            
            // Get crp_type from either source
            const crpType = effectiveAppointmentDetail?.crp_type || 
                           location?.state?.appointmentDetail?.crp_type;
            setCreateField(prev => ({
              ...prev,
              service_id: matchingService.id,
              patient_service: patientServiceId || selectedServiceObject.id,
              crp_type: crpType || null
            }));
          }
        } else {
          console.log("No matching service found in doctorServices");
        }
      }
    }
  }, [effectiveAppointmentDetail, location?.state?.appointmentDetail, doctorServices, selectedServiceObject]);
  // Modify the CRP section to handle null selectedServiceObject
  {getServiceTitleById(createField?.service_id, doctorServices) == 'crp' && (
    <>
      <div className='mb-2'>
        <Select2DropDown
          retuired
          searchable={false}
          label={'Select CRP'}
          value={createField?.patient_service || ''}
          data={crpServices
            .filter(service => {
              // First check if service is not done
              if (service?.status === "done") return false;
              
              // Then check if all sessions are completed
              if (!service?.crp_details?.length) return false;
              
              // Check if all details have completed their sessions
              return !service.crp_details.every(detail => 
                detail.session_booked === detail.session_delivered + detail.session_scheduled
              );
            })
            .map(service => ({
              title: `${service?.crp_package_name || 'Package'}-${formatToIST(service?.created_at) || ''}-${service?.service_category?.title || 'Service'}`,
              id: service?.id
            }))}
          showMSG={errors?.patient_service}
          onSelect={(val) => {
            setCreateField({
              ...createField,
              patient_service: val || null,
            });
            // Find and set the selected service object from crpServices
            const selectedService = crpServices.find(service => service.id === val);
            if (selectedService) {
              setSelectedServiceObject(selectedService);
            }
          }}
        />
      </div>
      <div className='mb-2'>
        <Select2DropDown
          retuired
          searchable={false}
          label={'Select Speciality'}
          valueKey={'crp_type'}
          labelKey={'title'}
          value={createField?.crp_type || location?.state?.appointmentDetail?.crp_type || effectiveAppointmentDetail?.crp_type || ''}
          data={selectedServiceObject?.crp_details?.filter(detail => detail.session_booked !== detail.session_delivered).map(detail => ({
            crp_type: detail.crp_type,
            title: detail.crp_type,
            session_scheduled: detail.session_booked,
            session_left: detail.session_booked - detail.session_delivered
          }))}
          showMSG={errors?.crp_type}
          onSelect={(val, obj) => {
            setCreateField({
              ...createField,
              crp_type: obj?.crp_type,
            });
            setSelectedSpeciality(val);
            setSelectedSpecialityObject(obj);
          }}
        />
      </div>
      <div className='mb-2'>
        <CoreInputBox
          label='Sessions Scheduled'
          value={
            (selectedServiceObject?.crp_details?.find(detail => detail.crp_type === selectedSpeciality)?.session_scheduled) ??
            effectiveAppointmentDetail?.session_scheduled ?? 
            location?.state?.appointmentDetail?.session_scheduled ?? ''
          }
          disabled={true}
        />
      </div>
      <div className='mb-2'>
        <CoreInputBox
          label='Sessions Left'
          value={
            (selectedServiceObject?.crp_details?.find(detail => detail.crp_type === selectedSpeciality)?.session_left) ??
            effectiveAppointmentDetail?.session_left ?? 
            location?.state?.appointmentDetail?.session_left ?? ''
          }
          disabled={true}
        />
      </div>
    </>
  )}

  return (
    <div className='appontmentModle-body d-flex flex-column'>
      {isNivanCare && props?.userData?.role != 'ee' && (!appointmentID || (appointmentID && effectiveAppointmentDetail?.service?.id == serviceID)) ? (
        <div className='mb-3 d-flex w-100 align-items-center  checkBoxBtn'>
          <CoreCheckBox
            disabled={appointmentID}
            onChange={(value, checked) => {
              setIsBreak(checked);
              setCreateField(
                checked
                  ? {
                    ...createField,
                    user_id: userID,
                    consult_type: 'physical',
                    service_id: serviceID,
                    appointment_fee: 0,
                  }
                  : {
                    ...createField,
                    user_id: null,
                    consult_type: null,
                    service_id: null,
                    appointment_fee: null,
                  }
              );
            }}
            checked={isBreak}
            type='checkbox'
            class='btn-check'
            id='btn-check-2-outlined'
            autocomplete='off'
          />
          <label for='btn-check-2-outlined'>
            <strong className='pointer'>Add Break</strong>
          </label>
        </div>
      ) : null}
      {!isBreak && (
        <div className='mb-2'>
          <Select2DropDown
            isValidation
            disabled={disablePatient || (appointmentID && selectedServiceTitle === 'crp')}
            retuired
            data={searchPatientStr?.length ? searchPatientList : patientList}
            onSelect={(_id, _obj) => {
              setPatientId(_id);
              if (_id == null) {
                navigate('/patient/new', { state: { key: 'appointment', name: searchPatientStr } });
              }
              if (_obj?.id && patientList?.findIndex((item2) => item2?.id == _obj?.id) == -1) {
                patientList.push(_obj);
                setPatientList([...patientList]);
              }
              setSearchPatientStr('');
              // setCreateField({ ...createField, user_id: _obj.id });
              // setCreateField({ 
              //   ...createField, 
              //   user_id: _obj.id,
              //   service_id: null,
              //   patient_service: null,
              //   crp_type: null,
              //   consultant_id: null,
              //   consult_datetime: null,
              //   consult_end_datetime: null,
              //   appointment_fee: null,
              //   appointment_recipient: null,
              //   meeting_link: null,
              //   walk_in: false
              // });
              
              // Reset other state variables
              // setSearchClinicStr('');
              // setSelectedServiceId(null);
              // setSelectedServiceObject(null);
              // setSelectedSpeciality(null);
              // setSelectedSpecialityObject(null);
              // setAppointmentDate(null);
              // setAppointmentFrom(null);
              // setAppointmentTo(null);
              // setAvailableSlots([]);
              // setAppointmentRecipient(null);
              // setSelectedFamilyMember(null);
              // setCrpSessionLeft(null);
              // setFamilyMemberDetails({
              //   family_member_name: null,
              //   relationship: null,
              //   family_member_age: null,
              //   family_member_gender: null,
              //   family_member_phone: null,
              // });

              setCreateField(prevState => ({
                ...prevState,
                user_id: _obj.id
              }));
              
              // Reset only the family member related states
              setAppointmentRecipient(null);
              setSelectedFamilyMember(null);
              setFamilyMemberDetails({
                family_member_name: null,
                relationship: null,
                family_member_age: null,
                family_member_gender: null,
                family_member_phone: null,
              });
            }}
            onSearch={(searchStr) => {
              handlePatientSearch(searchStr);
            }}
            searchBy={(keyword, label, item) => {
              return item?.phone?.includes(keyword) || item?.full_name?.toLowerCase()?.includes(keyword.toLowerCase());
            }}
            showMSG={errors?.user_id}
            value={createField?.user_id || ''}
            label={`Patient's Name or Phone No.`}
            labelKey={'full_name'}
            searchable={true}
            style={{ width: '100%' }}
            placeholder={`Patient's Name or Phone No.`}
          />
        </div>
      )}

      {!isBreak && (
        <div className='mb-2'>
          <Select2DropDown
            retuired
            searchable={false}
            label={'Appointment Type'}
            value={createField?.consult_type || ''}
            data={[
              { title: 'Physical', id: 'physical' },
              // { title: 'Virtual', id: 'virtual' },
            ]}
            showMSG={errors?.consult_type}
            onSelect={(val) =>
              setCreateField({
                ...createField,
                consult_type: val == '' ? null : val,
                meeting_link: null,
              })
            }
            disabled={isDoctorLoggedIn}
          />
        </div>
      )}

      {createField.consult_type == 'physical' && isBabyMd && (
        <div className='mb-2'>
          <CommonRenderCheckBox
            label='Walk In'
            id='flexCheckDefault8'
            htmlFor='flexCheckDefault8'
            onChange={(data) => {
              setCreateField({
                ...createField,
                walk_in: !createField?.walk_in,
              });
            }}
            checked={createField?.walk_in}
          />
        </div>
      )}
      {/* <div className='mb-2'>
        <Select2DropDown
          retuired
          searchable={false}
          label={'Appointment Type'}
          value={createField?.walk_in || ''}
          data={[{ title: 'Walk In', id: 'walk_in' }]}
          showMSG={errors?.consult_type}
          onSelect={(val) =>
            setCreateField({
              ...createField,
              walk_in: val,
            })
          }
        />
      </div> */}
      {/* 
      {
        getServiceTitleById(createField?.service_id, doctorServices) == 'crp'  && (
          <div className='mb-2'>
            <Select2DropDown
              //retuired
              searchable={false}
              label='Select CRP'
              value={createField?.service_id || ''}
              data={doctorServices}
            />
          </div>
        )
      } */}

      {isClinicEnable ? (
        <div className='mb-2'>
          <Select2DropDown
            isValidation
            retuired
            disabled={(appointmentID && selectedServiceTitle === 'crp') || isDoctorLoggedIn}
            data={searchClinicStr?.length ? [...new Map([...searchClinicList, ...clinicList].map((item) => [item?.id, item])).values()] : clinicList}
            onSelect={(_id, _obj) => {
              if (_obj?.id && clinicList?.findIndex((item2) => item2?.id == _obj?.id) == -1) {
                clinicList.push(_obj);
                setClinicList([...clinicList]);
              }
              setSearchClinicStr('');
              setCreateField({ ...createField, location_id: _obj?.id || null, consultant_id: null });
            }}
            onSearch={(searchStr) => {
              setSearchClinicStr(searchStr);
              handleClinicSearch(searchStr);
            }}
            onClean={() => {
              setSearchClinicStr('');
              setCreateField({ ...createField, location_id: null, consultant_id: null });
            }}
            value={createField?.location_id || ''}
            showMSG={errors?.location_id}
            label={'Clinic'}
            labelKey={'name'}
            searchable={true}
            style={{ width: '100%' }}
            placeholder='Clinic'
            //disabled={isDoctorLoggedIn}
          />
        </div>
      ) : null}

      <div className='mb-2'>
        <Select2DropDown
          isValidation
          retuired
          disabled={(appointmentID && selectedServiceTitle === 'crp') || isDoctorLoggedIn}
          data={
            searchDocStr?.length
              ? searchDoctorList?.filter((item4) => item4?.role == 'doctor' || item4?.role == 'doctor_pms')
              : doctorList?.filter((item4) => item4?.role == 'doctor' || item4?.role == 'doctor_pms')
          }
          onSelect={(_id, _obj) => {
            if (_obj?.id && doctorList?.findIndex((item2) => item2?.id == _obj?.id) == -1) {
              doctorList.push(_obj);
              setDoctorList([...doctorList]);
            }
            setSearchDocStr('');
            setCreateField({ ...createField, consultant_id: _id });
          }}
          onSearch={(searchStr) => {
            handleDoctorSearch(searchStr);
          }}
          onClean={() => {
            setSearchDocStr('');
            setCreateField({ ...createField, consultant_id: null });
          }}
          value={createField?.consultant_id || ''}
          showMSG={errors?.consultant_id}
          labelKey={'full_name'}
          searchable={true}
          style={{ width: '100%' }}
          placeholder='Doctor'
          label='Doctor'
          //disabled={isDoctorLoggedIn}
        />
      </div>
      {configData?.findIndex((configItem) => configItem?.key == 'enable_meeting_link' && !configItem?.isActive) != -1 &&
        createField.consult_type == 'virtual' &&
        !isBreak ? (
        <div className='mb-2'>
          <CoreInputBox
            // retuired
            value={createField?.meeting_link || ''}
            placeholder='Meeting Link'
            label='Meeting Link'
            setValue={(val) => {
              setCreateField({ ...createField, meeting_link: val });
            }}
            showMSG={errors?.meeting_link}
          />
        </div>
      ) : null}
      {!isBreak && (
        <div className='mb-2'>
          <Select2DropDown
            retuired
            disabled={(appointmentID && selectedServiceTitle === 'crp') || isDoctorLoggedIn}
            onSelect={(id) => handleServiceSelect(id)}
            value={createField.service_id}
            data={doctorServices}
            showMSG={errors?.service_id ? errors?.service_id : Array.isArray(errors?.creation) ? errors.creation[0] : ''}
            searchable={false}
            label='Service'
            //disabled={isDoctorLoggedIn}
          />
        </div>
      )}
      {!isBreak && (
        <div className='mb-2'>
          <Select2DropDown
            disabled={isDoctorLoggedIn}
            retuired
            searchable={false}
            label={'Appointment Type'}
            value={createField?.consult_type || ''}
            data={[
              { title: 'Physical', id: 'physical' },
            ]}
            showMSG={errors?.consult_type}
            onSelect={(val) =>
              setCreateField({
                ...createField,
                consult_type: val == '' ? null : val,
                meeting_link: null,
              })
            }
          />
        </div>
      )}
      {(!isBreak && (
        selectedServiceTitle === 'crp' || 
        effectiveAppointmentDetail?.service?.title === 'crp' || 
        location?.state?.appointmentDetail?.service?.title === 'crp'
      )) && (
        <>
          <div className='mb-2'>
            {appointmentID ? (
              <CoreInputBox
                label='Select CRP'
                value={`${selectedServiceObject?.crp_package_name || 'Package'}-${formatToIST(selectedServiceObject?.created_at) || ''}-${selectedServiceObject?.service_category?.title || 'Service'}`}
                disabled={true}
              />
            ) : (
              <Select2DropDown
                retuired
                searchable={false}
                label={'Select CRP'}
                value={createField?.patient_service || ''}
                data={crpServices
                  .filter(service => {
                    // First check if service is not done
                    if (service?.status === "done") return false;
                    
                    // Then check if all sessions are completed
                    if (!service?.crp_details?.length) return false;
                    
                    // Check if all details have completed their sessions
                    return !service.crp_details.every(detail => 
                      detail.session_booked === detail.session_delivered + detail.session_scheduled
                    );
                  })
                  .map(service => ({
                    title: `${service?.crp_package_name || 'Package'}-${formatToIST(service?.created_at) || ''}-${service?.service_category?.title || 'Service'}`,
                    id: service?.id
                  }))}
                showMSG={errors?.patient_service}
                onSelect={(val) => {
                  setCreateField({
                    ...createField,
                    patient_service: val || null,
                  });
                  // Find and set the selected service object from crpServices
                  const selectedService = crpServices.find(service => service.id === val);
                  if (selectedService) {
                    setSelectedServiceObject(selectedService);
                  }
                }}
              />
            )}
          </div>
          <div className='mb-2'>
            {appointmentID ? (
              <CoreInputBox
                label='Select Speciality'
                value={createField?.crp_type || location?.state?.appointmentDetail?.crp_type || effectiveAppointmentDetail?.crp_type || ''}
                disabled={true}
              />
            ) : (
              <Select2DropDown
                retuired
                searchable={false}
                label={'Select Speciality'}
                valueKey={'crp_type'}
                labelKey={'title'}
                value={createField?.crp_type || location?.state?.appointmentDetail?.crp_type || effectiveAppointmentDetail?.crp_type || ''}
                data={selectedServiceObject?.crp_details?.filter(detail => detail.session_booked !== detail.session_delivered + detail.session_scheduled).map(detail => ({
                  crp_type: detail.crp_type,
                  title: detail.crp_type,
                  session_scheduled: detail.session_booked,
                  session_left: detail.session_booked - detail.session_delivered
                }))}
                showMSG={errors?.crp_type}
                onSelect={(val, obj) => {
                  setCreateField({
                    ...createField,
                    crp_type: obj?.crp_type,
                  });
                  setSelectedSpeciality(val);
                  setSelectedSpecialityObject(obj);
                }}
              />
            )}
          </div>
          <div className='mb-2'>
            <CoreInputBox
              label='Sessions Scheduled'
              value={
                (selectedServiceObject?.crp_details?.find(detail => detail.crp_type === selectedSpeciality)?.session_scheduled) ??
                effectiveAppointmentDetail?.session_scheduled ?? 
                location?.state?.appointmentDetail?.session_scheduled ?? ''
              }
              disabled={true}
            />
          </div>
          <div className='mb-2'>
            <CoreInputBox
              label='Sessions Delivered'
              value={
                (selectedServiceObject?.crp_details?.find(detail => detail?.crp_type === selectedSpeciality)?.session_delivered) ??
                effectiveAppointmentDetail?.session_delivered ?? 
                location?.state?.appointmentDetail?.session_delivered ?? ''
              }
              disabled={true}
            />
          </div>
          <div className='mb-2'>
            <CoreInputBox
              label='Sessions Left'
              value={
                (selectedServiceObject?.crp_details?.find(detail => detail.crp_type === selectedSpeciality)?.session_left) ??
                effectiveAppointmentDetail?.session_left ??
                location?.state?.appointmentDetail?.session_left ?? ''
              }
              disabled={true}
            />
          </div>
        </>
      )}
      <div className='mb-2'>
        <label>
          <span className='requiredInput'> * </span> Date
        </label>
        <br />
        <DatePicker
          onSelect={(date) => {
            setAppointmentDate(new Date(date));
            setAppointmentFrom(null);
            setAppointmentTo(null);
            setCreateField({ ...createField, consult_datetime: null, consult_end_datetime: null });
          }}
          className='w-100'
          onClean={() => {
            setAppointmentDate(null);
            setAppointmentFrom(null);
            setAppointmentTo(null);
            setAvailableSlots([]);
            setCreateField({ ...createField, consult_datetime: null, consult_end_datetime: null });
          }}
          value={appointmentDate}
          disabled={isDoctorLoggedIn}
        />
      </div>
      {!isBreak && (
        <div className='mb-2'>
          <Select2DropDown
            retuired
            disabled={(appointmentID && selectedServiceTitle === 'crp') || isDoctorLoggedIn}
            onSelect={(id) => {
              setAppointmentRecipient(id);
              setCreateField({ ...createField, appointment_recipient: id });
            }}
            value={appointmentRecipient}
            data={[
              { title: 'Self', id: 'self' },
              { title: 'Family', id: 'family' },
            ]}
            showMSG={errors?.appointment_recipient}
            searchable={false}
            label='Appointment Recipient'
            //disabled={isDoctorLoggedIn}
          />
        </div>
      )}
      {renderFamilyMemberForm()}
      <div className='mb-2 '>
        <label>Time</label>
        <div className='d-flex justify-content-between gap-10'>
          <div className='w-100 d-flex flex-column'>
            <div>{renderDuration()}</div>
          </div>
          <div className='w-100  d-flex flex-column'>
            <label style={{ fontSize: '10px' }} className='inputlabel'>
              <span className='requiredInput'> * </span> From
            </label>
            <DatePicker
              format='hh:mm a'
              showMeridian={true}
              hideMinutes={(minute) => (isBabyMd ? minute % 15 !== 0 : null)}
              onClean={(e) => {
                setAppointmentFrom(null);
                setCreateField({ ...createField, consult_datetime: null });
              }}
              onSelect={(time) => {
                let consultStartTime = new Date(appointmentDate);
                consultStartTime.setHours(new Date(time).getHours(), new Date(time).getMinutes());
                setAppointmentFrom(new Date(consultStartTime));
                setCreateField({ ...createField, consult_datetime: new Date(consultStartTime).toISOString() });
              }}
              disabled={(!createField?.consult_datetime && !appointmentDate) || props?.userData?.role == 'ee' || isDoctorLoggedIn}
              value={createField?.consult_datetime ? new Date(createField?.consult_datetime) : appointmentFrom}
            />
            {errors?.consult_datetime ? (
              <div className='inputMsg' style={{ color: 'red' }}>
                {errors?.consult_datetime[0]}
              </div>
            ) : null}
          </div>
          <div className='w-100  d-flex flex-column'>
            <label style={{ fontSize: '10px' }} className='inputlabel'>
              <span className='requiredInput'> * </span> To
            </label>
            <DatePicker
              format='hh:mm a'
              showMeridian
              hideMinutes={(minute) => (isBabyMd ? minute % 15 !== 0 : null)}
              onClean={(e) => {
                setAppointmentTo(null);
                setCreateField({ ...createField, consult_end_datetime: null });
              }}
              onSelect={(time) => {
                let consultStartTime = new Date(appointmentDate);
                consultStartTime.setHours(new Date(time).getHours(), new Date(time).getMinutes());
                setAppointmentTo(new Date(consultStartTime));
                setCreateField({ ...createField, consult_end_datetime: new Date(consultStartTime).toISOString() });
              }}
              disabled={(!createField?.consult_end_datetime && !appointmentDate) || props?.userData?.role == 'ee' || isDoctorLoggedIn}
              value={createField?.consult_end_datetime ? new Date(createField?.consult_end_datetime) : appointmentTo}
            />
            {errors?.consult_end_datetime ? (
              <div className='inputMsg' style={{ color: 'red' }}>
                {errors?.consult_end_datetime[0]}
              </div>
            ) : null}
          </div>
        </div>
        <div className='d-flex justify-content-between gap-10'></div>
        <br />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {errors?.appointment ? (
            <div className='inputMsg' style={{ color: 'red' }}>
              {errors?.appointment[0]}
            </div>
          ) : null}
        </div>
      </div>
      {(isClinicEnable && !isDoctorLoggedIn) ? (
        <div className='mb-2'>
          <label className='mb-1' style={{ fontSize: '10px' }}>
            Recommended Time Slots
          </label>
          {isBabyMd ? (
            <div className='d-flex gap-10 justify-content-start flex-wrap'>
              {availableSlots?.slice(0, showAllSlots ? availableSlots.length : 10).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`slotTag text-nowrap ${isActiveSlots(index) ? 'active' : ''}`}
                    onClick={() => {
                      setCreateField({
                        ...createField,
                        consult_datetime: new Date(item[0]).toISOString(),
                        consult_end_datetime: new Date(item[1]).toISOString(),
                      });
                      setActiveIndex(index);
                    }}
                  >
                    {timeFormat(item[0])} - {timeFormat(item[1])}
                  </div>
                );
              })}
              {availableSlots.length > 10 && (
                <div onClick={toggleShowAllSlots} className='d-flex w-100 justify-content-center mt-2 slotTag'>
                  <span class='material-icons-outlined'>{showAllSlots ? 'expand_less' : 'expand_more'}</span>
                  {showAllSlots ? 'View Less' : 'View More'}
                </div>
              )}
            </div>
          ) : (
            <div className='d-flex gap-10 justify-content-start  flex-wrap'>
              {availableSlots?.map((item) => {
                return (
                  <div
                    className='slotTag  text-nowrap '
                    onClick={() => {
                      setCreateField({
                        ...createField,
                        consult_datetime: new Date(item[0]).toISOString(),
                        consult_end_datetime: new Date(item[1]).toISOString(),
                      });
                    }}
                  >
                    {timeFormat(item[0])} - {timeFormat(item[1])}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : null}

      {(!isBreak && selectedServiceTitle !== 'crp' && userData?.role != 'doctor') && (
        <div className='mb-2'>
          <CoreInputBox
            retuired
            value={createField?.appointment_fee || ''}
            placeholder='Appointment Fee'
            label='Appointment Fee'
            setValue={(val) => {
              setCreateField({ ...createField, appointment_fee: val });
            }}
            disabled={effectiveAppointmentDetail?.patient_attendance_status}
            showMSG={errors?.appointment_fee}
          />
        </div>
      )}
      
      {
        userData?.role != 'doctor' && (
          <div className='mb-2 mt-3'>
            <div className='d-flex justify-content-between gap-10 schedulButton'>
              <div
                className='reusableBtnActive  reusableBtn  w-100'
                onClick={() => {
                  const updatedCreateField = {
                    ...createField,
                    appointment_recipient: isBreak ? 'self' : appointmentRecipient,
                    ...(appointmentRecipient === 'family' && {
                      family_member_id: selectedFamilyMember?.user_profile?.id || selectedFamilyMember?.id,
                      family_member_name: selectedFamilyMember?.user_profile?.family_member_name || familyMemberDetails?.family_member_name,
                      family_member_age: selectedFamilyMember?.user_profile?.family_member_age || familyMemberDetails?.family_member_age,
                      family_member_gender: selectedFamilyMember?.user_profile?.family_member_gender || familyMemberDetails?.family_member_gender,
                      family_member_phone: selectedFamilyMember?.user_profile?.family_member_phone || familyMemberDetails?.family_member_phone,
                      relationship: familyMemberDetails.relationship,
                    }),
                  };
                  onClick(updatedCreateField);
                }}
              >
                {appointmentID ? 'Update' : 'Create'} {isBreak ? ' Break' : ' Appointment'}
              </div>
            </div>
            {!hideRecord && !isBreak && (userData?.role !== 'ee' || userData?.role === 'ee_manager') ? (
              <div className='d-flex justify-content-between gap-10 schedulButton mt-4'>
                <div
                  className='borderBtn  w-100'
                  onClick={() => {
                    const updatedCreateField = {
                      ...createField,
                      appointment_recipient: isBreak ? 'self' : appointmentRecipient,
                      ...(appointmentRecipient === 'family' && {
                        family_member_id: selectedFamilyMember?.user_profile?.id || selectedFamilyMember?.id,
                        family_member_name: selectedFamilyMember?.user_profile?.family_member_name || familyMemberDetails?.family_member_name,
                        family_member_age: selectedFamilyMember?.user_profile?.family_member_age || familyMemberDetails?.family_member_age,
                        family_member_gender: selectedFamilyMember?.user_profile?.family_member_gender || familyMemberDetails?.family_member_gender,
                        family_member_phone: selectedFamilyMember?.user_profile?.family_member_phone || familyMemberDetails?.family_member_phone,
                        relationship: familyMemberDetails.relationship,
                      }),
                    };
                    onClick(updatedCreateField,true);
                  }}
                >
                  {appointmentID ? 'Update' : 'Create'} Appointment & Record Payment
                </div>
              </div>
            ) : null}
          </div>
        )
      }
    </div>
  );
}

export default AppointmentForm;
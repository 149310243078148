import { AdminPanelHeader } from 'components';
import React, { useEffect, useState } from 'react';
import './AppointmentVer2.scss';
import { DatePicker, Modal } from 'rsuite';
import DoctorAppointment from './DoctorAppointment';
import DoctorSchedulTile from './DoctorSchedulTile';
import Select2DropDown from '../common/Select2DropDown';
import { dateFormat, dateFormatStringSingleDate } from '../Helper';
import useApiManager from 'networking/ApiManager';
import TagSelectPicker from '../common/TagSelectPicker';
import AppointmentForm from './AppointmentForm';
import CommonAlert from '../CommonAlert';
import Loader from '../Loader';
import RecordPayment from './RecordPayment';
import AppointmentCancel from './AppointmentCancel';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import CoreInputBox from '../CoreInputBox';
import { useFilter } from '../FilterContext';
import TagSelectPicker2 from '../common/TagSelectPicker2';

function AppointmentVer2(props) {
  let defaultAppointmentData = {
    user_id: null,
    consult_type: null,
    service_id: null,
    location_id: null,
    consultant_id: null,
    consult_datetime: null,
    consult_end_datetime: null,
    appointment_fee: null,
    meeting_link: null,
    walk_in: false,
  };
  let { isNivanCare, isBabyMd } = props;
  const ApiClient = useApiManager();
  const getCockpitData = useGetCockpitDataQuery();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  let configData = getCockpitData?.data?.data?.business_config;
  const [isClinicShow, setIsClinicShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [recordModal, setRecordModal] = useState(false);
  const [increaseNumber, setIncreaseNumber] = useState(0);
  const [clinicList, setClinicList] = useState([]);
  const [searchClinicStr, setSearchClinicStr] = useState('');
  const [searchClinicList, setSearchClinicList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [statusItem, setStatusItem] = useState('');
  const { selectedClinic, setSelectedClinic, selectedDoc, setSelectedDoc, activeDate, setActiveDate, selectedDep, setSelectedDep } = useFilter();
  const [appointmentList, setAppointmentList] = useState([]);
  const [errors, setErrors] = useState({});
  const [recordErrors, setRecordErrors] = useState({});
  const [isShowAlert, setIsShowAlert] = useState();
  const [alertType, setAlertType] = useState('alert-success');
  const [appointmentID, setAppointmentId] = useState(null);
  const [createdAppointment, setCreatedAppointment] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [businessConfig, setBusinessConfig] = useState([]);
  const [createField, setCreateField] = useState(defaultAppointmentData);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [appointmentCount, setAppointmentCount] = useState({});
  const location = useLocation();
  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    location.state.patientName = null;
  };

  useEffect(() => {
    if (!recordModal) {
      setRecordErrors({});
      setCreatedAppointment({
        payment_type: null,
        payment_mode: null,
        payment_from: null,
        amount: null,
        patient_attendance_status: null,
        payment_with_id: null,
        payment_with_source: null,
      });
    }
  }, [recordModal]);

  useEffect(() => {
    const getBusinessConfig = async () => {
      const res = await ApiClient.getConfig();
      if (res.status == 200 && res?.data?.configuration?.business_start_time && res?.data?.configuration?.business_end_time) {
        let numberArray = [];

        for (
          let i = parseInt(res?.data?.configuration?.business_start_time?.split(':')[0]);
          i <= parseInt(res?.data?.configuration?.business_end_time?.split(':')[0]);
          i++
        ) {
          numberArray.push(i);
        }
        setBusinessConfig(numberArray);
      }
    };
    getBusinessConfig();
    getDepartment();
  }, []);

  useEffect(() => {
    if (location?.state?.key == 'dashboard' || location?.state?.key == 'comingPatient') {
      setOpen(true);
    }
    setSelectedClinic(localStorage.getItem('selectedClinic')?.length ? [localStorage.getItem('selectedClinic')][0].split(',').map(Number) : []);
    localStorage.getItem('activeDate') && setActiveDate(new Date(localStorage.getItem('activeDate')));
  }, []);

  useEffect(() => {
    if (departmentList.length > 0) {
      const pmsDepartment = departmentList.find(department => department.name === 'PMS');
      if (pmsDepartment) {
        const savedDep = localStorage.getItem('selectedDep');
        if (!savedDep) {
          setSelectedDep([pmsDepartment.id]);
          localStorage.setItem('selectedDep', [pmsDepartment.id]);
        } else {
          setSelectedDep(savedDep.split(',').map(Number));
        }
      }
    }
  }, [departmentList]);

  useEffect(() => {
    let isClinicDisable = configData?.find((configItem) => configItem?.key == 'departments_clinics');
    if (isClinicDisable?.key) {
      setIsClinicShow(isClinicDisable?.isActive);
    }
  }, [configData]);

  const saveAppointment = (data, isRecord) => {
    setIsLoading(true);
    if (appointmentID) {
      ApiClient.updateAppointment(appointmentID, data)
        .then((res) => {
          getAppointment();
          if (isRecord) {
            appointmentDetail(res?.data?.id);
          }
          setIsLoading(false);
          setIsShowAlert('Appointment Updated Successfully');
          handleClose();
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.response?.data?.detail) {
            setErrors(err?.response?.data);
          }
          if (err?.response?.data?.errors) {
            setErrors(err?.response?.data?.errors);
          }
        });
    } else {
      ApiClient.createAppointment(data)
        .then((res) => {
          getAppointment();
          if (isRecord) {
            appointmentDetail(res?.data?.id);
          }
          setIsLoading(false);
          setIsShowAlert('Appointment Created Successfully');
          if (location?.state?.key == 'dashboard' && isBabyMd) {
            handleClose();
            setTimeout(() => {
              navigate('/dashboard');
            }, 1000);
          }
          handleClose();
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.response?.data?.detail) {
            setErrors(err?.response?.data);
          }
          if (err?.response?.data?.errors) {
            setErrors(err?.response?.data?.errors);
          }
        });
    }
  };

  const appointmentDetail = (appointmentId) => {
    setIsLoading(true);
    ApiClient.getAppointmentDetail(appointmentId)
      .then((res) => {
        setIsLoading(false);
        setCreatedAppointment(res.data);
        setRecordModal(true);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleRecordPayment = (idAppointment, recordPaymentData) => {
    setIsLoading(true);
    ApiClient.recordPayment(idAppointment, recordPaymentData)
      .then((res) => {
        setIsLoading(false);
        setIsShowAlert('Payment Recorded Successfully');
        setRecordModal(false);
        getAppointment();
      })
      .catch((err) => {
        setIsLoading(false);
        setRecordErrors(err?.response?.data?.errors);
      });
  };

  const clearErrors = () => {
    setErrors({});
  };

  const handleCancelAppointment = (idAppointment, recordPaymentData, patientID, onSuccess) => {
    setIsLoading(true);
    const requestBody = {
      appointment_cancellation_reason: recordPaymentData.appointment_cancellation_reason,
    };
    ApiClient.cancelAppointment(idAppointment, requestBody)
      .then((res) => {
        setIsLoading(false);
        setIsShowAlert('Appointment Canceled Successfully');
        setCancelModal(false);
        getAppointment();
        if (onSuccess) onSuccess();
        clearErrors();
      })
      .catch((err) => {
        setIsLoading(false);
        setRecordErrors(err?.response?.data?.errors);
        if (err?.response?.data?.appointment_cancellation_reason) {
          setErrors(err.response.data);
        } else {
          setErrors(err?.response?.data?.message || 'An error occurred');
        }
      });
  };

  useEffect(() => {
    if (userData?.id && isBabyMd) {
      getLocations();
    } else if (userData?.id) {
      getLocationsBabyMd();
    }
  }, [userData?.id]);

  useEffect(() => {
    if (!open) {
      setAppointmentId(null);
      setErrors({});
      setCreateField(defaultAppointmentData);
    }
  }, [open]);

  useEffect(() => {
    if (selectedDoc?.length && activeDate) {
      getAppointment();
    } else {
      setAppointmentList([]);
    }
  }, [selectedDoc, activeDate]);

  useEffect(() => {
    if (selectedClinic?.length || selectedDep?.length) {
      getClinicAdmin();
    }
  }, [selectedClinic, selectedDep]);

  useEffect(() => {
    if (!isClinicShow) {
      getDoctor();
    }
  }, [isClinicShow]);

  const getLocations = () => {
    setIsLoading(true);
    let params = { search_str: '', babymd_flag: true };
    if (userData?.role !== 'admin') {
      delete params.babymd_flag;
    }
    ApiClient.getLocationByAdmin(userData?.id, params)
      .then((res) => {
        setIsLoading(false);
        setClinicList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        // setIsLoading(false);
      });
  };

  const getDepartment = () => {
    ApiClient.searchDepartments()
      .then((res) => {
        setDepartmentList(res.data);
      })
      .catch((err) => {
        console.warn('getDepartment error', err);
      });
  };

  const getLocationsBabyMd = () => {
    setIsLoading(true);
    let params = { search_str: '' };
    ApiClient.getLocationByAdmin(userData?.id)
      .then((res) => {
        setIsLoading(false);
        setClinicList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        // setIsLoading(false);
      });
  };

  const calculateAppointmentTotals = (data) => {
    const initialTotals = {
      consult: {
        Booked: 0,
        Show: 0,
        Noshow: 0,
        Arrived: 0,
        Overdue: 0,
        Total: 0
      },
      followup: {
        Booked: 0,
        Show: 0,
        Noshow: 0,
        Arrived: 0,
        Overdue: 0,
        Total: 0
      },
      procedure: {
        Booked: 0,
        Show: 0,
        Noshow: 0,
        Arrived: 0,
        Overdue: 0,
        Total: 0
      }
    };
  
    return data.reduce((acc, curr) => {
      const counts = curr.appointment_counts;
  
      // Update consult totals
      acc.consult.Booked += counts.consult.Booked;
      acc.consult.Show += counts.consult.Show;
      acc.consult.Noshow += counts.consult.Noshow;
      acc.consult.Arrived += counts.consult.Arrived;
      acc.consult.Overdue += counts.consult.Overdue;
      acc.consult.Total += counts.consult.Total;
  
      // Update followup totals
      acc.followup.Booked += counts.followup.Booked;
      acc.followup.Show += counts.followup.Show;
      acc.followup.Noshow += counts.followup.Noshow;
      acc.followup.Arrived += counts.followup.Arrived;
      acc.followup.Overdue += counts.followup.Overdue;
      acc.followup.Total += counts.followup.Total;
  
      // Update procedure totals
      acc.procedure.Booked += counts.procedure.Booked;
      acc.procedure.Show += counts.procedure.Show;
      acc.procedure.Noshow += counts.procedure.Noshow;
      acc.procedure.Arrived += counts.procedure.Arrived;
      acc.procedure.Overdue += counts.procedure.Overdue;
      acc.procedure.Total += counts.procedure.Total;
  
      return acc;
    }, initialTotals);
  };
  
  const getAppointment = () => {
    let paramsApp = `?date=${dateFormatStringSingleDate(activeDate)}`;
    if (selectedClinic?.length) {
      selectedClinic?.forEach((element) => {
        paramsApp = paramsApp + '&location_id=' + element;
      });
      // paramsApp = paramsApp + `&location_id=${selectedClinic?.id}`;
    }
    selectedDoc?.forEach((element) => {
      paramsApp = paramsApp + '&doctor_id=' + element;
    });
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setIsLoading(true);
      ApiClient.getAppointment(paramsApp)
        .then((res) => {
          setIsLoading(false);
          setAppointmentCount(calculateAppointmentTotals(res.data));
          setAppointmentList(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }, 900);
    setDebounceTimeout(timeout);
  };

  const getDoctor = () => {
    setIsLoading(true);
    let params = { role: 'doctor,doctor_pms', remove_inactive_admins: true };
    ApiClient.getSearchTeam(params)
      .then((res) => {
        setIsLoading(false);
        setDoctorList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getClinicAdmin = () => {
    setIsLoading(true);
    let temp = localStorage.getItem('selectedDoc')?.length ? [localStorage.getItem('selectedDoc')][0].split(',').map(Number) : [];
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      ApiClient.getSearchTeam({ role: 'doctor,doctor_pms', clinic: selectedClinic?.toString(), department: selectedDep?.toString() })
        .then((res) => {
          setIsLoading(false);
          setDoctorList(res.data);
          let selectedDocList = [];
          temp?.forEach((item) => {
            if (res.data?.findIndex((item1) => item1.id == item) != -1) {
              selectedDocList.push(item);
            }
          });
          setSelectedDoc(selectedDocList);
          localStorage.setItem('selectedDoc', selectedDocList);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }, 900);
    setDebounceTimeout(timeout);
  };

  const handleClinicSearch = (searchStr) => {
    if (searchStr?.length > 2) {
      setSearchClinicStr(searchStr);
      let params = { search_str: searchStr };
      setIsLoading(true);
      ApiClient.getLocationByAdmin(userData?.id, params)
        .then((res) => {
          setIsLoading(false);
          let filteredClinic = [];
          res.data?.forEach((item5) => {
            if (clinicList?.findIndex((item6) => item6?.id == item5?.id) == -1) {
              filteredClinic.push(item5);
            }
          });
          setSearchClinicList(filteredClinic);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setSearchClinicStr('');
      setSearchClinicList([]);
    }
  };

  const handleAddAppointment = (doctor_id, doctorData, clinicID) => {
    setCreatedAppointment({});
    setOpen(true);
    setCreatedAppointment({ consultant: doctorData });
    let addObj = { ...createField, consultant_id: doctor_id };
    if (clinicID?.id) {
      addObj.location_id = clinicID?.id;
    }
    setCreateField({ ...addObj });
  };

  const handleAppointmentEdit = (appointmentId) => {
    setIsLoading(true);
    ApiClient.getAppointmentDetail(appointmentId)
      .then((res) => {
        setIsLoading(false);
        let appointmentData = res?.data;
        setCreatedAppointment(appointmentData);
        setAppointmentId(appointmentId);
        setCreateField({
          user_id: appointmentData?.user?.id,
          consult_type: appointmentData?.consult_type,
          service_id: appointmentData?.service?.id,
          location_id: appointmentData?.location?.id,
          consultant_id: appointmentData?.consultant?.id,
          consult_datetime: appointmentData?.consult_datetime,
          consult_end_datetime: appointmentData?.consult_end_datetime,
          appointment_fee: appointmentData?.appointment_fee,
          meeting_link: appointmentData?.meeting_link,
          walk_in: appointmentData?.walk_in,
          appointment_recipient: appointmentData?.appointment_recipient,
        });
        setOpen(true);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const renderConsultantDate = () => {
    return (
      <div className='hideCalender'>
        <DatePicker
          onSelect={(date) => {
            setIncreaseNumber(0);
            setActiveDate(new Date(date));
            localStorage.setItem('activeDate', new Date(date));
          }}
          value={activeDate}
          oneTap
          style={{ width: 80 }}
        />
      </div>
    );
  };

  const renderAppointmentView = (appointmentItem, appointmentIndex) => {
    let hours = new Date(appointmentItem?.consult_datetime).getHours() * 60;
    let minutes = new Date(appointmentItem?.consult_datetime).getMinutes();
    let endHours = new Date(appointmentItem?.consult_end_datetime).getHours() * 60;
    let endMinutes = new Date(appointmentItem?.consult_end_datetime).getMinutes();
    let startTime = hours + minutes;
    let endTime = endHours + endMinutes;
    let positionTop = (startTime / 60) * 61 - businessConfig[0] * 61;
    let calculatedHeight = ((endTime - startTime) / 60) * 61;
    // minimum 15min
    let height = Math.max(calculatedHeight, 15.25);
    let addTopPosition = isClinicShow ? 100 : 60;
    return (
      <div
        className={`tileDiv borderLeft${appointmentItem?.consultation_status ? appointmentItem?.consultation_status : 'scheduled'}`}
        style={{
          top: `calc(${positionTop}px + ${addTopPosition}px)`,
          height: `${height}px`,
        }}
      >
        <DoctorSchedulTile
          isBabyMd={isBabyMd}
          setStatusItem={setStatusItem}
          height={height}
          className={
            appointmentIndex > 10
              ? `SchedulBg1${appointmentIndex - 9}`
              : appointmentIndex > 5
              ? `SchedulBg1${appointmentIndex - 4}`
              : `SchedulBg1${appointmentIndex + 1}`
          }
          appointmentDetail={appointmentItem}
          handleAppointmentEdit={handleAppointmentEdit}
          setOpen={setRecordModal}
          setCancelModal={setCancelModal}
          setCreatedAppointment={setCreatedAppointment}
          userData={userData}
          handleRecordPayment = {handleRecordPayment}
        />
      </div>
    );
  };

  const renderAvailableSlotsView = (appointmentItem, appointmentIndex) => {
    let hours = appointmentItem?.start_time.split(':')[0] * 60;
    let minutes = appointmentItem?.start_time.split(':')[1];
    let endHours = appointmentItem?.end_time.split(':')[0] * 60;
    let endMinutes = appointmentItem?.end_time.split(':')[1];
    let startTime = hours + parseInt(minutes);
    let endTime = endHours + parseInt(endMinutes);
    let positionTop = (startTime / 60) * 61 - businessConfig[0] * 61;
    let height = ((endTime - startTime) / 60) * 61;
    let addTopPosition = isClinicShow ? 100 : 60;
    return (
      <div
        className='available-time-slots'
        style={{
          top: `calc(${positionTop}px + ${addTopPosition}px)`,
          height: `${height}px`,
        }}
      ></div>
    );
  };

  const renderCalendarView = (sendWidth = false) => {
    let leftPositionByClinic = 0;
    let allDoctorView = appointmentList?.map((item6, indexMain) => {
      if (indexMain > 0 && appointmentList[indexMain - 1]?.doctors?.length) {
        leftPositionByClinic += appointmentList[indexMain - 1]?.doctors?.length * 190 + 6;
      }
      if (!item6?.doctors?.length) {
        return null;
      }
      return (
        <>
          {item6?.doctors?.length && (
            <div
              className='multiDoctor-head'
              style={{
                left: `${leftPositionByClinic + 130}px`,
                width: `${item6?.doctors?.length * 190}px`,
              }}
            >
              <div className='clinical-name' title={item6?.location?.name?.length > 12 ? item6?.location?.name : undefined}>
                {item6?.location?.name?.length > 12 ? `${item6.location.name.slice(0, 12)}...` : item6?.location?.name}
              </div>
            </div>
          )}

          {item6?.doctors?.map((item, index) => {
            return (
              <div
                className={
                  index > 10 ? `doctorTiles borderColor${index - 9}` : index > 5 ? `doctorTiles borderColor${index - 4}` : `doctorTiles borderColor${index + 1}`
                }
                style={{
                  left: `calc(${130 * (index + 1)}px + ${leftPositionByClinic + 6 + index * 60}px)`,
                }}
              >
                <DoctorAppointment
                  className=''
                  workingSlots={item?.working_slots}
                  activeDate={activeDate}
                  data={item?.doctor}
                  location={item6?.location}
                  handleAddAppointment={handleAddAppointment}
                />
                {item?.appointments?.map((item3, index2) => {
                  return renderAppointmentView(item3, index2);
                })}

                {item?.working_slots?.map((item4, index2) => {
                  return renderAvailableSlotsView(item4, index2);
                })}
              </div>
            );
          })}
        </>
      );
    });
    if (sendWidth) {
      return leftPositionByClinic + 136 + appointmentList[appointmentList.length - 1]?.doctors?.length * 190;
    }
    return allDoctorView;
  };

  const pmsDepartment = departmentList.find(department => department.name === 'PMS');
const defaultDepartmentId = pmsDepartment ? [pmsDepartment.id] : [];

  const AppointmentSchedule = () => (
    <>
      <div className='articleHeader stickyHeader appointmentStickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='calendar_month' title='Appointment' data={appointmentCount} />
          {/* <DashBoardHeader leftData={leftSideData} data={headerData} /> */}
        </div>
        {!recordModal && !cancelModal && (
          <div className={`${isShowAlert ? 'mt-5' : ''}`}>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        <div className='appointmentheader'>
          <div className='headbox'>
            <div className='d-flex gap-10 flex-xl-row flex-column justify-content-between align-items-start'>
              {/* <DatePicker oneTap style={{ width: 80 }} placeholder='Nov' /> */}
              <div className='d-flex renewalDate '>
                <div className='d-flex d-flex justify-content-between w-100 '>
                  <div className='d-block capitalize detailsHeading'>{monthNames[activeDate.getMonth()]}</div>
                  {renderConsultantDate()}
                </div>
              </div>
              <div class='weekCalender d-flex justify-content-between align-items-center w-50'>
                <div
                  className='slider-arrow'
                  onClick={() => {
                    setIncreaseNumber(increaseNumber - 1);
                  }}
                >
                  <span class='material-icons-outlined'>chevron_left</span>
                </div>
                <div className='d-flex justify-content-between align-items-center gap-1'>
                  {Array.from(Array(7).keys()).map((idx) => {
                    const d = new Date(activeDate.getTime() + increaseNumber * 7 * 24 * 60 * 60 * 1000);
                    d.setDate(d.getDate() - d.getDay() + idx);
                    return (
                      <div
                        onClick={() => {
                          setIncreaseNumber(0);
                          setActiveDate(d);
                          localStorage.setItem('activeDate', d);
                        }}
                        class={`weekdate ${dateFormat(d) == dateFormat(activeDate) ? 'active' : null}`}
                      >
                        <span>{weekDays[d.getDay()]}</span>
                        <br />
                        {d.getDate()}
                      </div>
                    );
                  })}
                </div>
                <div className='slider-arrow' onClick={() => setIncreaseNumber(increaseNumber + 1)}>
                  <span class='material-icons-outlined'>chevron_right</span>
                </div>
              </div>
              {isClinicShow ? (
                <div className='w-100 clinicalDropdown'>
                  <CoreInputBox placeholder={`Clinic (${selectedClinic.length} Selected)`} />
                  <TagSelectPicker2
                    isValidation
                    data={searchClinicStr?.length ? [...searchClinicList, ...clinicList] : clinicList}
                    onSelect={(_id, _obj, _objData) => {
                      let notIncludeData = _objData.find((item1) => clinicList?.findIndex((item2) => item2?.id == item1?.id) == -1);
                      if (_id?.length && notIncludeData?.id) {
                        clinicList.push(notIncludeData);
                        setClinicList([...clinicList]);
                      }
                      setSearchClinicStr('');
                      setSelectedClinic(_id);
                      localStorage.setItem('selectedClinic', _id);
                      // setSelectedDoc([]);
                    }}
                    onSearch={(searchStr) => {
                      handleClinicSearch(searchStr);
                    }}
                    onClean={() => {
                      setSearchClinicStr('');
                      setSelectedClinic([]);
                      localStorage.setItem('selectedClinic', []);
                      setSelectedDoc([]);
                      localStorage.setItem('selectedDoc', []);
                      setDoctorList([]);
                    }}
                    value={selectedClinic || []}
                    labelKey={'name'}
                    searchable={true}
                    style={{ width: '100%' }}
                  />
                </div>
              ) : null}
              <div className='w-100 clinicalDropdown'>
                <CoreInputBox placeholder={`Department (${selectedDep.length} Selected)`} />
                {console.log('departmentList: ', departmentList)}
                <TagSelectPicker
                  data={departmentList}
                  value={selectedDep}
                  searchable={true}
                  labelKey={'name'}
                  onSelect={(_id, _obj) => {
                    setSelectedDep(_id);
                    localStorage.setItem('selectedDep', _id);
                    setSelectedDoc([]);
                    localStorage.setItem('selectedDoc', []);
                    // Force a re-render of the clinical team picker by setting its value to null first
                    const clinicalTeamPicker = document.querySelector('.clinical-team-picker');
                    if (clinicalTeamPicker) {
                      clinicalTeamPicker.value = null;
                    }
                  }}
                  onClean={() => {
                    setSelectedDep([]);
                    localStorage.setItem('selectedDep', []);
                    setSelectedDoc([]);
                    localStorage.setItem('selectedDoc', []);
                    setDoctorList([]);
                    // Force a re-render of the clinical team picker by setting its value to null first
                    const clinicalTeamPicker = document.querySelector('.clinical-team-picker');
                    if (clinicalTeamPicker) {
                      clinicalTeamPicker.value = null;
                    }
                  }}
                  style={{ width: '100%' }}
                  placeholder={''}
                  defaultValue={defaultDepartmentId}
                />
              </div>
              <div className='w-100 clinicalDropdown'>
                <CoreInputBox placeholder={`Clinical Team (${selectedDoc.length} Selected)`} />
                <TagSelectPicker2
                  className="clinical-team-picker"
                  key={`clinical-team-${selectedDep.join(',')}`}
                  data={isNivanCare ? doctorList?.filter((item4) => item4?.role == 'doctor' || item4?.role == 'doctor_pms') : doctorList}
                  value={selectedDoc}
                  searchable={true}
                  labelKey={'full_name'}
                  onSelect={(_id, _obj) => {
                    setSelectedDoc(_id);
                    localStorage.setItem('selectedDoc', _id);
                  }}
                  onClean={() => {
                    setSelectedDoc([]);
                    localStorage.setItem('selectedDoc', []);
                  }}
                  style={{ width: '100%' }}
                  placeholder={''}
                />
              </div>

              <div
                className='reusableBtnActive  reusableBtn w-50'
                style={{ height: '38px' }}
                onClick={() => {
                  setCreatedAppointment({});
                  setOpen(true);
                  let addObj = { ...createField };
                  if (selectedClinic?.length) {
                    addObj.location_id = selectedClinic[0];
                  }
                  setCreateField({ ...addObj });
                }}
              >
                + Add New Appointment
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='wrapperContent'>
        <div className='appointmentBody'>
          <div className='table-responsive appointmentTable'>
            <table className='table' style={{ width: renderCalendarView(true), minWidth: '100vw' }}>
              <thead className='stickytop-table'>
                <tr>
                  <th style={{ height: isClinicShow ? '100px' : '60px' }}></th>
                </tr>
              </thead>
              <tbody>
                {businessConfig?.map((idx) => {
                  return (
                    <tr>
                      <td className='text-center'>
                        <div className='schedulTime '>
                          {idx > 12 ? ('0' + (idx - 12)).slice(-2) : ('0' + idx).slice(-2)} {idx > 11 ? 'PM' : 'AM'}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {isClinicShow ? (
              <div className='multiDoctor-slots-part borderColor'>{renderCalendarView()}</div>
            ) : (
              <>
                {appointmentList?.map((item, index) => {
                  return (
                    <div
                      className={
                        index > 10
                          ? `doctorTiles borderColor${index - 9}`
                          : index > 5
                          ? `doctorTiles borderColor${index - 4}`
                          : `doctorTiles borderColor${index + 1}`
                      }
                      style={{ left: `calc(${130 * (index + 1)}px + ${60 * index}px)` }}
                    >
                      <DoctorAppointment
                        className=''
                        workingSlots={item?.working_slots}
                        activeDate={activeDate}
                        data={item?.doctor}
                        handleAddAppointment={handleAddAppointment}
                      />
                      {item?.appointments?.map((item3, index2) => {
                        return renderAppointmentView(item3, index2);
                      })}

                      {item?.working_slots?.map((item4, index2) => {
                        return renderAvailableSlotsView(item4, index2);
                      })}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className='wrapper'>
      {AppointmentSchedule()}
      <Modal backdrop='static' overflow={true} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title className='text-center modalTitle'>{appointmentID ? 'Update' : 'New'} Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors?.detail ? (
            <div className='mt-5'>
              <CommonAlert isShowAlert={errors?.detail} alertType={'alert-danger'} setIsShowAlert={setErrors} />
            </div>
          ) : null}
          <AppointmentForm
            isBabyMd={isBabyMd}
            isNivanCare={isNivanCare}
            isShowAlert={isShowAlert}
            setIsShowAlert={setIsShowAlert}
            alertType={alertType}
            createField={createField}
            setCreateField={setCreateField}
            handleClinicSearch={handleClinicSearch}
            allClinic={clinicList}
            searchClinicList={searchClinicList}
            onClick={saveAppointment}
            errors={errors}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            appointmentID={appointmentID}
            isClinicEnable={isClinicShow}
            appointmentDetail={createdAppointment}
            activeDate={activeDate}
            userData={userData}
          />
        </Modal.Body>
      </Modal>

      <AppointmentCancel
        isShowAlert={isShowAlert}
        setIsShowAlert={setIsShowAlert}
        alertType={alertType}
        appointmentDetail={createdAppointment}
        cancelModal={cancelModal}
        setCancelModal={setCancelModal}
        handleCancelAppointment={handleCancelAppointment}
        errors={errors}
      />
      <RecordPayment
        isBabyMd={isBabyMd}
        statusItem={statusItem}
        isNivanCare={isNivanCare}
        isShowAlert={isShowAlert}
        setIsShowAlert={setIsShowAlert}
        alertType={alertType}
        appointmentDetail={createdAppointment}
        handleRecordPayment={handleRecordPayment}
        open={recordModal}
        setOpen={setRecordModal}
        errors={recordErrors}
        setErrors={setRecordErrors}
        isClinicEnable={isClinicShow}
        userData={userData}
        setIsLoading={setIsLoading}
      />
      <Loader show={isLoading} />
    </div>
  );
}

export default AppointmentVer2;
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setAlerts } from 'redux/Slices';
import { add, arrowDown, arrowUp, editIconY, editTheme, uploaded } from 'res/images';
import { CommonAlert, EmptyView } from 'whealth-core-web/components';
import { dateFormat, profileImage, splitArrData, teamRoles, timeFormat } from 'whealth-core-web/components/Helper';
import CommonToolTip from './components/CommonToolTip';
import StatusTag from './components/StatusTag';
import { STRING } from 'constants/String';
import { user } from './assets/images';
import { use } from 'react';
function RenderService(props) {
  const { newQuestions, userData } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [serviceData, setServiceData] = useState([]);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-danger');
  const [totalPages, setTotalPages] = useState(1);
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    getListData();
  }, []);
  const getListData = () => {
    setIsLoading(true);
    // let params = { page };
    // if (departmentID) {
    //   params.department_id = departmentID;
    // }
    ApiClient.patientServiceList(id)
      .then((res) => {
        setIsLoading(false);
        setServiceData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const renderMultiSelect = (data) => {
    let ansArr = splitArrData(data.answer, '|');
    return (
      <ul>
        {ansArr.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
    );
  };

  const serviceDelete = (questionId) => {
    setIsLoading(true);
    // seterror({});
    ApiClient.deleteService(id, questionId)
      .then((res) => {
        setIsLoading(false);
        getListData();
        dispatch(setAlerts({ patient: { title: 'Patient Service deleted successfully.', type: 'alert-success' } }));
      })
      .catch((err) => {
        // setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.message);
        }
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.message);
        console.log(err);
      });
  };
  const serviceCrpDetailsObj = (data) => {
    return data?.map((item, i) => {
      return (
        <div className='d-flex'>
          <div className='w-100'>
            <span className='semiBold capitalize'>Type Of CRP: {item?.crp_type} · </span>
            <br />
            <span className='semiBold capitalize'> Session Booked · </span>
            <span className='prescriptiontime'>
              <pre className='zeefitness prefont_family firstletter_capitalize'>{item?.session_booked}</pre>
            </span>
            <span className='semiBold capitalize'>Session Delivered · </span>
            <span className='prescriptiontime'>
              <pre className='zeefitness prefont_family firstletter_capitalize'>{item.session_delivered}</pre>
            </span>
            <span className='semiBold capitalize'>Price · </span>
            <span className='prescriptiontime'>
              <pre className='zeefitness prefont_family firstletter_capitalize'>₹{item.price}</pre>
            </span>
            <span className='semiBold capitalize'>Hospital · </span>
            <span className='prescriptiontime'>
              <pre className='zeefitness prefont_family firstletter_capitalize'>{item.location?.name}</pre>
            </span>
            <span className='semiBold capitalize'>Doctor · </span>
            <span className='prescriptiontime'>
              <pre className='zeefitness prefont_family firstletter_capitalize'>{item.doctor?.full_name}</pre>
            </span>
            <span className='semiBold capitalize'>Care Manager · </span>
            <span className='prescriptiontime'>
              <pre className='zeefitness prefont_family firstletter_capitalize'>{item.care_manager?.full_name}</pre>
            </span>
            <hr />
          </div>
        </div>
      );
    });
  };
  const serviceDataObj = (data) => {
    if (data?.length) {
      let sortedData = [...data].sort(function (a, b) {
        return a.order - b.order;
      });
      return sortedData.map((item, i) => {
        return (
          <div className='d-flex'>
            <div className='w-100'>
              <span className='semiBold capitalize'>{item.label} · </span>
              <span className='prescriptiontime'>
                <pre className='zeefitness prefont_family firstletter_capitalize'>
                  {item.type == 'multi_select'
                    ? renderMultiSelect(item)
                    : item.type == 'date' && dateFormat(item.answer) != 'Invalid Date'
                      ? dateFormat(item.answer)
                      : item.type !== 'date_time' && item.answer}
                  {item.type == 'date_time' && dateFormat(item.answer)} {item.type == 'date_time' && timeFormat(item.answer)}
                </pre>
              </span>
            </div>
          </div>
        );
      });
    }
  };

  const tagCont = {
    display: 'flex',
    padding: '4px 6px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '10px',
    border: '0.5px solid #656364',
    background: '#FFF',
  };
  const renderNewServiceList = () => {
    const getStatusColor = (status) => {
      switch (status) {
        case 'scheduled':
          return '#136C0B';
        case 'scheduled-due':
          return '#DD461A';
        case 'dropped':
          return '#FF0000';
        default:
          return '#656364';
      }
    };

    const getStatusColorDiv = (status) => {
      switch (status) {
        case 'scheduled':
          return '#D2F2D8';
        case 'scheduled-due':
          return '#FFF';
        case 'dropped':
          return '#FBE3DC';
        default:
          return '#D9D9D9';
      }
    };

    return serviceData.map((item, index) => {
      const newText = item;
      const buttonsData = () => {
        return (
          <div className='headingMilestone d-flex justify-content-between'>
            <div className='d-flex'>
              <div className='buttonsDataStyle'>
                <div className='tagContainer capitalize'>
                  <p className='tagsText'>
                    {item.service.title} | {item.service_category?.title}
                  </p>
                </div>
              </div>
              <div className='d-flex capitalize'>
                <div
                  style={{
                    backgroundColor: getStatusColorDiv(item.status),
                    border: item?.status === 'scheduled-due' && '1px solid red',
                  }}
                  className='statusTextDiv'
                >
                  <span
                    style={{
                      color: getStatusColor(item.status),
                    }}
                    className='statusText'
                  >
                    {item.status}
                  </span>
                </div>
              </div>
              {/* <div className='d-block  ms-2 '>
                <div className='tagContainer'>
                  <p className='tagsText'>Partially Paid </p>
                </div>
              </div> */}
            </div>

            <div className='memberShipDetails' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {
                userData?.role != 'doctor' && (
                  <>
                    <div
                      onClick={() => {
                        navigate(`/patient/${id}/serviceDetails/${item.id}/edit`, { state: { id: item.id, item: item } });
                      }}
                      className='mb-2 ms-2 pointer action-images d-flex'
                    >
                      <CommonToolTip placement={'top'} item={<img src={editTheme} className='icon serviceEditIcon' />} showValue={'Edit'} />
                    </div>
                  </>
                )
              }
              {
                userData.role == 'doctor' && (
                  <div
                    onClick={() => {
                      navigate(`/patient/${id}/serviceDetails/${item.id}/view`, { state: { id: item.id, item: item } });
                    }}
                    className='ms-2 pointer'
                  >
                    <CommonToolTip
                      placement={'top'}
                      item={<span class='material-icons-outlined'>visibility</span>}
                      showValue={'View'}
                    />
                  </div>
                )
              }
            </div>
          </div>
        );
      };
      if (item?.service) {
        return (
          <div className='cardDataBorder cardDataMargin'>
            <div key={index} className='d-flex'>
              <div className='w-100'>
                <div className='cardMilestone paymentContainer'>
                  {buttonsData()}
                  <div className='d-flex justify-content-between' style={{ alignItems: 'center' }}>
                    <div className='d-block  ms-2 '>
                      {
                        userData?.role != 'doctor' && (
                          <p className='feeText'>
                            Total Fee: <span className='prescriptiontime'>₹{item.final_bill_amount > 0 ? item.final_bill_amount : item.estimated_cost} </span>
                          </p>
                        )
                      }
                    </div>
                    <div className='d-flex'>
                      {
                        userData?.role != 'doctor' && (
                          <button
                            onClick={() => {
                              navigate(`/patient/${id}/serviceDetails/${item.id}/edit`, { state: { id: item.id, item: item, page: 2 } });
                            }}
                            type='button'
                            className='btn btn-outline-primary recordPayment'
                          >
                            Record Payment
                          </button>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-between serviceDetailsHeaderConteiner'>
              <div className='d-block  ms-2 '>
                <p className='createdByText d-flex align-items'>
                  <p className='CreatedByTextHeader'>Created by {item.admin.full_name} </p> on {dateFormat(item.created_at)}, {timeFormat(item.created_at)}
                </p>
              </div>
              <div className='d-flex showDetailsContainer'>
                <p className='createdByText d-flex align-items viewDetailsTxt'>{STRING.SHOW_DETAILS(item?.showDetails)}</p>
                <div onClick={() => toggleDetails(item.id)} className='ms-1 pointer action-images d-flex'>
                  <CommonToolTip
                    placement={'top'}
                    item={<img src={item?.showDetails ? arrowUp : arrowDown} className='icon' />}
                    showValue={STRING.SHOW_DETAILS(item?.showDetails)}
                  />
                </div>
              </div>
            </div>
            {item?.showDetails && renderPaymentDetails(item)}
          </div>
        );
      }
    });
  };
  const renderPaymentDetails = (item) => {
    if (item?.crp_details.length) {
      let data = item.crp_details;

      return (
        <>
          <div className='table-responsive tableContainer'>
            <table className='w-100 table removeBottomMargin'>
              <thead className='tableHeader'>
                <tr>
                  <th>SI. No.</th>
                  <th>Item</th>

                  <th> Doctor</th>
                  <th> Location</th>
                  {userData?.role != 'doctor' && <th> Amount</th>}
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  return (
                    <tr className='capitalize'>
                      <td>{index + 1}</td>
                      <td className={` ${item?.payment_type == 'emi' && 'text-uppercase'}`}>{item?.crp_type}</td>

                      <td>{item?.doctor?.full_name}</td>
                      <td> {item?.location?.name}</td>
                      {userData?.role != 'doctor' && <td>₹ {item?.price}</td>}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className='table-responsive tableContainer'>
            <table className='w-100 table removeBottomMargin'>
              <thead className='tableHeader'>
                <tr>
                  <th> Doctor</th>
                  <th> Location</th>
                </tr>
              </thead>
              <tbody>
                <tr className='capitalize'>
                  <td>{item?.doctor?.full_name}</td>
                  <td> {item?.location?.name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    }
  };

  const toggleDetails = (id) => {
    setServiceData((prevData) => prevData.map((item) => (item.id === id ? { ...item, showDetails: !item.showDetails } : item)));
  };

  const renderServiceList = () => {
    return serviceData.map((item, index) => {
      const newText = item;
      const buttonsData = () => {
        return (
          <div className='headingMilestone d-flex justify-content-between'>
            <div className='d-flex'>
              <div>
                <b>Assigned On &nbsp; &nbsp;</b>
                {dateFormat(item.created_at)} · {timeFormat(item.created_at)}
              </div>
              <div className='d-block  ms-2 '>
                <span className={`badge bg-info  capitalize ${item.status}`}>{item.status}</span>
              </div>
            </div>

            <div className='memberShipDetails' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <>
                {/* <div
                  className='mb-1 ms-2 pointer'
                  onClick={() => {
                    let windoConfirm = window.confirm('Are you sure you want to delete this service?');
                    if (windoConfirm) {
                      serviceDelete(item.id);
                    }
                  }}
                >
                  <div className='pt-1'>
                    <CommonToolTip
                      placement={'top'}
                      item={<span class='material-icons-outlined'>delete_outline</span>}
                      showValue={'Delete'}
                    />
                  </div>
                </div> */}

                <div
                  onClick={() => {
                    navigate(`/patient/${id}/serviceDetails/${item.id}/edit`, { state: { id: item.id, item: item } });
                  }}
                  className='mb-2 ms-2 pointer'
                >
                  <CommonToolTip placement={'top'} item={<img src={editIconY} className='icon' style={{ cursor: 'pointer' }} />} showValue={'Edit'} />
                </div>
              </>
            </div>
          </div>
        );
      };
      if (item?.service) {
        return (
          <div className='cardDataBorder'>
            <div key={index} className='d-flex'>
              <div className='w-100'>
                <div className='cardMilestone'>
                  {buttonsData()}
                  <div className='d-flex mt-1 mb-3 justify-content-between align-items-center'>
                    <div className='d-flex'>
                      <div className='me-2'>
                        <img className='rounded coachImage float-start img-fluid' src={profileImage(item.admin.gender, item.admin.profile_url)} />
                      </div>
                      <div className='w-75 '>
                        <h5 className='coachtext capitalize'>{item.admin.full_name}</h5>
                        <p className='zeefitness capitalize'>{teamRoles(item.admin.role)}</p>
                      </div>
                    </div>
                  </div>
                  {item.service ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Service ·
                        <div>
                          <span className='prescriptiontime'>{item.service.title} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                  {item.doctor ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Doctor ·
                        <div>
                          <span className='prescriptiontime'>{item.doctor.full_name} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                  {item.location ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Hospital ·
                        <div>
                          <span className='prescriptiontime'>{item.location.name} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                  {item.care_manager ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Care Manager ·
                        <div>
                          <span className='prescriptiontime'>{item.care_manager.full_name} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}

                  {item?.service_category ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Service Category ·
                        <div>
                          <span className='prescriptiontime'>{item.service_category?.title} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}

                  {item?.crp_details && serviceCrpDetailsObj(item?.crp_details)}
                  {item?.status ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Status ·
                        <div>
                          <span className='prescriptiontime'>{item?.status} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                  {item?.data && serviceDataObj(item?.data)}
                  {item.estimated_cost || item.final_bill_amount ? (
                    <div className='w-100 mb-2'>
                      <span className='semiBold capitalize'>
                        Service Cost ·
                        <div>
                          <span className='prescriptiontime'>₹{item.final_bill_amount > 0 ? item.final_bill_amount : item.estimated_cost} </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div>
      {/* <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} alertType={alertType} /> */}
      <div className='d-flex  header-right-part'>
        <div className='d-flex'>
          <div className='pt-3'>
            {
              userData?.role != 'doctor' && (
                <button className='reusableBtnActive  reusableBtn' onClick={newQuestions}>
                  + Add New Service
                </button>
              )
            }
          </div>
        </div>
      </div>
      {renderNewServiceList()}
      <div className='d-flex justify-content-end'>
        {/* <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div> */}
      </div>
    </div>
  );
}

export default RenderService;

import React, { useEffect, useRef, useState } from 'react';
import { Popover, Whisper, Modal, Button, Dropdown } from 'rsuite';
import { add, eyeView, dotsthree, addNew, edit, goaldisableIcon, goalenableIcon, search } from 'res/images';
import { Pagination } from 'whealth-core-web/components';
import { useNavigate, useParams } from 'react-router-dom';
import useApiManager from 'networking/ApiManager';
import {
  dateFormat,
  dateFormatString,
  timeFormat,
  showArrayValues,
  getGoalValuePercent,
  showMedicineTimeString,
  sumVal,
} from './Helper';
import Loader from './Loader';
import { setAlerts, setReduxGoalData } from 'redux/Slices';
import { useDispatch } from 'react-redux';
import CoreInputBox from './CoreInputBox';
import { CustomActivityGoalAdd } from 'whealth-core-web/forms';

function RenderGoalTab(props) {
  const ref = useRef();
  const { data, newGoal, getGraphData, setGoal, getPatientData, userData } = props;
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [goalData, setGoalData] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const { id, gid } = useParams();
  const dispatch = useDispatch();

  let timeOut;

  useEffect(() => {
    timeOut = setTimeout(() => {
      if (searchInput?.trim()?.length > 2) {
        searchGoals();
      }
    }, 300);
  }, [searchInput]);

  useEffect(() => {
    if (!searchInput || searchInput?.trim()?.length == 0) {
      getPatientGoals();
    }
  }, [currentPage, searchInput?.trim()?.length == 0]);

  useEffect(() => {
    getPatientGoals();
  }, [id, currentPage]);

  const getPatientGoals = () => {
    setLoading(true);
    let params = { page: currentPage, date_range: dateFormatString(new Date()) };
    ApiClient.getPatientgoals(id, false, params)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setGoal && setGoal(res.data);
        setGoalData(res.data);
        dispatch(setReduxGoalData(res.data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const searchGoals = (search_str = searchInput, date_range = dateFormatString(new Date())) => {
    const params = { search_str, date_range };
    setLoading(true);
    ApiClient.searchPatientGoals(id, params)
      .then((res) => {
        setTotalPages(1);
        setLoading(false);
        setGoalData(res?.data[0]?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const enableItem = (goalid) => {
    const windowConfirm = window.confirm('Are you sure you want to enable this ?');
    if (windowConfirm) {
      updateGoalsData(goalid, 'enable');
    }
  };

  const disableItem = (goalid) => {
    const windowConfirm = window.confirm('Are you sure you want to disable this ?');
    if (windowConfirm) {
      updateGoalsData(goalid, 'disable');
    }
  };

  const updateGoalsData = (goalid, status) => {
    const params = { status: status };
    ApiClient.updatePatientGOAL(id, params, goalid.id)
      .then((res) => {
        getGraphData();
        getPatientGoals();
      })
      .catch((err) => {
        if (err.response.status == '500') {
          dispatch(setAlerts({ patient: { title: 'Internal server error', type: 'alert-danger' } }));
        } else {
          dispatch(setAlerts({ patient: { title: err.response.data.detail, type: 'alert-danger' } }));
        }
      });
  };

  const renderTitleDetails = (item) => {
    let target = item?.activity?.target_value;
    if (item?.goal?.medicinal_goal) {
      target = showMedicineTimeString(target).join(', ');
    }

    return (
      <div className='goalTitalLeft d-flex'>
        {item?.goal?.measuring_event?.image_url && (
          <div className='goalIconImg d-flex align-items-center justify-content-center me-2'>
            <img className='post-icon-images' src={item?.goal?.measuring_event?.image_url} />
          </div>
        )}
        <div>
          <div
            onClick={() => {
              navigate(`/patient/${id}/goals/${item.id}/show`);
            }}
            className='goalTital pointer'
          >
            {item?.goal?.title}

            <dev className=''>
              {/* {item.goal.type == "measuring" ? (
                <div className="progress-value2 goal-value ">
                  {item?.activity && (item?.activity?.target_value ?? "0") + " / "}
                  {item.goal.measuring_event.measuring_unit}
                </div>
              ) : ( */}
              <div className='goal-value d-flex align-items-center'>
                {showArrayValues([target, item?.frequency], '/')}
              </div>
              {/* )} */}
            </dev>
          </div>
        </div>
        <span className={`badge capitalize ms-2 toggleBadge max-content-height ${item.status}`}>
          <label className='form-check-label'>{item.status}</label>
        </span>
      </div>
    );
  };
  const PostFormat = (props) => {
    const { children, item } = props;
    return (
      <div className={`cardDataBorder goalTabPart ${item.status}-border-left`}>
        <div className='d-flex'>
          <div className='w-100'>
            <div className='cardMilestone'>{children}</div>
          </div>
        </div>
      </div>
    );
  };

  const MenuPopover = React.forwardRef(({ onSelect, ...rest }, ref) => {
    return (
      <div className='goalPopover'>
        <Popover ref={ref} {...rest} full>
          <Dropdown.Menu onSelect={onSelect}>
            {rest.item.status == 'enable' && (
              <Dropdown.Item className='dropdown-items-popover' eventKey={1}>
                <span>
                  <img src={addNew} />
                </span>
                Add Activity
              </Dropdown.Item>
            )}
            {(rest.item.status == 'draft' || rest.item.status == 'enable') && (
              <Dropdown.Item className='dropdown-items-popover' eventKey={2}>
                <span>
                  <img src={edit} />
                </span>
                Edit
              </Dropdown.Item>
            )}
            {rest.item.status == 'disable' || rest.item.status == 'draft' ? (
              <Dropdown.Item className='dropdown-items-popover' eventKey={3}>
                <span>
                  <img className='' src={goaldisableIcon} />
                </span>
                Enable
              </Dropdown.Item>
            ) : (
              <Dropdown.Item className='dropdown-items-popover' eventKey={4}>
                <span>
                  <img className='' src={goalenableIcon} />
                </span>
                Disable
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Popover>
      </div>
    );
  });

  const MenuPopoverProp = React.forwardRef(({ onSelect, ...rest }, ref) => {
    let isMedicineGoal = rest.item?.goal?.medicinal_goal;

    return (
      <Popover ref={ref} {...rest} full>
        <Dropdown.Menu onSelect={onSelect}>
          <Dropdown.Item className='dropdown-items-popover' eventKey={1}>
            <div className='w-100 table table-bordered mb-0'>
              {rest?.item?.activity_logs?.length > 0 ? (
                <table className='w-100'>
                  <tr>
                    <th>Value</th>
                    <th>Source</th>
                    <th>Logged At</th>
                  </tr>
                  {[...rest?.item?.activity_logs].map((item, index) => {
                    let target = item?.value;
                    if (isMedicineGoal) {
                      target = showMedicineTimeString(target).join(', ');
                    }

                    return (
                      <tr>
                        <td>{showArrayValues([target, item?.value2], ' - ')}</td>
                        <td className='capitalize'>{item.channel}</td>
                        <td className='capitalize'>
                          {dateFormat(item.updated_at)} · {timeFormat(item.updated_at)}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              ) : (
                <div>No Activity Found for Today</div>
              )}
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  });

  const renderPopOver = (icon, item) => {
    let itemsClickFunctions = {
      1: setOpen,
      2: () =>
        navigate(`/patient/${data.id}/goals/${item.id}/edit`, {
          state: {
            item,
          },
        }),
      3: enableItem,
      4: disableItem,
    };

    function handleSelectMenu(eventKey, event) {
      itemsClickFunctions[eventKey](item);
      ref.current.close();
    }
    return (
      <Whisper
        placement='auto'
        controlId='control-id-with-dropdown'
        trigger='click'
        ref={ref}
        speaker={<MenuPopover onSelect={handleSelectMenu} item={item} />}
      >
        <Button>
          <img src={icon} />
        </Button>
      </Whisper>
    );
  };

  const renderProgressBar = (item, value2) => {
    let barval = 0;
    let value, targetValue;
    if (item?.goal?.medicinal_goal) {
      value = sumVal(item?.activity?.value);
      targetValue = sumVal(item?.activity?.target_value);
      barval = getGoalValuePercent(item?.activity?.value, item?.activity?.target_value);
    } else {
      barval = parseInt((item?.activity?.value / item?.activity?.target_value) * 100);
    }

    return (
      <div className='mt-3 mileStone rounded'>
        <div className='d-flex justify-content-between'>
          <div className='goalDate'>
            {item.activity && item.activity.last_updated ? (
              <>
                Recorded on {dateFormat(item.activity.last_updated) + ' · ' + timeFormat(item.activity.last_updated)}
                <Whisper
                  placement='auto'
                  controlId='control-id-with-dropdown'
                  trigger='click'
                  ref={ref}
                  speaker={<MenuPopoverProp item={item} />}
                >
                  <Button>
                    <img src={eyeView} />
                  </Button>
                </Whisper>
              </>
            ) : (
              'No Activity'
            )}
          </div>
          <div className='progressData'>
            <div className='progress-value d-flex align-items-center'>
              {/* {item.goal.type == 'logging' && item?.activity?.value}&nbsp;{' '} */}
              {/* {item?.activity?.value && getItemVal(item?.activity?.value, item.goal.measuring_event.measuring_unit)} */}

              {item?.goal?.medicinal_goal
                ? showArrayValues(
                    [showArrayValues([value, targetValue], '/'), item?.goal?.measuring_event?.measuring_unit],
                    ' '
                  )
                : showArrayValues(
                    [
                      showArrayValues(
                        [
                          showArrayValues(
                            [
                              item?.activity?.value ? parseFloat(item?.activity?.value).toFixed(2) : '',
                              item.activity?.value2 ? parseFloat(item?.activity?.value2).toFixed(2) : '',
                            ],
                            ' - '
                          ),
                          item?.activity?.target_value,
                        ],
                        '/'
                      ),
                      item?.goal?.measuring_event?.measuring_unit,
                    ],
                    '  '
                  )}

              {/* {value2} {item.goal.measuring_event.measuring_unit} */}
            </div>
          </div>
        </div>
        <div className='progressBar mt-1 d-flex align-items-center'>
          <div className='progress w-100 flex-fill'>
            <div
              className='progress-bar'
              role='progressbar'
              aria-valuenow='0'
              style={{
                width: item?.activity?.value && item.goal.type == 'logging' ? '100%' : barval + '%',
              }}
              aria-valuemin='0'
              aria-valuemax={item.value}
            ></div>
          </div>
        </div>
      </div>
    );
  };

  const renderPatientGoals = (item) => {
    let renderValue2 = item?.activity?.value2;
    let value2 = renderValue2 ? '/' + renderValue2 : '';

    return (
      <PostFormat item={item}>
        <div className='newMilestone1 info1 w-100 d-flex justify-content-between'>
          {renderTitleDetails(item, value2)}
          <div className='goalTitalRight'>
            {
              userData.role != 'doctor' && (
                <div className='threeDot'>
                  <div className=''>{renderPopOver(dotsthree, item)}</div>
                </div>
              )
            }
          </div>
        </div>
        {renderProgressBar(item, value2)}
      </PostFormat>
    );
  };

  const handleModalClose = () => {
    getPatientGoals();
    setOpen(false);
  };

  const customGoalActivityModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={() => handleModalClose()}>
          <Modal.Header />
          <CustomActivityGoalAdd
            getPatientData={getPatientData}
            getGraphData={getGraphData}
            data={open}
            handleSubmit={handleModalClose}
            setLoading={setLoading}
          />
        </Modal>
      </div>
    );
  };

  return (
    <>
      <div className='d-flex addButtonTab justify-content-end'>
        <div className='pt-3 d-flex'>
          <div className='me-2'>
            <CoreInputBox
              placeholder='Search Goal'
              value={searchInput}
              setValue={(text) => {
                clearTimeout(timeOut);
                setSearchInput(text);
              }}
              leftIcon={search}
            />
          </div>
          {/* <button type='button' className='btn  btn-outline-primary' onClick={newGoal}>
            <img src={add} className='icon' /> Add Goal
          </button> */}
        </div>
      </div>

      {customGoalActivityModal()}
      <div className='d-flex justify-content-center'>{goalData?.length ? '' : 'No data found'}</div>
      {goalData &&
        goalData.map((item) => {
          return renderPatientGoals(item);
        })}

      <Loader show={isLoading} />
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </>
  );
}

export default RenderGoalTab;

import React, { useEffect, useState } from 'react';
import { closeIcon } from 'res/images';
import {
  DateRange,
  CategoryFilter,
  TagsFilter,
  AuthorFilter,
  LanguageView,
  GenderFilterView,
  BMIFilter,
  ChipsTagsView,
} from '../whealth-core-web/components/index.js';
import '../whealth-core-web/components/filterModal.scss';
import { clickOnElmOnBodyClick } from '../whealth-core-web/components/Helper';
import labels from '../inputFields.json';
import ClinicsFilterView from './ClinicsFliterView.js';
import RecommendationFilterView from './RecommendationFilterView.js';
import PatientEngagementFilterView from './PatientEngagementFilterView.js';
import CarePathFilter from './CarePathFilter.js';
import LeadSourceFilter from './LeadSourceFilter.js';
import PayerFilterView from './PayerFilterView.js';
import DoctorFilterView from './DoctorFilterView.js';
import StatusFilterView from './StatusFilterView.js';
import SubStatusFilterView from './SubStatusFilterView.js';
import CareManagerLeadFilterView from './CareManagerLeadFilterView.js';
import TimelineEventFilterView from './TimelineEventFilterView.js';
import TimelineModeFilterView from './TimelineModeFilterView.js';
import Select2DropDown from '../whealth-core-web/components/common/Select2DropDown.js';

function FilterModal(props) {
  console.log('FilterModal props: ', props);
  const { getData, data, filter, clearFiltersData, setClearFilterData, showFilter, userData } = props;
  const [filterData, setFilterData] = useState({});
  
  useEffect(() => {
    setFilterData(data);
  }, [data]);

  const getFilterData = (data) => {
    setFilterData({ ...filterData, ...data });
  };

  const handleClearDate = (removeArg) => {
    let obj = { ...filterData };
    if (obj[removeArg]) {
      delete obj[removeArg];
    }

    setFilterData(obj);
  };

  const filterHeader = () => {
    return (
      <div className='filterHeader'>
        <div>Apply Filter</div>
        <div className='' data-bs-dismiss='offcanvas' aria-label='Close'>
          <img src={closeIcon} />
        </div>
      </div>
    );
  };

  const genderFilter = () => {
    return (
      <div className='ml-8'>
        <GenderFilterView filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const carePathFilter = () => {
    return (
      <div className='ml-8'>
        <CarePathFilter filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const pmsConsultDateFilter = () => {
    return (
      <div>
        <DateRange
          pmsConsultDate
          title='1st PMS Consult Date'
          clearFiltersData={clearFiltersData}
          filterData={data}
          setFilterData={getFilterData}
          handleClearDate={handleClearDate}
        />
      </div>
    );
  };

  const renderBMIFilter = () => {
    return (
      <div className='ml-8'>
        <BMIFilter filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const showDateRange = () => {
    return (
      <div>
        <DateRange
          disableDate={(date) => {
            if (date >= new Date()) {
              return true;
            } else {
              return false;
            }
          }}
          clearFiltersData={clearFiltersData}
          filterData={data}
          setFilterData={getFilterData}
          handleClearDate={handleClearDate}
        />
      </div>
    );
  };

  const categoryFilter = () => {
    return (
      <div className='categoryFileter'>
        <CategoryFilter clearData={clearFiltersData} filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const timelineModeFilter = () => {
    return (
      <div className='categoryFileter'>
        <TimelineModeFilterView clearData={clearFiltersData} filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const leadSourceFilter = () => {
    return (
      <div className='ml-8'>
        <LeadSourceFilter filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const showAutherFilter = () => {
    return (
      <div className='authorFilter'>
        <AuthorFilter title='Select Author' placeholder='Search Author' api='article' filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const teamPatientsFilter = () => {
    return (
      <div className='authorFilter'>
        <AuthorFilter title='Assigned Patients' placeholder='Search Assigned Patients' api='team' filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const patientAdminFilter = () => {
    return (
      <div className='authorFilter'>
        <AuthorFilter
          title={labels?.patientFilterSearch?.label || 'Doctor, Health Coach'}
          placeholder={labels?.patientFilterSearch?.placeholder || 'Search Doctor, Health Coach'}
          filterData={data}
          api='patient'
          setFilterData={getFilterData}
        />
      </div>
    );
  };

  // const renderDoctorInfoOrClinicFilter = () => {
  //   if (userData?.role === 'doctor') {
  //     return (
  //       <div className='authorFilter'>
  //         <div className='doctorInfo'>
  //           <label className='my-3'>Doctor:</label>
  //           <Select2DropDown
  //             container={document.getElementById('clinic-dropdown-container')}
  //             className='zindex-popover'
  //             placement='bottomStart'
  //             placeholder='Select Doctor'
  //             data={[{ id: userData?.id, title: userData?.full_name }]}
  //             labelStyle={{ fontWeight: 'var(--lightFont)' }}
  //             value={userData?.id}
  //             disabled={true}
  //             style={{ position: 'relative', width: '100%' }}
  //           />
  //           <div id='clinic-dropdown-container' style={{ position: 'absolute', bottom: '0px' }}></div>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return patientDoctorFilter();
  //   }
  // };

  const patientCareManagerLeadFilter = () => {
    return (
      <div className='authorFilter'>
        <CareManagerLeadFilterView
          title='Care Manager Lead'
          placeholder='Select Care Manager Lead'
          filterData={data}
          api='patient'
          setFilterData={getFilterData}
          clearFiltersData={clearFiltersData}
        />
      </div>
    );
  };

  const timelineEventFilter = () => {
    return (
      <div className='authorFilter'>
        <TimelineEventFilterView
          title='Care Manager Lead'
          placeholder='Select Care Manager Lead'
          filterData={data}
          api='patient'
          setFilterData={getFilterData}
          clearFiltersData={clearFiltersData}
        />
      </div>
    );
  };

  const patientStatusFilter = () => {
    return (
      <div className='authorFilter'>
        <StatusFilterView
          title='Status'
          placeholder='Select Status'
          filterData={data}
          api='patient'
          setFilterData={getFilterData}
          clearFiltersData={clearFiltersData}
        />
      </div>
    );
  };

  const patientSubStatusFilter = () => {
    return (
      <div className='authorFilter'>
        <SubStatusFilterView
          title='Sub Status'
          placeholder='Select Sub Status'
          filterData={filterData}
          api='patient'
          setFilterData={getFilterData}
          clearFiltersData={clearFiltersData}
        />
      </div>
    );
  };

  const patientDoctorFilter = () => {
    return (
      <div className='authorFilter capitalize'>
        <DoctorFilterView
          title='Doctor'
          placeholder='Select Doctor'
          filterData={data}
          api='patient'
          setFilterData={getFilterData}
          clearFiltersData={clearFiltersData}
          userData={userData}
        />
      </div>
    );
  };

  // const patientRecommendationFilter = () => {
  //   return (
  //     <div className='authorFilter position-relative'>
  //       <RecommendationFilterView
  //         title='MDT Recommendation'
  //         placeholder='Select Recommendation Type'
  //         filterData={data}
  //         api='patient'
  //         setFilterData={getFilterData}
  //         clearFiltersData={clearFiltersData}
  //       />
  //     </div>
  //   );
  // };

  const patientEngagementFilter = () => {
    return (
      <div className='authorFilter'>
        <PatientEngagementFilterView
          title='Conversion Probability'
          placeholder='Conversion Probability'
          filterData={data}
          api='patient'
          setFilterData={getFilterData}
          clearFiltersData={clearFiltersData}
        />
      </div>
    );
  };

  const payerFilter = () => {
    return (
      <div className='authorFilter'>
        <div className='mb-4'>
          <PayerFilterView title='Payer' filterData={data} api='patient' setFilterData={getFilterData} clearFiltersData={clearFiltersData} />
        </div>
      </div>
    );
  };

  const patientCheckInDateFilter = () => {
    return (
      <div className='authorFilter px-0'>
        <DateRange
          pmsDate
          title='Next Follow Up date'
          clearFiltersData={clearFiltersData}
          filterData={data}
          setFilterData={getFilterData}
          handleClearDate={handleClearDate}
        />
      </div>
    );
  };

  const patientPrescriptionCreationFilter = () => {
    return (
      <div className='authorFilter px-0'>
        <DateRange
          prescriptionDate
          title='Prescription Creation Date'
          clearFiltersData={clearFiltersData}
          filterData={data}
          setFilterData={getFilterData}
          handleClearDate={handleClearDate}
        />
      </div>
    );
  };

  const languagesFilter = () => {
    return (
      <div className='ml-8'>
        <LanguageView clearFiltersData={clearFiltersData} filterData={data} setFilterData={getFilterData} />
      </div>
    );
  };

  const tagsFilter = () => (
    <div className='categoryFileter'>
      <TagsFilter filterData={data} setFilterData={getFilterData} />
    </div>
  );

  const filterButtons = () => {
    return (
      <div className='footerButtons'>
        <div
          className='button clearBtn'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
          onClick={() => {
            setFilterData('');
            getData({});
            setClearFilterData(!clearFiltersData);
            clickOnElmOnBodyClick('datePickerContainer', 'rs-picker-toggle-clean', 'class');
          }}
        >
          Clear All
        </div>
        <div
          onClick={() => {
            getData(filterData);
          }}
          className='button applyBtn'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        >
          Apply Filter
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='renderFooter'>
        <div className='hrLine' />
        <div>{filterButtons()}</div>
      </div>
    );
  };

  const handleInsuranceCat = (insuranceCat) => {
    let stringActiveData = insuranceCat?.join('|');
    let obj = { ...filterData, insurance_category: stringActiveData };
    setFilterData(obj);
  };

  const patientClinicFilter = () => {
    return (
      <div className='authorFilter'>
        <ClinicsFilterView
          title='Hospitals'
          placeholder='Select Hospital'
          filterData={data}
          api='patient'
          setFilterData={getFilterData}
          clearFiltersData={clearFiltersData}
        />
      </div>
    );
  };

  const renderFilters = () => {
    if (filter == 'team') {
      return (
        <>
          {showDateRange()}
          <div className='my-3' />
          {languagesFilter()}
          {teamPatientsFilter()}
        </>
      );
    } else if (filter == 'patient' && showFilter) {
      return (
        <>
          {patientStatusFilter()}
          {filterData?.status && patientSubStatusFilter()}
          {patientClinicFilter()}
          {/* {renderDoctorInfoOrClinicFilter()} */}
          {patientDoctorFilter()}
          {patientCareManagerLeadFilter()}
          {/* {patientRecommendationFilter()} */}
          {patientEngagementFilter()}
          <div className='filterHeadings mt-3'>Insurance Category</div>
          <div className='mt-3' />
          <ChipsTagsView
            setActiveTags={handleInsuranceCat}
            activeTags={filterData.insurance_category?.length ? filterData.insurance_category?.split('|') : []}
            data={[
              { title: 'Private insurance', id: 'Private insurance' },
              { title: 'Govt insurance', id: 'Govt insurance' },
              { title: 'Cash', id: 'Cash' },
              { title: 'EMI', id: 'EMI' },
            ]}
          />
          {carePathFilter()}
          {leadSourceFilter()}
          {patientPrescriptionCreationFilter()}
          {patientCheckInDateFilter()}
          {pmsConsultDateFilter()}
          {payerFilter()}
        </>
      );
      // } else if (filter == 'timeline') {
      //   return (
      //     <>
      //       {showDateRange()}
      //       {timelineEventFilter()}

      //       {timelineModeFilter()}
      //       <div className='my-3' />
      //     </>
      //   );
    } else {
      return (
        <>
          {showDateRange()}
          {categoryFilter()}
          {showAutherFilter()}
          {tagsFilter()}
        </>
      );
    }
  };

  const renderModal = () => {
    return (
      <div className='filterModal'>
        <div className='offcanvas offcanvas-end' tabIndex='-1' id='offcanvasRight' aria-labelledby='offcanvasRightLabel'>
          <div className='whiteBackground'>
            {filterHeader()}
            <div className='hrLine' />
            <div className='filterHeaderData'>{renderFilters()}</div>
            {renderFooter()}
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
}

export default FilterModal;

import React, { useEffect, useState } from 'react';
import { Select2DropDown } from '../whealth-core-web/components/index';
import { setDoctorsList, setLocations } from 'redux/Slices';
import { useDispatch, useSelector } from 'react-redux';
import useApiManager from 'networking/ApiManager';

function DoctorFilterView(props) {
  const { filterData, setFilterData, clearFiltersData, userData } = props;
  const [selectedClinic, setSelectedClinic] = useState();
  // const locations = useSelector((state) => state.sliceReducer.locations);
  const allDoctorsList = useSelector((state) => state.sliceReducer.allDoctorsList);
  const ApiClient = useApiManager();
  const dispatch = useDispatch();
  console.log('DoctorFilterView userData: ', userData);

  useEffect(() => {
    let obj = { doctor: selectedClinic };
    setFilterData(obj);
  }, [selectedClinic]);

  useEffect(() => {
    setSelectedClinic(filterData?.doctor);
  }, [filterData]);

  useEffect(() => {
    if (!filterData?.doctor) setSelectedClinic();
  }, [clearFiltersData]);

  useEffect(() => {
    if (userData?.role == 'doctor' && !userData?.is_clinic_head) {
      setSelectedClinic(userData?.id);
      setFilterData({ ...filterData, doctor: userData?.id });
    } else {
        const getData = async () => {
          const params = {show_active: false}
          const res = await ApiClient.getServiceDoctor(params);
          if (res.status == 200) {
          let locationsData = [];
          res.data.map((item) => locationsData.push({ 
            id: JSON.stringify(item.id), 
            title: item?.status ? `${item?.full_name} - (${item?.status?.title})` : item?.full_name,
            status: item?.status 
          }));
          dispatch(setDoctorsList(locationsData));
        }
      };
      try {
        getData();
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>Doctor</div>;
  };
  console.log('renderChips selectedClinic: ', selectedClinic);
  const renderChips = () => {
    return (
      <>
        <div className='position-relative'>
          <div className='mt-3' />
          <Select2DropDown
            container={document.getElementById('clinic-dropdown-container')}
            className='zindex-popover'
            placement='bottomStart'
            placeholder='Select Doctor'
            data={(userData?.role == 'doctor' && !userData?.is_clinic_head) ? [{ id: userData?.id, title: userData?.full_name, status: userData?.status }] : allDoctorsList}
            labelStyle={{ fontWeight: 'var(--lightFont)' }}
            onSelect={(data) => {
              setSelectedClinic(data);
            }}
            value={selectedClinic || ''}
            msgStyle={{ color: 'red' }}
            style={{ position: 'relative', width: '100%' }}
            disabled={(userData?.role == 'doctor' && !userData?.is_clinic_head)}
          />
          <div id='clinic-dropdown-container' style={{ position: 'absolute', bottom: '0px' }}></div>
        </div>
      </>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default DoctorFilterView;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { femaleUser, maleUser, warningBadge, whatsapp, eyeView } from 'res/images';
import labels from '../../inputFields.json';
import 'whealth-core-web/components/showTeamMembersList.scss';

import {
  profileImage,
  dateFormat,
  showArrayValues,
  timeAgo,
  titlizeString,
  timeFormat,
  teamRoles1,
} from 'whealth-core-web/components/Helper.js';
import { useDispatch } from 'react-redux';
import { setPatientActiveTab, setFromPatientListPage } from 'redux/Slices';
import StatusCard from 'whealth-core-web/components/StatusCard';
import CommonToolTip from 'whealth-core-web/components/CommonToolTip';
import PatientNotes from './PatientNotes';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { Button, Dropdown, Popover, Whisper } from 'rsuite';

function ShowPatientList(props) {
  const [showAll, setShowAll] = useState(false);
  const ref = useRef();
  const handleShowMore = () => {
    setShowAll((prev) => !prev);
  };
  const {
    data,
    renderStatusChange,
    getAdminProfiles,
    showPatientNotes,
    setShowPatientNotes,
    setPatientId,
    setContent,
    setTitleReal,
    setNoteCategory,
    setConversionKey,
    setShowPatientConversion,
    showPatientConversion,
    setPatientCoversionId,
    setIsClearVal,
  } = props;
  const [editOnboardStatus, setEditOnboardStatus] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { vitalsSection, assignSection, profileSection } = labels?.patientSection;
  let fields = vitalsSection?.data;
  let role = assignSection?.showRole;
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  useEffect(() => {
    dispatch(setPatientActiveTab('timeline'));
  }, []);

  const onBoardingStatus = [
    { title: 'Show', id: 'show' },
    { title: 'No Show', id: 'no_show' },
  ];

  const handleStatusCard = (status) => {
    setEditOnboardStatus(status);
  };

  const userCard = (data) => {
    let userAge = data?.age && data.age + 'yr';
    return (
      <>
        <div className='w-100 d-flex userImageView'>
          {/* <div
            className='userImage pointer'
            onClick={() => {
              !(
                ((userData?.role === 'health_coach' && !data?.admins?.some((obj) => obj?.id === userData?.id)) ||
                  (userData?.role === 'health_coach_lead' &&
                    !data?.admins?.some((obj) => obj?.team_lead_id === userData?.id || obj?.id === userData?.id))) &&
                props?.searchStr
              ) &&
                navigate(`/patient/${data?.id}/dashboard`, {
                  state: {
                    data,
                  },
                });
            }}
          >
            <img className='listUserImages' src={profileImage(data?.gender, data?.profile_url)} />
            <div className={`statusDot borderWhite ${data?.status?.title?.toLowerCase()?.replaceAll(' ', '')}`} />
          </div> */}
          <div className='linesView'>
            {((userData?.role === 'health_coach' && !data?.admins?.some((obj) => obj?.id === userData?.id)) ||
              (userData?.role === 'health_coach_lead' &&
                !data?.admins?.some((obj) => obj?.team_lead_id === userData?.id || obj?.id === userData?.id))) &&
              props?.searchStr ? (
              <strong style={{ fontSize: '9px' }} className='capitalize'>{data?.full_name}</strong>
            ) : (
              <Link className='userName' to={`/patient/${data.id}/dashboard`} state={data}>
                <div style={{ fontSize: '9px' }}>{data?.full_name}</div>
              </Link>
            )}
            <div className='userPhoneNumber d-flex'>
              <img src={whatsapp} />
              <div className='phonenumberText' style={{ fontSize: '9px' }}>{data?.phone}</div>
            </div>
            {data?.patient_id && (
              <div className='bottomTextView' style={{ fontSize: '9px' }}>
                {profileSection?.dateLabel || 'Joined At'} : {data?.patient_id}
              </div>
            )}
            <div className='bottomTextView capitalize' style={{ fontSize: '9px' }}>
              {showArrayValues([showArrayValues([userAge, data?.gender], ', '), data?.city], ' · ')}
            </div>
            {/* <div className='bottomTextView capitalize'>Insurance Category : {data?.insurance_category || 'N/A'}</div> */}
            {data.locations.length ? (
              data.locations?.map((item, index) => {
                return <div key={index} className='capitalize' style={{ fontSize: '9px' }}><b>Clinic:</b> {data.health_matrix.center}</div>;
              })
            ) : (
              <div className='mt-2 capitalize bottomTextView' style={{ fontSize: '9px' }}>
                No Hospital Found
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  function formatDate(dateString) {
    // Parse the input date string as a UTC time
    const utcDate = new Date(dateString);

    // Convert UTC time to IST
    const istOffset = 5 * 60 + 30; // IST is UTC+5:30 in minutes
    const istTime = new Date(utcDate.getTime() + istOffset * 60 * 1000);

    // Extract date components in IST
    const day = String(istTime.getUTCDate()).padStart(2, '0');
    const month = String(istTime.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = istTime.getUTCFullYear();

    // Extract time components in IST
    let hours = istTime.getUTCHours();
    const minutes = String(istTime.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format

    // Format and return the result
    return `${day}-${month}-${year}, ${hours}:${minutes} ${ampm}`;
  }

  const roleView = (data) => {
    return (
      <div className='linesView'>
        <div className='d-flex flex-column'>
          <div
            style={{ fontSize: '9px' }}
            className={` d-flex align-items-center badgeStatus capitalize me-1 onboardinglist ${data?.status?.title?.toLowerCase()}`}
          >
            {data?.status?.title}
          </div>
          {data?.sub_status && (
            <div className='bottomTextView' style={{ fontSize: '9px' }}>
              Sub-status: {data.sub_status}
            </div>
          )}
          {data?.status_updated_at && (
            <div className='mt-2 bottomTextView' style={{ fontSize: '9px' }}>
              Status updated at: {formatDate(data.status_updated_at)}
            </div>
          )}
          {data?.last_status_updated_by?.full_name && (
            <div className='mt-2 bottomTextView' style={{ fontSize: '9px' }}>
              Status updated by: {data.last_status_updated_by.full_name}
            </div>
          )}
          {data?.sub_status_updated_at && (
            <div className='mt-2 bottomTextView' style={{ fontSize: '9px' }}>
              Sub-status updated at: {formatDate(data.sub_status_updated_at)}
            </div>
          )}
          {data?.last_substatus_updated_by?.full_name && (
            <div className='mt-2 bottomTextView' style={{ fontSize: '9px' }}>
              Sub-status updated by: {data.last_substatus_updated_by.full_name}
            </div>
          )}
          {/* {data.sub_status && (
            <>
              <div
                className={` d-flex align-items-center badge capitalize onboardinglist defaultbadge ${data?.sub_status?.toLowerCase()}`}
              >
                {data?.sub_status}
              </div>
            </>
          )} */}

          {/* <div className='ms-2'>
            {renderStatusChange ? (
              <StatusCard
                tagPencil
                item={data}
                setUpdateStatus={handleStatusCard}
                updateStatus={editOnboardStatus == data.id}
                status={onBoardingStatus}
                getPatientData={getAdminProfiles}
                badgeTitle={titlizeString(data.onboarding_status)}
                defaultStatus='Onboarding Status'
                defaultClass='onboarding_status_default'
              />
            ) : null}
          </div> */}
        </div>
        {/* <div className='bottomTextView'>Last Activity : {dateFormat(data?.last_activity)}</div> */}
        {/* <div className='bottomTextView'>
          Last Activity :{' '}
          {data?.last_updated_at ? dateFormat(data?.last_updated_at) + ' ' + timeFormat(data?.last_updated_at) : 'N/A'}
        </div>
        <div className='bottomTextView'>Activity By: {data?.last_updated_by?.full_name || 'N/A'}</div> */}
        {/* <div className="bottomTextView">Renewal on . 13 Dec 2022</div> */}
      </div>
    );
  };

  const renderdata = (item) => {
    return fields.map((field) => {
      if (field?.show && field?.key !== "pms_checkin_date") {
        const dateField =
          (field?.key == 'pms_checkin_date' ||
            field?.key == 'pms_consult_date' ||
            field?.key == 'next_followup_date') &&
          item[field?.key];
        const PipeRemove = field?.key == 'care_pathway' && item[field?.key];
        if (dateField) {
          return (
            <div className='bottomTextView capitalize' style={{ fontSize: '9px' }}>
              {field?.label} : {dateFormat(item[field?.key] || '')}&nbsp;
              {timeFormat(item[field?.key] || '')}
            </div>
          );
        } else if (PipeRemove) {
          return (
            <div className='bottomTextView capitalize' style={{ fontSize: '9px' }}>
              {field?.label} : {item[field?.key].replaceAll('|', ',') || ''}
            </div>
          );
        } else {
          return (
            <div className='bottomTextView capitalize' style={{ fontSize: '9px' }}>
              {field?.label} : {item[field?.key] || 'N/A'}
            </div>
          );
        }
      } else {
        return null;
      }
    });
  };

  const bmiView = (data) => {
    return (
      <div className='d-flex flex-column'>
        {/* <div
          style={{width: '34%', fontSize: '9px'}}
          className={`d-flex align-items-center badge capitalize me-1 onboardinglist defaultbadge ${data?.status?.title?.toLowerCase()}`}
        >
          Follow Ups
        </div> */}
        <div className='linesView mt-1' style={{ fontSize: '12px' }}>
          {/* {data?.health_matrix?.last_activity && (
            <div className='badge capitalize lightGrayBadge'>{timeAgo(data?.health_matrix?.last_activity)}</div>
          )} */}
          {renderdata(data?.health_matrix)}

          {/* <div className='bottomTextView capitalize'>
            BMI : {data?.health_matrix?.current_bmi || 'N/A'}, Weight :{' '}
            {data?.health_matrix?.current_weight
              ? parseFloat(data?.health_matrix?.current_weight).toFixed(2) + ' kg'
              : }
          </div> */}
          <div className='bottomTextView capitalize' style={{ fontSize: '12px' }}>
            {data?.health_matrix?.current_blood_pressure && (
              <span> BP : {data?.health_matrix?.current_blood_pressure},</span>
            )}
            {data?.health_matrix?.current_sugar_level && <span> Sugar : {data?.health_matrix?.current_sugar_level}</span>}
          </div>
        </div>
      </div>
    );
  };

  const goToPatientProfile = (patientId) => {
    dispatch(setFromPatientListPage(true));
    navigate(`/patient/${patientId}/dashboard`, {
      state: {
        data,
      },
    });
  };

  const MenuPopoverProp = React.forwardRef(({ onSelect, patient_id, ...rest }, ref) => {
    return (
      <Popover ref={ref} {...rest} full>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => rest.item.action(patient_id)} className='dropdown-items-popover' eventKey={1}>
            <div
              className=''
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvasRight2'
              aria-controls='offcanvasRight2'
            >
              View notes
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              rest.item.conversionAction(patient_id);
              setIsClearVal(false);
            }}
            className='dropdown-items-popover'
            eventKey={2}
          >
            <div
              className=''
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvasRight3'
              aria-controls='offcanvasRight3'
            >
              Conversion logs
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            // onClick={() => navigate(`/patient/${patient_id}/dashboard`, {
            //   state: {
            //     data,
            //   },
            // })}
            onClick={() => goToPatientProfile(patient_id)}
            className='dropdown-items-popover'
            eventKey={2}
          >
            <div
              className=''
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvasRight3'
              aria-controls='offcanvasRight3'
            >
              Open Patient Profile
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  });

  const actionsSheetItems = [
    {
      msg: 'View Notes & conversion logs',
      icon: 'visibility',
      action: (segmentId) => {
        setPatientId(segmentId);
        setShowPatientNotes(!showPatientNotes);
        setContent('');
        setTitleReal('');
        setNoteCategory('');
      },
      conversionAction: (segmentId, key) => {
        setPatientCoversionId(segmentId);
        setConversionKey(key);
        setShowPatientConversion(!showPatientConversion);
        setContent('');
        setTitleReal('');
        setNoteCategory('');
      },
    },
  ];

  const renderActionSheetItems = (patient_id, ind) => {
    return actionsSheetItems.map((item, index) => {
      return (
        <>
          <div className='goalPopover segmentlist-icon ms-2'>
            <div className='goalfilterBtn ms-2'>
              {((userData?.role === 'health_coach' && !data[ind]?.admins?.some((obj) => obj?.id === userData?.id)) ||
                (userData?.role === 'health_coach_lead' &&
                  !data[ind]?.admins?.some((obj) => obj?.team_lead_id === userData?.id || obj?.id === userData?.id))) &&
                props?.searchStr ? (
                <span className='material-icons ' style={{ color: 'gray', cursor: 'not-allowed' }}>
                  {item?.icon}
                </span>
              ) : (
                <Whisper
                  placement='auto'
                  controlId='control-id-with-dropdown'
                  trigger='hover'
                  ref={ref}
                  enterable
                  speaker={<MenuPopoverProp item={item} patient_id={patient_id} />}
                >
                  <span className='material-icons pointer mt-2'>{item?.icon}</span>
                </Whisper>
              )}
            </div>
          </div>
        </>
      );
    });
  };

  const renderActionForDoctor = (patient_id) => {
    return (
      <div
        onClick={() => goToPatientProfile(patient_id)}
        className='ms-2 pointer mt-2'
      >
        <CommonToolTip
          placement={'top'}
          item={<span class='material-icons-outlined'>visibility</span>}
          showValue={'View'}
        />
      </div>
    );
  }

  const assignBtnView = (data) => {
    return (
      <>
        <div className='linesView'>
          <div className='teams' style={{ padding: '0px 6px' }}>
            <div>
              <img className='assinedTeamImage' src={profileImage(data?.gender, data?.profile_url)} />
            </div>
            <div className='linesView'>
              <div
                className='d-flex bottomTextView capitalize'
                style={{ fontSize: '9px' }}
              // onClick={() => {
              //   navigate(`/team/${data?.id}/edit`);
              // }}
              >
                {data?.full_name}&nbsp;
                <div className='capitalize'>{role ? ' - ' + titlizeString(teamRoles1(data?.role)) + '' : ''}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderData = () => {
    return (
      <div className='table-responsive'>
        <table className='w-100'>
          <tbody>
            {data &&
              data?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      item.status == 'in_active' && navigate(`/team/${item.id}/edit`);
                    }}
                    className='userFullCard border rounded'
                  >
                    <td> {userCard(item)}</td>
                    <td>{roleView(item)}</td>
                    <td>{bmiView(item)}</td>

                    <td>
                      <div key={index} className='badge capitalize lightGrayBadge' style={{ fontSize: '9px' }}>
                        Assigned Teams
                      </div>
                      {item?.admins?.slice(0, showAll ? item.admins.length : 2).map((data, keyText) => (
                        <div key={keyText}>{assignBtnView(data, keyText)}</div>
                      ))}
                      {item?.admins?.length > 3 && (
                        <button
                          onClick={handleShowMore}
                          style={{
                            marginTop: '5px',
                            color: 'blue',
                            fontSize: '9px',
                            backgroundColor: 'white',
                          }}
                        >
                          {showAll ? 'View Less' : 'View More'}
                        </button>
                      )}
                    </td>
                    {item.status == 'in_active' && <td className='usersDivDisable' />}

                    {/* <td>
                      <div key={index} className='badge capitalize lightGrayBadge' style={{ fontSize: '9px'}}>
                        Hospital
                      </div>

                      {item.locations.length ? (
                        item.locations?.map((item) => {
                          return <div className='capitalize mt-2 bottomTextView' style={{ fontSize: '9px' }}>{item.name}</div>;
                        })
                      ) : (
                        <div key={index} className='mt-2 capitalize bottomTextView' style={{ fontSize: '9px' }}>
                          No Hospital Found
                        </div>
                      )}
                    </td> */}

                    <td>
                      <div key={index} className='badge capitalize lightGrayBadge' style={{ fontSize: '9px' }}>
                        Action
                      </div>

                      <div className='d-flex justify-content-start'>{userData?.role == 'doctor' ? renderActionForDoctor(item?.id) : renderActionSheetItems(item?.id, index)}</div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  };

  return <div className='showTeamMembersList'>{renderData()}</div>;
}

export default ShowPatientList;

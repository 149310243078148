import React, { useEffect, useState } from 'react';
import { Select2DropDown, TagSelectPicker } from '../whealth-core-web/components/index';
import { setCareManagerLeads } from 'redux/Slices';
import { useDispatch, useSelector } from 'react-redux';
import useApiManager from 'networking/ApiManager';
function CareManagerLeadFilterView(props) {
  const { filterData, setFilterData, clearFiltersData } = props;
  const [selectedCareManagerLead, setSelectedCareManagerLead] = useState();
  const careManagerLeads = useSelector((state) => state.sliceReducer.careManagerLeads);
  const ApiClient = useApiManager();
  const dispatch = useDispatch();

  useEffect(() => {
    let obj = { care_manager_lead: selectedCareManagerLead };
    setFilterData(obj);
  }, [selectedCareManagerLead]);

  useEffect(() => {
    setSelectedCareManagerLead(filterData?.care_manager_lead);
  }, [filterData]);

  useEffect(() => {
    if (!filterData?.care_manager_lead) setSelectedCareManagerLead();
  }, [clearFiltersData]);

  useEffect(() => {
    const getData = async () => {
      let params = { role: 'health_coach_lead,revenue_manager,health_coach', show_active: false };
      const res = await ApiClient.getSearchTeam(params);
      if (res.status == 200) {
        let locationsData = [];
        res.data.map((item) => {
          const role = item?.role === 'revenue_manager' ? 'Revenue Manager' : item?.role === 'health_coach' ? 'Care Manager' : 'Care Manager Lead';
          const statusText = item?.status ? ` - (${item?.status?.title})` : '';
          locationsData.push({ 
            id: JSON.stringify(item?.id), 
            full_name: `${item?.full_name} (${role})${statusText}`
          });
        });
        dispatch(setCareManagerLeads(locationsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const renderHeading = () => {
    return <div className='filterHeadings mt-3'>Clinic Manager</div>;
  };

  const renderChips = () => {
    return (
      <>
        <div className='position-relative'>
          <div className='mt-3' />

          <div className='w-100'>
            <TagSelectPicker
              isValidation
              data={careManagerLeads}
              onSelect={(_id, _obj, _objData) => {
                setSelectedCareManagerLead(_id);
              }}
              onSearch={(searchStr) => {}}
              onClean={() => {}}
              value={selectedCareManagerLead || []}
              labelKey={'full_name'}
              searchable={true}
              style={{ width: '100%' }}
              placeholder='Select Clinic Manager'
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default CareManagerLeadFilterView;

import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { add, editIconBlack } from 'res/images';
import { Modal } from 'rsuite';
import ModalBody from 'rsuite/esm/Modal/ModalBody';
import { CommonAlert, CoreButton, Loader, Pagination } from 'whealth-core-web/components';
import CommonToolTip from 'whealth-core-web/components/CommonToolTip';
import { dateFormat, timeFormat, titlizeString } from 'whealth-core-web/components/Helper';
import { laptopLink } from 'whealth-core-web/assets/images';
import RecordPayment from './AppointmentVer2/RecordPayment';
import AppointmentCancel from './AppointmentVer2/AppointmentCancel';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

function RenderConsultationTab(props) {
  let environment = process.env.REACT_APP_ENVIRONMENT;
  let serviceID = 3;

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;
  let userData = useGetLoginUserDataQuery()?.data?.data || {};

  const [isLoading, setIsLoading] = useState(false);
  const [consultationData, setConsultationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState('');
  const [alertError, setAlertError] = useState('');
  const [consultationItemId, setConsultationItemId] = useState('');
  const [consultationItem, setConsultationItem] = useState('');
  const [formVal, setFormVal] = useState({});
  const [modal, setModal] = useState(false);
  const [createdAppointment, setCreatedAppointment] = useState({});
  const [cancelModal, setCancelModal] = useState(false);
  const [recordModal, setRecordModal] = useState(false);
  const [recordErrors, setRecordErrors] = useState({});

  const [alertType, setAlertType] = useState('alert-danger');
  const ApiClient = useApiManager();
  const { id, pdf } = useParams();
  const { newConsultation, getPatientData, isNivanCare } = props;
  const navigate = useNavigate();
console.log("___userData: ", userData);
  useEffect(() => {
    if (!recordModal) {
      setRecordErrors({});
      setCreatedAppointment({
        payment_type: null,
        payment_mode: null,
        payment_from: null,
        amount: null,
        patient_attendance_status: null,
        payment_with_id: null,
        payment_with_source: null,
      });
    }
  }, [recordModal]);

  useEffect(() => {
    getConsultationData();
  }, [id, currentPage]);

  const getConsultationData = () => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.consultationList(params, id)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setConsultationData(res.data);
        getPatientData();

        setIsLoading(false);
      })
      .catch((err) => {
        console.warn('err', err.response);
        setIsLoading(false);
      });
  };

  const deleteConsultation = (deleteId) => {
    ApiClient.consultationDelete(deleteId, id)
      .then((res) => {
        getConsultationData();
        setIsShowAlert(res.data.message);
        setAlertType('alert-success');
      })
      .catch((err) => {
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.message);
      });
  };

  const createFollowUp = () => {
    setIsLoading(true);
    let params = { next_consultation_datetime: formVal.next_consultation_datetime };
    ApiClient.createFollowUp(consultationItemId, id, params)
      .then((res) => {
        getConsultationData();
        setIsLoading(false);
        setOpen(false);
        setAlertType('alert-success');
        setIsShowAlert('Patient Status Updated Successfully');
        setIsShowAlert('Follow up consultation created successfully.');
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err?.response.data?.message);
      });
  };
  const closeConsultation = (closeId) => {
    ApiClient.consultationClose(closeId, id)
      .then((res) => {
        setIsShowAlert('Consultation Closed Successfully');
        setAlertType('alert-success');
        getConsultationData();
      })
      .catch((err) => {
        console.warn('err', err.response);
      });
  };

  const changeStatus = (patient_attendance_status, consultData) => {
    const params = {
      patient_attendance_status: patient_attendance_status,
    };
    ApiClient.consultationStatusUpdate(id, params, consultData.id)
      .then((res) => {
        getConsultationData();
        setAlertType('alert-success');
        setIsShowAlert('Patient Status Updated Successfully');
      })
      .catch((err) => {
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.message);
      });
  };

  const handleModalClose = () => {
    // Check if next_consultation_datetime is empty, and set a default value
    setModal(false);
    if (formVal.next_consultation_datetime) {
      formVal.next_consultation_datetime = null;
      setFormVal({ ...formVal });
    }
    setOpen(false);
  };

  const clearErrors = () => {
    setErrors({});
  };

  const handleCancelAppointment = (idAppointment, recordPaymentData, patientID, onSuccess) => {
    setIsLoading(true);
    const requestBody = {
      appointment_cancellation_reason: recordPaymentData.appointment_cancellation_reason,
    };
    ApiClient.cancelAppointment(idAppointment, requestBody)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Appointment Canceled Successfully');
        setCancelModal(false);
        getConsultationData();
        if (recordPaymentData?.note) {
          saveNoteForPatient(recordPaymentData, patientID);
        }
        if (onSuccess) onSuccess();
        clearErrors();
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.appointment_cancellation_reason) {
          setErrors(err.response.data);
        } else {
          setErrors(err?.response?.data?.message || 'An error occurred');
        }
      });
  };

  const saveNoteForPatient = (content, patientID) => {
    ApiClient.notes({ content: content?.note }, patientID)
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleRecordPayment = (idAppointment, recordPaymentData) => {
    setIsLoading(true);
    ApiClient.recordPayment(idAppointment, recordPaymentData)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Payment Recorded Successfully');
        setRecordModal(false);
        getConsultationData();
      })
      .catch((err) => {
        setIsLoading(false);
        setRecordErrors(err?.response?.data?.errors);
      });
  };

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const renderDuration = (item) => {
    let duration =
      new Date(item?.consult_end_datetime).getHours() * 60 +
      new Date(item?.consult_end_datetime).getMinutes() -
      (new Date(item?.consult_datetime).getHours() * 60 + new Date(item?.consult_datetime).getMinutes());
    let durationHours = parseInt(duration / 60);
    let durationMinutes = duration % 60;
    let value = '';
    if (durationHours > 0) {
      value += durationHours + ' Hour ';
    }
    if (durationMinutes > 0) {
      value += durationMinutes + ' Mins';
    }
    return value;
  };

  const canEditOrCancel = (item) => {
    if (item?.service?.title === 'procedure' || item?.service?.title === "followup" || item?.service?.title === "break" || item?.service?.title === "crp") {
      return (
        userData?.role !== 'ee' &&
        userData?.role !== 'ee_manager' &&
        (
          userData?.role === 'admin' ||  
          userData?.role === 'super_admin' || // Admin can always edit/cancel
          item?.admin?.id === userData?.id || // The creator can edit/cancel
          (userData?.role === 'health_coach_lead' && 
            (item?.admin?.role === 'health_coach' || 
             item?.admin?.role === 'revenue_manager' || 
             item?.admin?.role === 'admin' ||
             item?.admin?.role === 'super_admin' ||
             item?.admin?.id === userData?.id)) ||
          ((userData?.role === 'health_coach' || userData?.role === 'revenue_manager') && 
            (item?.admin?.role === 'admin' || item?.admin?.role === 'super_admin'))
        )
      );
    }
  
    switch (userData?.role) {
      case 'health_coach_lead':
        return (
          item?.admin?.role === 'admin' || 
          item?.admin?.role === 'super_admin' ||
          item?.admin?.role === 'health_coach' ||
          item?.admin?.role === 'revenue_manager' ||
          item?.admin?.id === userData?.id
        );
        
      case 'health_coach':
      case 'revenue_manager':
        return (
          item?.admin?.role === 'admin' ||
          item?.admin?.role === 'super_admin' ||
          item?.admin?.id === userData?.id 
        );
        
      case 'ee_manager':
        return (
          item?.admin?.role === 'ee' ||
          item?.admin?.id === userData?.id
        );
  
      case 'ee':
        return (
          item?.admin?.id === userData?.id
        );
  
      case 'admin':
      case 'super_admin':
        return true;
  
      default:
        return false;
    }
  };
  
  const canRecordOrMarkArrived = (item) => {
    if (item?.service?.title === 'procedure' || item?.service?.title === "followup" || item?.service?.title === "break" || item?.service?.title === "crp") {
      return (
        userData?.role !== 'ee' &&
        userData?.role !== 'ee_manager' &&
        (
          userData?.role === 'admin' ||  
          userData?.role === 'super_admin' || // Admin can always edit/cancel
          item?.admin?.id === userData?.id || // The creator can edit/cancel
          (userData?.role === 'health_coach_lead' && 
            (item?.admin?.role === 'health_coach' || 
             item?.admin?.role === 'revenue_manager' || 
             item?.admin?.role === 'admin' ||
             item?.admin?.role === 'super_admin' ||
             item?.admin?.id === userData?.id)) ||
          ((userData?.role === 'health_coach' || userData?.role === 'revenue_manager') && 
            (item?.admin?.role === 'admin' || item?.admin?.role === 'super_admin'))
        )
      );
    }

    if (item?.channel === "Hum Pum") {
      return userData?.role === 'health_coach' || userData?.role === 'health_coach_lead';
    }
  
    switch (userData?.role) {
      case 'admin':
      case 'super_admin':
        return true; // Admin can always record/mark arrived
  
      case 'health_coach':
      case 'revenue_manager':
        return (
          item?.admin?.role === 'admin' ||
          item?.admin?.role === 'super_admin' ||
          item?.admin?.role === 'ee' ||
          item?.admin?.role === 'ee_manager' ||
          item?.admin?.id === userData?.id // Only if they created the appointment
        ); 
  
      case 'health_coach_lead':
        return (
          item?.admin?.role === 'health_coach' ||
          item?.admin?.role === 'revenue_manager' ||
          item?.admin?.role === 'ee' ||
          item?.admin?.role === 'ee_manager' ||
          item?.admin?.role === 'admin' ||
          item?.admin?.role === 'super_admin' ||
          item?.admin?.id === userData?.id // Their own appointments
        );
  
      case 'ee':
      case 'ee_manager':
        return false; // Explicitly restricted
  
      default:
        return false; // Default case restricts other roles
    }
  };

  return (
    <div>
      <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} alertType={alertType} />
      <div className='d-flex'>
        <div className='pt-3'>
          {
            userData.role != 'doctor' && (
              <button type='button' className='btn mt-3 btn-outline-primary' onClick={newConsultation}>
                <img src={add} className='icon' /> Add Appointment
              </button>
            )
          }
        </div>
        <Loader show={isLoading} />
      </div>
      {consultationData.map((item, index) => {
        return (
          <div className='consultationtabPart appointmentTilePart' key={index}>
            <div className='d-flex w-100 justify-content-between align-items-center'>
              <div className='d-flex  align-items-center'>
                <div className='consultationTital'>
                  {/* {item.title} {item?.category == 'follow_up' && titlizeString(item?.category)}{' '} */}
                  {/* {item?.category == 'follow_up' && dateFormat(item.consult_datetime)} */}
                  {dateFormat(item.consult_datetime)} | {timeFormat(item.consult_datetime)}-{timeFormat(item.consult_end_datetime)}{' '}
                  <span>({renderDuration(item)})</span>
                  {/* <div class='consultationDate'></div> */}
                </div>
                <div className='ms-2'>
                  <div className='d-flex consultationbadge align-items-end'>
                    {/* {item.patient_attendance_status && (
                      <span class='badge capitalize lightGrayBadge'>
                        <label class='form-check-label'>{titlizeString(item.patient_attendance_status)}</label>
                      </span>
                    )} */}
                    {item?.service?.title ? (
                      <span class='badge capitalize follow ms-2'>
                        <label class='form-check-label'>{item?.service?.title}</label>
                      </span>
                    ) : null}
                    {item?.consult_type == 'virtual' ? (
                      <span class='badge capitalize follow ms-2'>
                        <label class='form-check-label d-flex align-items-center'>
                          <img src={laptopLink} className='me-1' /> Online
                        </label>
                      </span>
                    ) : null}
                    <span class='badge capitalize enable ms-2'>
                      <label class='form-check-label'>{titlizeString(item.consultation_status) || 'Scheduled'}</label>
                    </span>
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-center'>
                {item?.consultation_status != 'canceled' &&
                  item.patient_attendance_status !== 'show' &&
                  item.patient_attendance_status !== 'no_show' &&
                  item?.service?.id != serviceID &&
                  userData.role != 'doctor' &&
                  (item?.channel == "Hum Pum"
                    ? userData?.role == 'ee_manager'
                    : canEditOrCancel()) ? (
                  <CommonToolTip
                    placement={'top'}
                    item={
                      <div
                        onClick={() => {
                          setCreatedAppointment(item);
                          setCancelModal(true);
                        }}
                        className='d-flex me-2'
                      >
                        <span class='material-icons-outlined'>close</span>
                      </div>
                    }
                    showValue={'Cancel'}
                  />
                ) : null}
                <div>
                  {item?.consultation_status != 'canceled' &&
                    item.patient_attendance_status !== 'show' &&
                    item.patient_attendance_status !== 'no_show' &&
                    item?.service?.id != serviceID &&
                    userData.role != 'doctor' &&
                    (item?.channel == "Hum Pum"
                    ? userData?.role == 'ee_manager'
                    : canEditOrCancel()) ? (
                    <CommonToolTip
                      placement={'top'}
                      item={
                        <img
                          onClick={() =>
                            navigate(`/patient/${id}/appointment/${item.id}/edit`, {
                              state: {
                                edit: 'edit',
                                appointmentDetail: item,
                              },
                            })
                          }
                          src={editIconBlack}
                          className='icon'
                          style={{ cursor: 'pointer', width: '20px' }}
                        />
                      }
                      showValue={'Edit'}
                    />
                  ) : null}
                </div>
                {
                  userData.role == 'doctor' && (
                    <div
                      onClick={() =>
                        navigate(`/patient/${id}/appointment/${item.id}/view`, {
                          state: {
                            view: 'view',
                          },
                        })
                      }
                      className='ms-2 pointer'
                    >
                      <CommonToolTip
                        placement={'top'}
                        item={<span class='material-icons-outlined'>visibility</span>}
                        showValue={'View'}
                      />
                    </div>
                  )
                }
              </div>
              <div className='d-flex align-items-center'>
                <span class='badge capitalize follow ms-2'>
                  <label class='form-check-label d-flex align-items-center'>Channel | {item?.channel}</label>
                </span>
              </div>
            </div>
            <div className='d-flex mt-1 w-100 justify-content-between align-items-end'>
              <div className='d-flex flex-column w-50'>
                {item?.appointment_cancellation_reason && (
                  <p className='consultationDate capitalize'>Cancelled Reason : {item?.appointment_cancellation_reason}</p>
                )}
                <div className='consultationDate'>{item?.consultant?.full_name}</div>
                <div className='consultationDate align-items-center mt-1 d-flex'>
                  <div>{item?.location?.name} </div>
                  {item?.meeting_link && item?.consult_type == 'virtual' ? (
                    <div className='appointmentTilelink text-nowrap ms-2'>{item?.meeting_link}</div>
                  ) : null}
                </div>
                <p className='consultationDate'>Created By : {item?.admin?.full_name}</p>
              </div>
              <div className='d-flex align-items-center'>
                {item?.consultation_status !== 'canceled' &&
                  item?.patient_attendance_status !== 'no_show' &&
                  item?.service?.id !== serviceID &&
                  item?.payment_type !== "free" &&
                  item?.payment_type !== "paid" &&
                  item?.patient_attendance_status != 'show' &&
                  item?.patient_attendance_status != 'arrived' &&
                  !(item?.payment_type === "partially_paid" && item?.amount == item?.appointment_fee) &&
                  userData.role != 'doctor' &&
                  canRecordOrMarkArrived(item) ? (
                  <div className="me-2">
                    <div
                      onClick={() => {
                        setCreatedAppointment(item);
                        setRecordModal(true);
                      }}
                      className="d-flex justify-content-center align-items-center record-btn badge"
                    >
                      {item?.service?.title == 'crp' ? 'Mark Show' : 'Record Payment'}
                    </div>
                  </div>
                ) : null}

                <div>
                  {item?.consultation_status != 'canceled' && item?.consult_type == 'virtual' && item?.meeting_link && item?.service?.id != serviceID ? (
                    <a target='_blank' href={item?.meeting_link} class='badge capitalize lightRedBadge closebtn pointer'>
                      <label class='form-check-label pointer'>Join Link</label>
                    </a>
                  ) : null}

                  {/* {item.consultation_status != 'closed' ? (
                    <button
                      onClick={() =>
                        navigate(`/patient/${id}/appointment/${item.id}/edit`, {
                          state: {
                            check_in: 'check_in',
                          },
                        })
                      }
                      class='badge capitalize lightRedBadge closebtn pointer'
                    >
                      <label class='form-check-label pointer'>Check-in</label>
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        setConsultationItemId(item?.id);
                        setConsultationItem(item);
                        setOpen(true);
                        if (formVal.next_consultation_datetime) {
                          formVal.next_consultation_datetime = null;
                          setFormVal({ ...formVal });
                        }
                      }}
                      class='badge capitalize lightRedBadge closebtn pointer'
                    >
                      <label class='form-check-label pointer'>Create follow-up</label>
                    </button>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <AppointmentCancel
        appointmentDetail={createdAppointment}
        cancelModal={cancelModal}
        setCancelModal={setCancelModal}
        handleCancelAppointment={handleCancelAppointment}
        errors={errors}
      />

      <RecordPayment
        getPatientData={getPatientData}
        isNivanCare={isNivanCare}
        userData={userData}
        setIsLoading={setIsLoading}
        appointmentDetail={createdAppointment}
        handleRecordPayment={handleRecordPayment}
        open={recordModal}
        setOpen={setRecordModal}
        errors={recordErrors}
        setErrors={setRecordErrors}
        isClinicEnable={configData?.findIndex((configItem) => configItem?.key == 'departments_clinics' || configItem?.isActive) != -1}
      />

      <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
        <Modal.Header />
        <ModalBody className='pt-2 '>
          <CommonAlert isShowAlert={alertError} alertType={alertType} setIsShowAlert={setAlertError} />

          <label className='inputlabel'>{<span className='requiredInput'> * </span>}Follow up consultation date</label>
          {/* <DatePicker
            disabledDate={(date) => {
              if (new Date(date) < new Date().setHours(0, 0, 0)) {
                return true;
              } else {
                return false;
              }
            }}
            format='dd-MM-yyyy HH:mm:ss'
            placeholder='Follow up consultation date'
            defaultValue={formVal.next_consultation_datetime}
            onChange={(text) => {
              formVal.next_consultation_datetime = text;
              setFormVal({ ...formVal });
            }}
            style={{ width: '100%' }}
          /> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              disablePast
              // value={consultationItem?.consult_datetime ? dayjs(consultationItem.consult_datetime) : null}
              minDate={consultationItem?.consult_datetime ? dayjs(consultationItem.consult_datetime) : null}
              placeholder={'Create follow-up Consult Date and Time'}
              onChange={(text) => {
                formVal.next_consultation_datetime = text;
                setFormVal({ ...formVal });
              }}
              format='DD/MM/YYYY hh:mm A'
              className='MobileDatePickerinputCommon'
            // label='Consult Date and time'
            // sx={{
            //   width: '100%',
            //   '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
            //     padding: '9.5px 14px',
            //     borderRadius: '10px',
            //   },
            //   '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
            //     padding: '8.5px 14px',
            //   },
            //   '& .MuiInputLabel-root.Mui-focused': { color: '#d9dee2' },
            //   '& .MuiOutlinedInput-root': {
            //     '&:hover > fieldset': { borderColor: '#d9dee2' },
            //     height: '42px',
            //     borderRadius: '10px',
            //   },
            // }}
            />
          </LocalizationProvider>
          {errors && modal && <div className='inputMsg ms-2'>{errors}</div>}
          <CoreButton
            onClick={() => {
              createFollowUp();
              setModal(true);
            }}
            title='Save'
          />
        </ModalBody>
      </Modal>
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </div>
  );
}

export default RenderConsultationTab;
import React, { useEffect, useState } from 'react';
import { TagPicker } from 'rsuite';
import '../inputBox.css';

function TagSelectPicker2(props) {
  const {
    placeholder,
    label,
    selectStyle,
    msgStyle,
    labelStyle,
    showMSG,
    retuired,
    data,
    onSelect,
    valueKey,
    onSearch,
    labelKey,
    defaultValue,
    style,
    defaultData,
    value,
    container,
    onClean,
    disabled,
  } = props;

  const [selectAll, setSelectAll] = useState(false);
  let timeout = '';

  useEffect(() => {
    if (defaultData && typeof defaultData == 'object') {
      let verifyData = data.find((item) => item[valueKey] == defaultData[valueKey]);
      if (!verifyData) {
        data.unshift(defaultData);
      }
    }
  }, [defaultData]);

  const handleSelectValue = (arr) => {
    let selectedData = [];
    let selectedValues = [];
    let selectedTitle = [];
    data?.map((item) => {
      if (arr?.includes(item[valueKey])) {
        selectedData.push(item);
        selectedValues.push(item[valueKey]);
        selectedTitle.push(item[labelKey]);
      }
    });
    onSelect(selectedValues, selectedTitle, selectedData);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      handleSelectValue([]);
    } else {
      handleSelectValue(data.map(item => item[valueKey]));
    }
    setSelectAll(!selectAll);
  };

  return (
    <div>
      {label && (
        <label className='inputlabel' style={labelStyle}>
          {retuired && <span className='requiredInput'> * </span>} {label}
        </label>
      )}

      <div style={{ color: '#7b858f', marginTop: '5px', marginLeft: '5px' }}>
        <input type="checkbox" checked={selectAll} onChange={handleSelectAll} /> Select All
      </div>

      <TagPicker
        disabled={disabled || false}
        container={container}
        data={data}
        onClean={() => {
          onClean ? onClean() : onSelect && handleSelectValue([]);
        }}
        placement='autoVerticalStart'
        onChange={handleSelectValue}
        value={value || []}
        valueKey={valueKey}
        labelKey={labelKey}
        defaultValue={defaultValue}
        onSearch={(str) => {
          if (typeof onSearch === 'function') {
            if (str?.trim()?.length > 2) {
              if (timeout) {
                clearTimeout(timeout);
              }

              timeout = setTimeout(() => {
                onSearch(str);
              }, 300);
            } else {
              onSearch('');
            }
          }
        }}
        style={{ ...style, ...selectStyle }}
        placeholder={placeholder}
      />
      {showMSG && (
        <div className='inputMsg' style={msgStyle}>
          {showMSG}
        </div>
      )}
    </div>
  );
}

TagSelectPicker2.defaultProps = {
  data: [],
  valueKey: 'id',
  labelKey: 'title',
  style: { width: '100%' },
  msgStyle: { color: 'red' },
};

export default TagSelectPicker2; 
import React, { useEffect, useState } from 'react';
import { CoreButton, CoreCheckBox, CoreInputBox, CoreTextArea, Select2DropDown, TagsShow } from 'whealth-core-web/components';
import useApiManager from 'networking/ApiManager';
import TagSelectPicker from '../whealth-core-web/components/common/TagSelectPicker';
import '../whealth-core-web/components/AppointmentVer2/AppointmentVer2.scss';

function ClinicForm(props) {
  const { valueObj, error, isUpdate, setValueObj, onClick, statuses, careManagersList, open } = props;
  const [selectedCareManager, setSelectedCareManager] = useState();
  const ApiClient = useApiManager();
  const [listData, setListData] = useState([]);
  //const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedService, setSelectedService] = useState();
  const [searchServiceStr, setSearchServiceStr] = useState();
  const [selectedClinic, setSelectedClinic] = useState();
  const [serviceList, setServiceList] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    if (isUpdate && valueObj?.service) {
      setSelectedServices(valueObj?.service.map(service => service?.id));
    } else {
      setSelectedServices([])
    }
  }, []);

  const getServiceList = () => {
    //setIsLoading(true);
    const pageQry = { page: currentPage };
    ApiClient.getAppointmentService(currentPage)
      .then((res) => {
        //setIsLoading(false);
        setListData(res?.data);
      })
      .catch((err) => {
        //setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getServiceList();
  }, []);

  useEffect(() => {
    if (!selectedCareManager && valueObj?.admin_profiles && !valueObj.admin_profiles_ids) {
      setSelectedCareManager(valueObj?.admin_profiles[0]?.id);
    }
    setValueObj({ ...valueObj, admin_profiles_ids: selectedCareManager });
  }, [selectedCareManager]);

  const handleServiceSelect = (ids) => {
    const newIds = Array.isArray(ids) ? ids : [ids];

    // Determine which IDs to add or remove
    const idsToAdd = newIds.filter(id => !selectedServices.includes(id));
    const idsToRemove = selectedServices.filter(id => !newIds.includes(id));

    // Update the selected services
    const updatedServices = selectedServices
      .filter(id => !idsToRemove.includes(id))
      .concat(idsToAdd);

    setSelectedServices(updatedServices);
    localStorage.setItem('selectedServices', JSON.stringify(updatedServices));
    setValueObj(prevValueObj => ({ ...prevValueObj, service: updatedServices }));
  };

  const handleCareManagerSelect = (inputVal) => {
    setSelectedCareManager(inputVal);
    setValueObj(prevValueObj => ({
      ...prevValueObj,
      admin_profiles_ids: inputVal,
      service: selectedServices // Ensure service is set to selectedServices
    }));
  };

  const handleShortCodeChange = (inputVal) => {
    setValueObj(prevValueObj => ({
      ...prevValueObj,
      short_code: inputVal,
      service: selectedServices // Ensure service is set to selectedServices
    }));
  };

  return (
    <div className='medicineForm'>
      <div className='formTitle'>{!isUpdate ? 'Add Hospital' : 'Update Hospital'} </div>

      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex w-100 justify-content-between medicineNamerow'>
        <div className='w-100'>
          <CoreInputBox
            showMSG={error?.name}
            placeholder='Hospital Name'
            value={valueObj?.name || ''}
            label='Hospital Name'
            setValue={(inputVal) => {
              setValueObj({ ...valueObj, name: inputVal });
            }}
            maxLength={255}
            msgStyle={{ color: 'red' }}
            retuired
          />
        </div>
      </div>

      <div>
        <span><span className='requiredInput'> * </span>Services</span>
        <div className='w-100 clinicalDropdown'>
          <CoreInputBox placeholder={`Services (${isUpdate ? valueObj?.service?.length : selectedServices?.length} Selected)`} />
          <TagSelectPicker
            data={listData}
            value={selectedServices}
            searchable={true}
            labelKey={'title'}
            onSelect={(ids, _obj) => {
              if (isUpdate) {
                handleServiceSelect(ids);
              } else {
                setSelectedServices(ids);
                localStorage.setItem('selectedServices', JSON.stringify(ids));
                setValueObj({ ...valueObj, service: ids });
              }
            }}
            onClean={() => {
              setSelectedServices([]);
              localStorage.setItem('selectedServices', []);
              setValueObj({ ...valueObj, service: []});
            }}
            style={{ width: '100%' }}
            placeholder={''}
          />
        </div>
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.address1}
          placeholder='Address 1'
          value={valueObj?.address1 || ''}
          label='Address 1'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, address1: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.address2}
          placeholder='Address 2'
          value={valueObj?.address2 || ''}
          label='Address 2'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, address2: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          validateString
          showMSG={error?.city}
          placeholder='City'
          value={valueObj?.city || ''}
          label='City'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, city: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          validateString
          showMSG={error?.state}
          placeholder='State'
          value={valueObj?.state || ''}
          label='State'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, state: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.zip_code}
          placeholder='Zipcode'
          value={valueObj?.zip_code || ''}
          label='Zipcode'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, zip_code: inputVal });
          }}
          validateNumbers
          maxLength={6}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          // validateString
          showMSG={error?.latitude}
          placeholder='Latitude'
          value={valueObj?.latitude || ''}
          label='Latitude'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, latitude: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          // validatedecimal
          showMSG={error?.longitude}
          placeholder='Longitude'
          value={valueObj?.longitude || ''}
          label='Longitude'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, longitude: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          // validatedecimal
          showMSG={error?.google_pin}
          placeholder='Google Pin'
          value={valueObj?.google_pin || ''}
          label='Google Pin'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, google_pin: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.contact_number}
          placeholder='Contact Number'
          value={valueObj?.contact_number || ''}
          label='Contact Number'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, contact_number: inputVal });
          }}
          maxLength={10}
          validateNumbers
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.contact_email}
          placeholder='Contact Email'
          value={valueObj?.contact_email || ''}
          label='Contact Email'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, contact_email: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>

      <div style={{ margin: '20px 0' }}>
        <Select2DropDown
          showMSG={error?.admin_profiles_ids}
          placeholder='Care Manager'
          value={valueObj.admin_profiles_ids || ''}
          label='Care Manager'
          // onSelect={(inputVal) => {
          //   setSelectedCareManager(inputVal);
          // }}
          onSelect={handleCareManagerSelect}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          data={careManagersList}
          onClean={() => {
            setSelectedCareManager(null);
            setValueObj(prevValueObj => ({
              ...prevValueObj,
              care_manager_name: null,
            }));
          }}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.short_code}
          placeholder='Short Code'
          value={valueObj?.short_code || ''}
          label='Short Code'
          // setValue={(inputVal) => {
          //   setValueObj({ ...valueObj, short_code: inputVal });
          // }}
          setValue={handleShortCodeChange}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>

      <CoreButton
        onClick={() => {
          onClick();
        }}
        title={isUpdate ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default ClinicForm;

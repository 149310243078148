import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AddServiceForm from './AddServiceForm';
import { CommonAlert, CoreInputBox, Loader, Select2DropDown, TagSelectPicker } from './components';
import { profileImage, scrollToTop } from './components/Helper';
import CurrencyInput from 'react-currency-input-field';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { setCrpCreated } from 'redux/Slices';


function AddService(props) {
  const {
    serviceDoctorData,
    setServiceDoctorData,
    crpDetails,
    setCrpDetails,
    setheaderServiceDetail,
    setheaderLocationDetail,
    setheaderLocationDetailList,
    setheaderDoctorDetail,
    setheaderDoctorDetailList,
    setheaderEstimateCostDetail,
    setHasSaveButton,
    setCurrentStep,
    steps,
    currentStep,
    dynamicServiceData,
    hasSaveButton,
    setDynamicServiceData,
    setServiceDataForPayment,
    serviceDataForPayment,
    hospitalData,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [patientDetails, setPatientDetails] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const [serviceData, setServiceData] = useState([]);
  const [serviceDropdown, setServiceDropdown] = useState('');
  const [doctorDropdown, setDoctorDropdown] = useState('');
  const [careManagerDropdown, setCareManagerDropdown] = useState('');
  const [clinicDropdown, setClinicDropdown] = useState('');
  const [serviceCategoryValue, setServiceCategoryValue] = useState('');
  const [serviceCategoryData, setServiceCategoryData] = useState([]);
  const [careManagersList, setCareManagersList] = useState([]);
  const [selectedCatObj, setSelectedCatObj] = useState({});
  const [serviceDropdownVal, setServiceDropdownVal] = useState('');
  const [statusValue, setStatusValue] = useState('scheduled');
  const [serviceSchemaData, setServiceSchemaData] = useState('');
  const [estimateCost, setEstimateCost] = useState('');
  const [typeOfCrp, setTypeOfCrp] = useState('');
  const [appointmentVal, setAppointmentVal] = useState('');
  const [appointmentList, setAppointmentList] = useState([]);
  const [ifSingleMultiSpec, setIfSingleMultiSpec] = useState(false);
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [referralSlip, setReferralSlip] = useState(null);
  const [showReferralUpload, setShowReferralUpload] = useState(false);
  const [selectedCrpType, setSelectedCrpType] = useState(null);
  const [selectedCrpPackageName, setSelectedCrpPackageName] = useState(null);
  const [selectedFocusArea, setSelectedFocusArea] = useState(null);
  const [speciality, setSpeciality] = useState(null);
  // const [selectedHospital, setSelectedHospital] = useState(null);
  // const [selectedDoctor, setSelectedDoctor] = useState(null);
  // const [selectedCareManager, setSelectedCareManager] = useState(null);
  const isCrpSelected = serviceData.find(item => item.id === serviceDropdownVal)?.title === 'crp'
  const isCrpEdit = dynamicServiceData?.service?.title == 'crp'
  const isCrp = isCrpSelected || isCrpEdit
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, pid } = useParams();
  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  let userData = useGetLoginUserDataQuery()?.data?.data || {}
  const isDoctorLoggedIn = userData?.role?.toLowerCase() === 'doctor';

  useEffect(() => {
    getServiceList();
    getServiceCategories(serviceDropdown);
    getAppointmentList();
    // getServicesDoctor();
  }, []);

  useEffect(() => {
    if (dynamicServiceData) {
      // Set service-related fields
      if (dynamicServiceData?.service?.id) {
        setServiceDropdown(dynamicServiceData.service.id);
        setServiceDropdownVal(dynamicServiceData.service.id);
      }
      if (dynamicServiceData?.service_category?.id) {
        setServiceCategoryValue(dynamicServiceData.service_category.id);
      }
      if (dynamicServiceData?.location?.id) {
        setClinicDropdown(dynamicServiceData.location.id);
      }
      if (dynamicServiceData?.estimated_cost) {
        setEstimateCost(dynamicServiceData.estimated_cost);
      }
      if (dynamicServiceData?.doctor?.id) {
        setDoctorDropdown(dynamicServiceData.doctor.id);
      }
      if (dynamicServiceData?.care_manager?.id) {
        setCareManagerDropdown(dynamicServiceData.care_manager.id);
      }
      if (dynamicServiceData?.status) {
        setStatusValue(dynamicServiceData.status);
      }
      if (dynamicServiceData?.appointment) {
        setAppointmentVal(dynamicServiceData.appointment);
      }

      // Set CRP-related fields
      if (dynamicServiceData?.crp_details?.length) {
        setTypeOfCrp(dynamicServiceData.crp_details.map((item) => item.crp_type));
        setSpeciality(dynamicServiceData.crp_details[0]?.crp_type); // Set speciality from first CRP type
        
        const updatedCrpDetails = dynamicServiceData.crp_details;
        updatedCrpDetails?.forEach((item, index) => {
          ApiClient.getCareManagersByClinic('&serviceflow=true')
            .then((res) => {
              setIsLoading(false);
              updatedCrpDetails[index]['care_manager_list'] = res.data;
            })
            .catch((err) => {
              setIsLoading(false);
              console.log(err);
            });

          updatedCrpDetails[index]['doctor'] = updatedCrpDetails[index]['doctor']?.id
            ? updatedCrpDetails[index]['doctor']?.id
            : updatedCrpDetails[index]['doctor'];
          updatedCrpDetails[index]['location'] = updatedCrpDetails[index]['location']?.id
            ? updatedCrpDetails[index]['location']?.id
            : updatedCrpDetails[index]['location'];
          updatedCrpDetails[index]['care_manager'] = updatedCrpDetails[index]['care_manager']?.id
            ? updatedCrpDetails[index]['care_manager']?.id
            : updatedCrpDetails[index]['care_manager'];
        });
        setCrpDetails(updatedCrpDetails);
      }

      // Set other CRP-specific fields
      if (dynamicServiceData?.crp_category) {
        setSelectedCrpType(dynamicServiceData.crp_category);
      }
      if (dynamicServiceData?.crp_package_name) {
        setSelectedCrpPackageName(dynamicServiceData.crp_package_name);
      }
      if (dynamicServiceData?.focus_area) {
        setSelectedFocusArea(dynamicServiceData.focus_area);
      }
      if (dynamicServiceData?.patient_type) {
        setSelectedCustomerType(dynamicServiceData.patient_type);
        setShowReferralUpload(dynamicServiceData.patient_type === 'Govt-Insurance');
      }

      // Set service schema data
      if (dynamicServiceData?.data) {
        setServiceSchemaData({ service_schema: dynamicServiceData.data });
      }
    }
  }, [dynamicServiceData]);

  useEffect(() => {
    // setClinicDropdown('');
    // setServiceSchemaData({});
    // setDoctorDropdown('');
    // setCareManagerDropdown('');
    if (dynamicServiceData?.service_schema) {
      setServiceSchemaData({ service_schema: dynamicServiceData?.service_schema });
    }
  }, [serviceDropdownVal]);

  useEffect(() => {
    if (pid) {
      getServiceData();
      getServicesDoctor(dynamicServiceData?.service?.id, dynamicServiceData?.location?.id);
      getServicesClinic(clinicDropdown);
    } else {
      getServicesDoctor(dynamicServiceData?.service?.id, dynamicServiceData?.location?.id);
      getServicesClinic(clinicDropdown);
    }
  }, [pid, dynamicServiceData?.service?.id, dynamicServiceData?.location?.id, serviceDropdown]);

  useEffect(() => {
    let temp = serviceCategoryData.some(
      (item) => (item?.title?.toLowerCase() === 'single spec' || item?.title?.toLowerCase() === 'multi spec') && item.id === serviceCategoryValue
    );
    setIfSingleMultiSpec(temp);
  }, [serviceCategoryValue, serviceCategoryData]);

  useEffect(() => {
    const currentEstimateCost = crpDetails?.reduce((total, item) => {
      const price = item?.price ? parseInt(item.price, 10) : 0;
      return total + price;
    }, 0);
    setheaderEstimateCostDetail(currentEstimateCost?.toString());

    setEstimateCost(currentEstimateCost?.toString());
  }, [crpDetails]);

  useEffect(() => {
    if (dynamicServiceData?.service?.id && !serviceDropdown) {
      setServiceDropdown(dynamicServiceData?.service?.id);
      setServiceDropdownVal(dynamicServiceData?.service?.id);
    }
    if (dynamicServiceData?.service_category?.id) {
      setServiceCategoryValue(dynamicServiceData?.service_category?.id);
    }
    if (dynamicServiceData?.location?.id && !serviceDropdown) {
      setClinicDropdown(dynamicServiceData?.location?.id);
    }
    if (dynamicServiceData?.estimated_cost) {
      setEstimateCost(dynamicServiceData?.estimated_cost);
    }
    if (dynamicServiceData?.doctor?.id && !serviceDropdown) {
      setDoctorDropdown(dynamicServiceData?.doctor?.id);
    }
    if (dynamicServiceData?.care_manager?.id && !serviceDropdown) {
      setCareManagerDropdown(dynamicServiceData?.care_manager?.id);
    }
    if (dynamicServiceData?.status) {
      setStatusValue(dynamicServiceData?.status);
    }
    if (dynamicServiceData?.appointment) setAppointmentVal(dynamicServiceData?.appointment);
    if (dynamicServiceData?.crp_details?.length && !serviceDropdownVal) {
      setTypeOfCrp(dynamicServiceData?.crp_details?.map((item) => item.crp_type));

      const updatedCrpDetails = dynamicServiceData?.crp_details;
      updatedCrpDetails?.map((item, index) => {
        ApiClient.getCareManagersByClinic('&serviceflow=true')
          .then((res) => {
            setIsLoading(false);
            updatedCrpDetails[index]['care_manager_list'] = res.data;
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
        let params = { service_id: serviceDropdown, location_id: item?.location };
        updatedCrpDetails[index]['doctor'] = updatedCrpDetails[index]['doctor']?.id
          ? updatedCrpDetails[index]['doctor']?.id
          : updatedCrpDetails[index]['doctor'];
        updatedCrpDetails[index]['location'] = updatedCrpDetails[index]['location']?.id
          ? updatedCrpDetails[index]['location']?.id
          : updatedCrpDetails[index]['location'];
        updatedCrpDetails[index]['care_manager'] = updatedCrpDetails[index]['care_manager']?.id
          ? updatedCrpDetails[index]['care_manager']?.id
          : updatedCrpDetails[index]['care_manager'];
        // ApiClient.getPatientServiceDoctor(params)
        //   .then((res) => {
        //     setIsLoading(false);
        //     updatedCrpDetails[index]['doctor_list'] = res.data;

        //     setErrors({});
        //   })
        //   .catch((err) => {
        //     setIsLoading(false);
        //     setErrors(err.response.data);
        //     updatedCrpDetails[index]['doctor_list'] = [];

        //     console.log(err);
        //   });
      });
      setCrpDetails(updatedCrpDetails);
    }
    if (dynamicServiceData?.data && !serviceDropdownVal) {
      setServiceSchemaData({ service_schema: dynamicServiceData?.data });
    }
  }, [dynamicServiceData?.data, dynamicServiceData?.service_category?.id, dynamicServiceData?.data]);

  useEffect(() => {
    // This ensures CRP package name and focus area are preserved
    // when switching between CRP types or categories
    if (isCrp || isCrpSelected || isCrpEdit) {
      // If we have values from dynamicServiceData, use those
      if (dynamicServiceData) {
        setSelectedCrpPackageName(dynamicServiceData?.crp_package_name || selectedCrpPackageName);
        setSelectedFocusArea(dynamicServiceData?.focus_area || selectedFocusArea);
      }
    }
  }, [typeOfCrp, serviceCategoryValue, isCrp, isCrpSelected, isCrpEdit]);

  // Add this useEffect to preserve form values when CRP-related states change
  useEffect(() => {
    // Only run this effect if we're dealing with a CRP service
    if (isCrp || isCrpSelected || isCrpEdit) {
      // Store the current values in a ref or variables to prevent them from being lost
      const preserveValues = () => {
        // If editing, prioritize values from dynamicServiceData
        if (dynamicServiceData) {
          if (dynamicServiceData.crp_package_name && !selectedCrpPackageName) {
            setSelectedCrpPackageName(dynamicServiceData.crp_package_name);
          }

          if (dynamicServiceData.focus_area && !selectedFocusArea) {
            setSelectedFocusArea(dynamicServiceData.focus_area);
          }

          if (dynamicServiceData.patient_type && !selectedCustomerType) {
            setSelectedCustomerType(dynamicServiceData.patient_type);
            setShowReferralUpload(dynamicServiceData.patient_type === 'Govt-Insurance');
          }
        }
      };

      preserveValues();
    }
  }, [
    serviceDropdown,
    serviceDropdownVal,
    serviceCategoryValue,
    typeOfCrp,
    isCrp,
    isCrpSelected,
    isCrpEdit
  ]);

  // Add this useEffect to preserve CRP-specific values when service schema changes
  useEffect(() => {
    if (isCrp || isCrpSelected || isCrpEdit) {
      // When service schema changes, ensure we preserve the CRP-specific values
      if (dynamicServiceData) {
        const updatedServiceData = {
          ...dynamicServiceData,
          crp_package_name: selectedCrpPackageName || dynamicServiceData.crp_package_name,
          focus_area: selectedFocusArea || dynamicServiceData.focus_area,
          patient_type: selectedCustomerType || dynamicServiceData.patient_type
        };

        // Only update if there's a difference to avoid infinite loops
        if (JSON.stringify(updatedServiceData) !== JSON.stringify(dynamicServiceData)) {
          setDynamicServiceData(updatedServiceData);
        }
      }
    }
  }, [serviceSchemaData]);

  const handleCrpDetailsChange = (item, key, value) => {
    const updatedCrpDetails = JSON.parse(JSON.stringify(crpDetails));
    const itemIndex = updatedCrpDetails.findIndex((crpItem) => crpItem?.crp_type === item?.crp_type);

    if (itemIndex !== -1) {
      if ((key === 'session_booked' && value === '') || (key === 'session_delivered' && value === '') || (key === 'price' && value === '')) {
        value = '0';
      }
      updatedCrpDetails[itemIndex][key] = value;
      
      // Clear the specific error when a field is filled
      if (value && errors?.specialtyErrors?.[item.crp_type]) {
        const updatedErrors = { ...errors };
        if (key === 'location') {
          delete updatedErrors.specialtyErrors[item.crp_type].hospital;
        } else if (key === 'doctor') {
          delete updatedErrors.specialtyErrors[item.crp_type].doctor;
        } else if (key === 'care_manager') {
          delete updatedErrors.specialtyErrors[item.crp_type].care_manager;
        }
        
        // If no more errors for this specialty, remove the specialty entry
        if (Object.keys(updatedErrors.specialtyErrors[item.crp_type]).length === 0) {
          delete updatedErrors.specialtyErrors[item.crp_type];
        }
        
        // If no more specialty errors, remove the specialtyErrors object
        if (Object.keys(updatedErrors.specialtyErrors).length === 0) {
          delete updatedErrors.specialtyErrors;
        }
        
        setErrors(updatedErrors);
      }

      if (key === 'location') {
        updatedCrpDetails[itemIndex]['care_manager'] = '';
        updatedCrpDetails[itemIndex]['doctor'] = '';
        ApiClient.getCareManagersByClinic('&serviceflow=true')
          .then((res) => {
            setIsLoading(false);
            updatedCrpDetails[itemIndex]['care_manager_list'] = res.data;
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
        let params = { service_id: serviceDropdown, location_id: value };
      }
      // Set the state with the updated array
      setCrpDetails(updatedCrpDetails);
    }
  };
  const renderCrpDetails = () => {
    return crpDetails?.map((item, index) => {
      const specialtyErrors = errors?.specialtyErrors?.[item.crp_type] || {};
      
      return (
        <>
          <h4>{item?.crp_type}</h4>

          <div className='mt-3'>
            <CoreInputBox
              validateNumbers
              validateZero
              validatedecimal
              label={'Session Booked'}
              placeholder={'Session Booked'}
              value={item?.session_booked || '0'}
              setValue={(data, dataObj) => handleCrpDetailsChange(item, 'session_booked', data)}
              disabled={isDoctorLoggedIn}
            />
          </div>
          <div className='mt-3'>
            <CoreInputBox
              disabled
              validateNumbers
              validateZero
              validatedecimal
              label={'Session Delivered'}
              placeholder={'Session Delivered'}
              value={item?.session_delivered || 0}
              setValue={(data, dataObj) => handleCrpDetailsChange(item, 'session_delivered', data)}
            />
          </div>
          <div className='mt-3'>
            {/* <CoreInputBox
              // disabled={data?.payment_type == 'paid' || appointmentDetail?.payment_type == 'paid' || appointmentDetail?.payment_type == 'free'}
              // retuired
              validateNumbers
              validateZero
              validatedecimal
              label={'Price'}
              placeholder={'Price'}
              // showMSG={errors?.amount}
              value={item?.price || 0}
              setValue={(data, dataObj) => handleCrpDetailsChange(item, 'price', data)}
            /> */}
            {
              !isDoctorLoggedIn && (
                <>
                  <div className='inputFieldHeader'>
                    <label className='inputlabel' htmlFor='validationCustom01 mt-3 slot-name'>
                      {/* <span style={{ color: 'red' }}>*&nbsp;</span> */}
                      {'Price'}
                    </label>
                  </div>
                  <CurrencyInput
                    // disabled={editIndex > 0 || (!editData?.id && dynamicServiceData?.final_bill_amount > 0)}
                    id='validationCustom01'
                    name='input-1'
                    className={`form-control coreInput`}
                    label='Price'
                    value={item?.price || 0}
                    // showMSG={errors?.crp_final_bill_amounts?.[crpTypeFinalAmount?.title?.toLowerCase()]}
                    msgStyle={{ color: 'red' }}
                    onValueChange={(data) => {
                      handleCrpDetailsChange(item, 'price', data);
                    }}
                    placeholder={'Price'}
                    prefix={'₹'}
                    step={1}
                  />
                </>
              )
            }
            {/* <p className='capitalize' style={{ color: 'red' }}>
              {errors?.crp_final_bill_amounts?.[crpTypeFinalAmount?.title?.toLowerCase()]}
            </p> */}
          </div>
          <div className='mt-3'>
            <div className='daypart-input behaviourpart_box w-100'>
              <Select2DropDown
                showMSG={specialtyErrors.hospital}
                value={item?.location}
                data={hospitalData}
                label={'Select Hospital'}
                placeholder={'Select Hospital'}
                valueKey='id'
                labelKey='title'
                getObjData
                retuired
                onSelect={(locationId, obj) => {
                  handleCrpDetailsChange(item, 'location', locationId);
                }}
                disabled={isDoctorLoggedIn}
              />
            </div>
          </div>
          <div className='mt-3'>
            <div className='daypart-input behaviourpart_box w-100'>
              <Select2DropDown
                showMSG={specialtyErrors.doctor}
                value={item?.doctor}
                data={serviceDoctorData}
                label={'Select Doctor'}
                placeholder={'Select Doctor'}
                valueKey='id'
                labelKey='full_name'
                getObjData
                retuired
                onSelect={(locationId, obj) => {
                  handleCrpDetailsChange(item, 'doctor', locationId);
                }}
                disabled={isDoctorLoggedIn}
              />
            </div>
          </div>
          <div className='mt-3'>
            <div className='daypart-input behaviourpart_box w-100'>
              <Select2DropDown
                showMSG={specialtyErrors.care_manager}
                value={item?.care_manager}
                data={careManagersList}
                label={'Select Care Manager'}
                placeholder={'Select Care Manager'}
                valueKey='id'
                labelKey='full_name'
                getObjData
                retuired
                onSelect={(locationId, obj) => {
                  handleCrpDetailsChange(item, 'care_manager', locationId);
                }}
                disabled={isDoctorLoggedIn}
              />
            </div>
          </div>
          <br />
        </>
      );
    });
  };

  const getServicesDoctor = (serviceId, locationId) => {
    if (serviceDropdown || serviceId) {
      setIsLoading(true);
      let params = { service_id: serviceDropdown || serviceId, location_id: locationId || 1 };
      ApiClient.getPatientServiceDoctor(params)
        .then((res) => {
          setIsLoading(false);
          setServiceDoctorData(res.data);
          setErrors({});
        })
        .catch((err) => {
          setDoctorDropdown('');
          setIsLoading(false);
          setErrors(err.response.data);
          console.log(err);
        });
    }
  };

  const getServicesDropdownSearch = (text) => {
    setIsLoading(true);
    ApiClient.getServiceDropdownSearch(text)
      .then((res) => {
        setIsLoading(false);
        setServiceData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPatientData();
  }, []);

  const getServicesClinic = (HospitalId) => {
    setIsLoading(true);
    ApiClient.getCareManagersByClinic('&serviceflow=true')
      .then((res) => {
        setIsLoading(false);
        setCareManagersList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getServiceList = () => {
    setIsLoading(true);
    ApiClient.getAppointmentService(1, true)
      .then((res) => {
        setServiceData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  function timeStringChange(utcDateString) {
    const istTimeString = new Date(utcDateString)?.toLocaleTimeString('en-IN', {
      timeZone: 'Asia/Kolkata',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    const formattedTimeString = istTimeString?.replace(' am', ' AM')?.replace(' pm', ' PM');
    return formattedTimeString;
  }

  const getAppointmentList = () => {
    setIsLoading(true);
    let params = { active: true };
    ApiClient.consultationList(params, id)
      .then((res) => {
        const outputArray = res?.data.map((item) => ({
          ...item,
          display_key: `${item.consult_datetime?.substring(0, 10)} | ${timeStringChange(item.consult_datetime)} - ${timeStringChange(
            item.consult_end_datetime
          )}   | ${item.consultant?.full_name} | ${item.location?.name}`,
        }));
        setAppointmentList(outputArray);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getServiceCategories = (serviceId) => {
    setIsLoading(true);
    let params = { service_id: serviceId, enable: true };
    ApiClient.getServiceCategories(params)
      .then((res) => {
        setIsLoading(false);
        setServiceCategoryData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getServiceData = (prescription_id = pid) => {
    setIsLoading(true);
    ApiClient.singleDataService(id, prescription_id || serviceDataForPayment?.id)
      .then((res) => {
        setIsLoading(false);
        if (!!Object.entries(res.data?.data).length) {
          let tmpData = res.data;
          // tmpData.service.service_schema = res.data.data;
          tmpData.isUpdate = true;
          tmpData.emptySchema = res.data.service;
          setDynamicServiceData(tmpData);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const serviceDataCreate = (data) => {
    setIsLoading(true);
    ApiClient.patientServiceCreate(data, id)
      .then((res) => {
        setIsLoading(false);
        if(isCrp) {
          dispatch(setCrpCreated(true));
        }
        setAlertType('alert-success');
        setIsShowAlert('Service created successfully ');
        setHasSaveButton(true);
        setCurrentStep(1);
        scrollToTop();
        setServiceDataForPayment(res.data);
        setCrpDetails(res?.data?.crp_details);
        navigate(`/patient/${id}/dashboard`);
        // setTimeout(() => {
        //   navigation(`/patient/${id}/dashboard`);
        // }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
      });
  };

  const updateServiceData = (data) => {
    setIsLoading(true);
    ApiClient.updateServiceData(id, pid || serviceDataForPayment?.id, data)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Prescription updated successfully ');
        setHasSaveButton(true);
        setCurrentStep(1);
        scrollToTop();
        getServiceData(serviceDataForPayment?.id);
        setCrpDetails(res?.data?.crp_details);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        if (err.response.data?.errors?.non_field_errors || err.response.data?.errors?.details) {
          setAlertType('alert-danger');
          setIsShowAlert(Object.values(err.response.data?.errors?.non_field_errors || err.response.data.errors)[0]);
        }
      });
  };

  const getPatientData = () => {
    setIsLoading(true);
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setIsLoading(false);
        setPatientDetails(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors = {};

    // Basic service validation - check either serviceDropdownVal or dynamicServiceData.service.id
    if (!serviceDropdownVal && !dynamicServiceData?.service?.id) {
      newErrors.service = 'Service is required';
      isValid = false;
    }
    if (!serviceCategoryValue) {
      newErrors.serviceCategory = 'Service Category is required';
      isValid = false;
    }

    // Only validate CRP-specific fields if this is a CRP service
    if (isCrp || isCrpSelected || isCrpEdit) {
      // Check if we have crpDetails with location, doctor, and care_manager
      if (crpDetails && crpDetails.length > 0) {
        // Initialize specialty-specific error objects
        newErrors.specialtyErrors = {};
        
        crpDetails.forEach((detail, index) => {
          const specialtyErrors = {};
          let hasError = false;
          
          if (!detail.location) {
            specialtyErrors.hospital = 'Hospital is required';
            hasError = true;
          }
          if (!detail.doctor) {
            specialtyErrors.doctor = 'Doctor is required';
            hasError = true;
          }
          if (!detail.care_manager) {
            specialtyErrors.care_manager = 'Care Manager is required';
            hasError = true;
          }
          
          if (hasError) {
            newErrors.specialtyErrors[detail.crp_type] = specialtyErrors;
            isValid = false;
          }
        });
      }

      if (!selectedCrpType) {
        newErrors.crpType = 'CRP Type is required';
        isValid = false;
      }
      if (!speciality) {
        newErrors.speciality = 'Speciality is required';
        isValid = false;
      }
      if (!selectedCustomerType) {
        newErrors.customerType = 'Patient Type is required';
        isValid = false;
      }
      if (!selectedCrpPackageName) {
        newErrors.crpPackageName = 'CRP Package Name is required';
        isValid = false;
      }
      if (!selectedFocusArea) {
        newErrors.focusArea = 'Focus Area is required';
        isValid = false;
      }
    }

    console.log("Validation result:", { isValid, newErrors });
    setErrors(newErrors);
    return isValid;
  };

  const statusValueData = [
    {
      title: 'Scheduled',
      id: 'scheduled',
    },
    {
      title: 'Scheduled Due',
      id: 'scheduled-due',
    },
    {
      title: 'Done',
      id: 'done',
    },
    {
      title: 'Dropped',
      id: 'dropped',
    },
    {
      title: 'Delivered',
      id: 'delivered',
    },
    {
      title: 'In-Progress',
      id: 'in-progress',
    },
    {
      title: 'Incomplete',
      id: 'incomplete',
    },
  ];
  const crpStatusValueData = [
    {
      title: 'Scheduled',
      id: 'scheduled',
    },
    {
      title: 'Dropped',
      id: 'dropped',
    },
    {
      title: 'Done',
      id: 'done',
    },
    {
      title: 'In-Progress',
      id: 'in-progress',
    },
    {
      title: 'Incomplete',
      id: 'incomplete',
    },
  ];
  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails.gender, userDetails.image),
      title: userDetails.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + ' ' + 'Service', link: '' },
  ];
  const showService = (adminData) => (
    <>
      {/* <div className='articleHeader stickyHeader '>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>
        <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Service</div>
        <div class='hrLine'></div>
        <>
          <br />
          <div className='d-flex justify-content-around'>
            <div className='capitalize'>Gender : {patientDetails?.gender}</div>
            <div>Age : {patientDetails?.age}</div>
            <div className='capitalize'>
              Insurance Category :{' '}
              {patientDetails?.profile_info?.find((item3) => item3?.key == 'insurance_category')?.answer || 'N/A'}
            </div>
          </div>
      </>
      </div> */}
      <div className=''>
        {isShowAlert && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        <div className=' mt-3'>
          <div className='mt-3'>
            {/* {renderServiceCategoryDropdown()} */}
            {renderServiceDropdown()}
            {errors?.service && <div className='inputMsg' style={{ color: 'red' }}>{errors?.service}</div>}
          </div>
          {isCrp && (
            <div className='mt-3'>
              {renderCrpType()}
              {errors?.crpType && <div className='inputMsg' style={{ color: 'red' }}>{errors?.crpType}</div>}
            </div>
          )}
          {serviceDropdown && serviceData?.some((obj) => obj?.title?.toLowerCase() === 'procedure' && obj?.id === serviceDropdown) && (
            <div className='mt-3'>{renderAppointmentDropdown()}</div>
          )}
          {serviceDropdown && (
            <div className='mt-3'>
              {renderServiceCategoryDropdown()}
              {errors?.serviceCategory && <div className='inputMsg' style={{ color: 'red' }}>{errors?.serviceCategory}</div>}
            </div>
          )}
          {ifSingleMultiSpec ? (
            <>
              <div className='mt-3'>{renderTypeCrpDropdown()}</div>
              {errors?.speciality && <div className='inputMsg' style={{ color: 'red' }}>{errors?.speciality}</div>}
            </>
          ) : (
            serviceCategoryValue && (
              <>
                <div className='mt-3'>{renderClinicDropdown()}</div>
                <div className='mt-3'>{renderDoctorDropdown()}</div>
                <div className='mt-3'>{renderCareManagerDropdown()}</div>
              </>
            )
          )}
          {/* {crpDetails?.length ? <div className='mt-3'>{renderClinicDropdown()}</div> : null}
          {crpDetails?.length ? <div className='mt-3'>{renderDoctorDropdown()}</div> : null}
          {crpDetails?.length ? <div className='mt-3'>{renderCareManagerDropdown()}</div> : null} */}
          {isCrp && (
            <div className='mt-3'>
              {renderCustomerType()}
              {errors?.customerType && <div className='inputMsg' style={{ color: 'red' }}>{errors?.customerType}</div>}
            </div>
          )}
          {showReferralUpload && (
            <div className='mt-3'>
              <label className='inputlabel'>
                Upload Referral Slip <span className='requiredInput'>*</span>
              </label>

              <input disabled={isDoctorLoggedIn} type="file" className='rounded-1 mt-2' onChange={(e) => handleFileChange(e)} />


              {/* Display existing referral slip if available */}
              {dynamicServiceData?.referral_slip && (
                <div style={{ marginTop: '10px' }}>
                  <a href={dynamicServiceData.referral_slip} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
                    View Current Referral Slip
                  </a>
                  {/* Optionally, display the image directly if it's an image file */}
                  {/* <img src={dynamicServiceData.referral_slip} alt="Referral Slip" style={{ maxWidth: '100px', height: 'auto', marginTop: '10px', marginLeft: '20px' }} /> */}
                </div>
              )}

              {errors?.referralSlip && <div className='inputMsg' style={{ color: 'red' }}>{errors?.referralSlip}</div>}
              {/* {!referralSlip && <div className='inputMsg' style={{ color: 'red' }}>Referral slip is required for Government insurance.</div>} */}
            </div>
          )}

          {ifSingleMultiSpec && <div className='mt-3'>{renderCrpDetails()}</div>}

          {(pid || serviceDropdown) && (
            <div className='mt-3'>
              <Select2DropDown
                retuired
                label='status'
                data={isCrp ? crpStatusValueData : statusValueData}
                value={statusValue}
                onClean={() => setStatusValue(null)}
                showMSG={errors?.status}
                onSelect={(id) => setStatusValue(id)}
                disabled={isDoctorLoggedIn}
              />
            </div>
          )}

          {isCrp && (
            <div className='mt-3'>
              {renderCrpPackageName()}
              {errors?.crpPackageName && <div className='inputMsg' style={{ color: 'red' }}>{errors?.crpPackageName}</div>}
            </div>
          )}
          {isCrp && (
            <div className='mt-3'>
              {renderFocusArea()}
              {errors?.focusArea && <div className='inputMsg' style={{ color: 'red' }}>{errors?.focusArea}</div>}
            </div>
          )}
          {serviceDropdown ? (
            serviceCategoryData?.length && serviceCategoryData[0]?.service?.service_schema?.length ? (
              <div className='mt-3'>
                <AddServiceForm
                  estimateCost={estimateCost}
                  setEstimateCost={setEstimateCost}
                  setheaderEstimateCostDetail={setheaderEstimateCostDetail}
                  selectedCatObj={selectedCatObj}
                  setSelectedCatObj={setSelectedCatObj}
                  careManagerDropdown={careManagerDropdown}
                  doctorDropdown={doctorDropdown}
                  clinicDropdown={clinicDropdown}
                  patientId={id}
                  errors={errors}
                  setErrors={setErrors}
                  serviceSchemaData={serviceSchemaData}
                  statusValue={statusValue}
                  onClick={pid || serviceDataForPayment?.id ? updateServiceData : serviceDataCreate}
                  dynamicServiceData={dynamicServiceData}
                  setDynamicServiceData={setDynamicServiceData}
                  serviceDropdown={serviceDropdown}
                  serviceCategoryValue={serviceCategoryValue}
                  pid={pid}
                  crpDetails={crpDetails}
                  ifSingleMultiSpec={ifSingleMultiSpec}
                  appointmentVal={appointmentVal}
                  adminData={adminData}
                  disabled={isDoctorLoggedIn}
                  selectedCrpType={selectedCrpType}
                  selectedCrpPackageName={selectedCrpPackageName}
                  selectedFocusArea={selectedFocusArea}
                  selectedCustomerType={selectedCustomerType}
                  referralSlip={referralSlip}
                  onCustomerTypeSelect={handleCustomerTypeSelect}
                  onFileChange={handleFileChange}
                  validateFields={validateFields}
                />
              </div>
            ) : (
              <div className='card mb-3 m-3' style={{ textAlign: 'center' }}>
                {serviceCategoryData?.length ? <div className='card-body'> No Schema Found</div> : <div className='card-body'> No Category Found</div>}
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  );

  const renderFocusArea = () => {
    const focusAreaData = [
      { title: 'Pain Reduction', value: 'Pain Reduction' },
      { title: 'Muscle Strengthening', value: 'Muscle Strengthening' },
      { title: 'Recovery of Function', value: 'Recovery of Function' },
      { title: 'Prevention', value: 'Prevention' },
      { title: 'Maintenance', value: 'Maintenance' },
    ];
    return (
      <Select2DropDown
        disabled={isDoctorLoggedIn}
        label="Focus Area"
        placeholder="Select Focus Area"
        data={focusAreaData}
        value={selectedFocusArea}
        onSelect={(value) => setSelectedFocusArea(value)}
        valueKey="value"
        labelKey="title"
        retuired
        style={{ width: '100%' }}
        onClean={() => setSelectedFocusArea(null)}
      />
    );
  };

  const renderCrpPackageName = () => {
    const crpPackageNameData = [
      { title: 'Trail Session', value: 'Trail Session' },
      { title: 'Mild Knee Pain Package', value: 'Mild Knee Pain Package' },
      { title: 'Moderate Knee Pain Package', value: 'Moderate Knee Pain Package' },
      { title: 'Severe Knee Pain Package', value: 'Severe Knee Pain Package' },
      { title: 'Mild Back Pain Package', value: 'Mild Back Pain Package' },
      { title: 'Moderate Back Pain Package', value: 'Moderate Back Pain Package' },
      { title: 'Severe Back Pain Package', value: 'Severe Back Pain Package' },
      { title: 'Mild Neck Pain Package', value: 'Mild Neck Pain Package' },
      { title: 'Moderate Neck Pain Package', value: 'Moderate Neck Pain Package' },
      { title: 'Severe Neck Pain Package', value: 'Severe Neck Pain Package' },
      { title: 'Mild Shoulder Pain Package', value: 'Mild Shoulder Pain Package' },
      { title: 'Moderate Shoulder Pain Package', value: 'Moderate Shoulder Pain Package' },
      { title: 'Severe Shoulder Pain Package', value: 'Severe Shoulder Pain Package' },
      { title: 'Second Trimester Weekly Package', value: 'Second Trimester Weekly Package' },
      { title: 'Third Trimester Weekly Package', value: 'Third Trimester Weekly Package' },
    ];
    return (
      <Select2DropDown
        disabled={isDoctorLoggedIn}
        label="CRP Package Name"
        placeholder="Select CRP Package Name"
        data={crpPackageNameData}
        value={selectedCrpPackageName}
        onSelect={(value) => setSelectedCrpPackageName(value)}
        valueKey="value"
        labelKey="title"
        retuired
        style={{ width: '100%' }}
        onClean={() => setSelectedCrpPackageName(null)}
      />
    );
  };

  const renderCrpType = () => {
    const crpTypeData = [
      { title: 'I-Crp', value: 'I-Crp' },
      { title: 'P-Crp', value: 'P-Crp' },
      { title: 'R-Crp', value: 'R-Crp' },
    ];

    // Use dynamicServiceData?.crp_category as the default value if it exists
    const defaultCrpType = dynamicServiceData?.crp_category || selectedCrpType;

    return (
      <Select2DropDown
        disabled={isDoctorLoggedIn}
        label="CRP Type"
        placeholder="Select CRP Type"
        data={crpTypeData}
        value={defaultCrpType}
        onSelect={(value) => {
          setSelectedCrpType(value);
          // Update dynamicServiceData to reflect the new CRP type
          if (dynamicServiceData) {
            setDynamicServiceData({
              ...dynamicServiceData,
              crp_category: value
            });
          }
        }}
        valueKey="value"
        labelKey="title"
        retuired
        style={{ width: '100%' }}
        onClean={() => {
          setSelectedCrpType(null);
          // Update dynamicServiceData to remove CRP type
          if (dynamicServiceData) {
            setDynamicServiceData({
              ...dynamicServiceData,
              crp_category: null
            });
          }
        }}
      />
    );
  };

  const renderServiceDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          disabled={pid || serviceDataForPayment?.id || isDoctorLoggedIn}
          onSearch={(text) => getServicesDropdownSearch(text)}
          showMSG={errors?.service}
          value={serviceDropdown}
          data={!serviceData.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id ? [...serviceData, selectedCatObj] : serviceData}
          isValidation
          label={'Select Service'}
          placeholder={'Select Service'}
          valueKey='id'
          labelKey='title'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            getServiceCategories(serviceId);
            setServiceDropdown(serviceId);
            setServiceSchemaData(serviceObj);
            setDynamicServiceData(serviceObj);
            setSelectedCatObj(serviceObj);
            setServiceDropdownVal(serviceId);
            setheaderServiceDetail(serviceObj);
            setServiceCategoryValue('');
            setTypeOfCrp('');
            setCrpDetails([]);
            setEstimateCost('');
          }}
        />
      </div>
    );
  };

  const renderServiceCategoryDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          disabled={pid || serviceDataForPayment?.id || isDoctorLoggedIn}
          showMSG={errors?.service_category}
          value={serviceCategoryValue}
          data={serviceCategoryData}
          label={'Service Category'}
          placeholder={'Service Category'}
          valueKey='id'
          labelKey='title'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            setTypeOfCrp('');
            setCrpDetails([]);
            setEstimateCost('');
            setClinicDropdown('');
            setDoctorDropdown('');
            setCareManagerDropdown('');
            setServiceCategoryValue(serviceId);
          }}
        />
      </div>
    );
  };
  const renderTypeCrpDropdown = () => {
    const serviceCategoryValueName = serviceCategoryData.find((item) => item?.id === serviceCategoryValue);
    const typeOfCrpList = [
      {
        title: 'Physio',
        value: 'Physio',
      },
      // {
      //   title: 'Psych',
      //   value: 'Psych',
      // },
      {
        title: 'Pain-Th',
        value: 'Pain-Th',
      },
      {
        title: 'Nutri',
        value: 'Nutri',
      },
    ];

    // serviceCategoryValueName === 'Single Spec';
    return serviceCategoryValueName?.title?.toLowerCase() === 'single spec' ? (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          disabled={pid || serviceDataForPayment?.id || isDoctorLoggedIn}
          showMSG={errors?.data?.length ? errors?.data?.find((obj) => 'crp_type' in obj)?.['crp_type'] || '' : ''}
          value={Array.isArray(typeOfCrp) && typeOfCrp.length === 1 ? typeOfCrp[0] : typeOfCrp}
          data={typeOfCrpList}
          label={'Specialty'}
          placeholder={'Specialty'}
          valueKey='title'
          labelKey='value'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            setTypeOfCrp(serviceId);
            setSpeciality(serviceId);
            // Preserve existing CRP package name and focus area if they're set
            if (isCrp || isCrpSelected || isCrpEdit) {
              if (dynamicServiceData) {
                setSelectedCrpPackageName(dynamicServiceData?.crp_package_name || selectedCrpPackageName);
                setSelectedFocusArea(dynamicServiceData?.focus_area || selectedFocusArea);
              }
            }

            setCrpDetails([
              {
                crp_type: serviceId,
                session_booked: '0',
                session_delivered: '0',
                price: '0',
                location: '',
                care_manager: '',
                care_manager_list: [],
                doctor: '',
                doctor_list: [],
              },
            ]);
          }}
        />
      </div>
    ) : (
      <div className='mb-3 capitalize'>
        <TagSelectPicker
          retuired
          disabled={pid || serviceDataForPayment?.id || isDoctorLoggedIn}
          showMSG={errors?.data?.length ? errors?.data?.find((obj) => 'crp_type' in obj)?.['crp_type'] || '' : ''}
          label={'Specialty'}
          data={typeOfCrpList}
          style={{ width: '100%' }}
          labelKey='title'
          valueKey='value'
          placeholder={'Specialty'}
          onSelect={(val, titleArray) => {
            setSpeciality(val)
            // Store current values before updating
            const currentPackageName = selectedCrpPackageName;
            const currentFocusArea = selectedFocusArea;
            const currentCustomerType = selectedCustomerType;

            setTypeOfCrp(titleArray);

            const updatedCrpDetails = JSON.parse(JSON.stringify(crpDetails));

            // Add missing objects
            titleArray.forEach((title) => {
              const exists = updatedCrpDetails.some((item) => item.crp_type === title);
              if (!exists) {
                updatedCrpDetails.push({
                  crp_type: title,
                  session_booked: '0',
                  session_delivered: '0',
                  price: '0',
                  location: '',
                  care_manager: '',
                  care_manager_list: [],
                  doctor: '',
                  doctor_list: [],
                });
              }
            });

            // Remove objects not present in titleArray
            const filteredCrpDetails = updatedCrpDetails.filter((item) => titleArray.includes(item.crp_type));

            setCrpDetails(filteredCrpDetails);

            // Restore the values after state update
            setSelectedCrpPackageName(currentPackageName);
            setSelectedFocusArea(currentFocusArea);
            setSelectedCustomerType(currentCustomerType);
          }}
          msgStyle={{ color: 'red' }}
          value={typeOfCrp}
        />
      </div>
    );
  };

  const handleCustomerTypeSelect = (value) => {
    setSelectedCustomerType(value);
    setShowReferralUpload(value === 'Govt-Insurance');
    if (value !== 'Govt-Insurance') {
      setReferralSlip(null); // Reset referral slip if not required
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setReferralSlip(file);
    } else {
      setReferralSlip(null); // Reset if no file is selected
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (showReferralUpload && !referralSlip && selectedCustomerType === 'Govt-Insurance') {
      newErrors.referralSlip = 'Referral slip is required for Government insurance.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
    //return newErrors;
  };

  const renderCustomerType = () => {
    const customerTypeData = [
      { title: 'Government insurance', value: 'Govt-Insurance' },
      { title: 'Cash patient', value: 'Cash-Patient' },
    ];
    return (
      <Select2DropDown
        disabled={isDoctorLoggedIn}
        label="Patient Type"
        placeholder="Select Patient Type"
        data={customerTypeData}
        value={selectedCustomerType}
        onSelect={handleCustomerTypeSelect}
        valueKey="value"
        labelKey="title"
        retuired
        style={{ width: '100%' }}
      />
    );
  };

  const renderAppointmentDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          disabled={pid || serviceDataForPayment?.id || isDoctorLoggedIn}
          showMSG={errors?.data?.length ? errors?.data?.find((obj) => 'appointment' in obj)?.['appointment'] || '' : ''}
          value={appointmentVal}
          data={appointmentList}
          label={'Appointment'}
          placeholder={'Appointment'}
          valueKey='id'
          labelKey={'display_key'}
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            setAppointmentVal(serviceId);
          }}
        />
      </div>
    );
  };

  const renderDoctorDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          showMSG={errors?.doctor || errors?.message}
          value={doctorDropdown}
          data={serviceDoctorData}
          label={'Select Doctor'}
          placeholder={'Select Doctor'}
          valueKey='id'
          labelKey='full_name'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            setDoctorDropdown(serviceId);
            setheaderDoctorDetail(serviceObj);
          }}
          disabled={isDoctorLoggedIn}
        />
      </div>
    );
  };

  const renderClinicDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          showMSG={errors?.location}
          value={clinicDropdown}
          data={hospitalData}
          label={'Select Hospital'}
          placeholder={'Select Hospital'}
          valueKey='id'
          labelKey='title'
          getObjData
          retuired
          onSelect={(locationId, obj) => {
            setClinicDropdown(locationId);
            getServicesClinic(locationId);
            getServicesDoctor(false, locationId);
            setheaderLocationDetail(obj);
            setCrpDetails([]);
          }}
          disabled={isDoctorLoggedIn}
        />
      </div>
    );
  };

  const renderCareManagerDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          showMSG={errors?.care_manager}
          value={careManagerDropdown}
          data={careManagersList}
          label={'Select CareManager'}
          placeholder={'Select CareManager'}
          valueKey='id'
          labelKey='full_name'
          getObjData
          retuired
          onSelect={(serviceId) => {
            setCareManagerDropdown(serviceId);
          }}
          disabled={isDoctorLoggedIn}
        />
      </div>
    );
  };

  return (
    <div className=''>
      {showService(userData)}
      <Loader show={isLoading} />
    </div>
  );
}

export default AddService;
import React, { useEffect, useState } from 'react';
import StatusBar from './StatusBar';
import { Popover, Whisper, Dropdown } from 'rsuite';
import { laptop, laptopLink, payment, paymentLink, user, userLink } from 'whealth-core-web/assets/images';
import { dateFormat, timeFormat, titlizeString } from '../Helper';
import { Link } from 'react-router-dom';

function DoctorSchedulTile(props) {
  let environment = process.env.REACT_APP_ENVIRONMENT;
  let serviceID = 3;

  const {
    className,
    appointmentDetail,
    handleAppointmentEdit,
    setOpen,
    setCancelModal,
    height,
    isBabyMd,
    setCreatedAppointment,
    userData,
    setStatusItem,
    handleRecordPayment,
  } = props;

  const renderStatus = () => {
    if (appointmentDetail?.consultation_status == 'overdue') {
      return <StatusBar className='overdue' icon='schedule' status='Overdue' />;
    } else if (appointmentDetail?.consultation_status == 'no_show') {
      return <StatusBar className='noshow' icon='cancel' status='No Show' />;
    } else if (appointmentDetail?.consultation_status == 'arrived') {
      return <StatusBar className='arrived' icon='check_circle' status='Arrived' />;
    } else if (appointmentDetail?.consultation_status == 'canceled') {
      return <StatusBar className='overdue' icon='cancel' status='Canceled' />;
    } else if (appointmentDetail?.consultation_status == 'show') {
      return <StatusBar className='visited' icon='check_circle' status='Visited' />;
    } else {
      return <StatusBar className='scheduled' icon='check_circle' status='Scheduled' />;
    }
  };

  console.log('userData: ', userData);  

  const status = [
    { title: 'Arrived', id: 'show' },
    { title: 'No Show', id: 'no_show' },
    (appointmentDetail.consultation_status == 'no_show' ||
      appointmentDetail.consultation_status == null ||
      appointmentDetail.consultation_status == 'overdue') && {
      title: 'canceled',
      id: 'canceled',
    },
  ];

  console.log('appointmentDetail: ', appointmentDetail);

  const calculateAgeInMonths = (dob) => {
    const currentDate = new Date();
    const birthDate = new Date(dob);
    const months = (currentDate?.getFullYear() - birthDate?.getFullYear()) * 12 + (currentDate?.getMonth() - birthDate?.getMonth());
    return months;
  };
  const ageInMonths = calculateAgeInMonths(appointmentDetail?.user?.date_of_birth);
  
  const canEditOrCancel = () => {
    if (appointmentDetail?.service?.title === 'procedure' || appointmentDetail?.service?.title === "followup" || appointmentDetail?.service?.title === "break" || appointmentDetail?.service?.title == "crp") {
      return (
        userData?.role !== 'ee' &&
        userData?.role !== 'ee_manager' &&
        (
          userData?.role === 'admin' ||  
          userData?.role === 'super_admin' || // Admin can always edit/cancel
          appointmentDetail?.admin?.id === userData?.id || // The creator can edit/cancel
          (userData?.role === 'health_coach_lead' && 
            (appointmentDetail?.admin?.role === 'health_coach' || 
             appointmentDetail?.admin?.role === 'revenue_manager' || 
             appointmentDetail?.admin?.role === 'admin' ||
             appointmentDetail?.admin?.role === 'super_admin' ||
             appointmentDetail?.admin?.id === userData?.id)) ||
          ((userData?.role === 'health_coach' || userData?.role === 'revenue_manager') && 
            (appointmentDetail?.admin?.role === 'admin' || appointmentDetail?.admin?.role === 'super_admin'))
        )
      );
    }
  
    switch (userData?.role) {
      case 'health_coach_lead':
        return (
          appointmentDetail?.admin?.role === 'admin' || 
          appointmentDetail?.admin?.role === 'super_admin' ||
          appointmentDetail?.admin?.role === 'health_coach' ||
          appointmentDetail?.admin?.role === 'revenue_manager' ||
          appointmentDetail?.admin?.id === userData?.id
        );
        
      case 'health_coach':
      case 'revenue_manager':
        return (
          appointmentDetail?.admin?.role === 'admin' ||
          appointmentDetail?.admin?.role === 'super_admin' ||
          appointmentDetail?.admin?.id === userData?.id 
        );
        
      case 'ee_manager':
        return (
          appointmentDetail?.admin?.role === 'ee' ||
          appointmentDetail?.admin?.id === userData?.id
        );
  
      case 'ee':
        return (
          appointmentDetail?.admin?.id === userData?.id
        );
  
      case 'admin':
      case 'super_admin':
        return true;
  
      default:
        return false;
    }
  };
  
  const canRecordOrMarkArrived = () => {
    if (appointmentDetail?.service?.title === 'procedure' || appointmentDetail?.service?.title === "followup" || appointmentDetail?.service?.title === "break" || appointmentDetail?.service?.title == "crp") {
      return (
        userData?.role !== 'ee' &&
        userData?.role !== 'ee_manager' &&
        (
          userData?.role === 'admin' ||  
          userData?.role === 'super_admin' || // Admin can always edit/cancel
          appointmentDetail?.admin?.id === userData?.id || // The creator can edit/cancel
          (userData?.role === 'health_coach_lead' && 
            (appointmentDetail?.admin?.role === 'health_coach' || 
             appointmentDetail?.admin?.role === 'revenue_manager' || 
             appointmentDetail?.admin?.role === 'admin' ||
             appointmentDetail?.admin?.role === 'super_admin' ||
             appointmentDetail?.admin?.id === userData?.id)) ||
          ((userData?.role === 'health_coach' || userData?.role === 'revenue_manager') && 
            (appointmentDetail?.admin?.role === 'admin' || appointmentDetail?.admin?.role === 'super_admin'))
        )
      );
    }

    if (appointmentDetail?.channel === "Hum Pum") {
      return userData?.role === 'health_coach' || userData?.role === 'health_coach_lead';
    }
  
    switch (userData?.role) {
      case 'admin':
      case 'super_admin':
        return true; // Admin can always record/mark arrived
  
      case 'health_coach':
      case 'revenue_manager':
        return (
          appointmentDetail?.admin?.role === 'admin' ||
          appointmentDetail?.admin?.role === 'super_admin' ||
          appointmentDetail?.admin?.role === 'ee' ||
          appointmentDetail?.admin?.role === 'ee_manager' ||
          appointmentDetail?.admin?.id === userData?.id // Only if they created the appointment
        ); 
  
      case 'health_coach_lead':
        return (
          appointmentDetail?.admin?.role === 'health_coach' ||
          appointmentDetail?.admin?.role === 'revenue_manager' ||
          appointmentDetail?.admin?.role === 'ee' ||
          appointmentDetail?.admin?.role === 'ee_manager' ||
          appointmentDetail?.admin?.role === 'admin' ||
          appointmentDetail?.admin?.role === 'super_admin' ||
          appointmentDetail?.admin?.id === userData?.id // Their own appointments
        );
  
      case 'ee':
      case 'ee_manager':
        return false; // Explicitly restricted
  
      default:
        return false; // Default case restricts other roles
    }
  };
  
  const schedulPopover = (
    <Popover visible className='schedulePopover'>
      <div className='popoverPart'>
        <div className='schedulePopover-head'>
          <div className='tileName'>
            <Link to={userData?.role !== 'ee' ? `/patient/${appointmentDetail?.user.id}/dashboard` : null}>
              {appointmentDetail?.user?.full_name} ({appointmentDetail?.user?.gender && appointmentDetail?.user?.gender[0].toUpperCase()},{' '}
              {appointmentDetail?.user?.date_of_birth && isBabyMd && ` ${ageInMonths} M`}
              {!isBabyMd && appointmentDetail?.user?.age})
            </Link>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='number'>{appointmentDetail?.user?.phone}</div>
            {!isBabyMd && <div className='number'>{renderStatus()}</div>}
            {appointmentDetail?.consultation_status !== 'canceled' && isBabyMd && (
              <div className='number'>
                <Dropdown
                  className={`capitalize pointer patient-list-show-badge ${appointmentDetail.consultation_status}`}
                  title={
                    <span className='capitalize'>
                      {titlizeString(appointmentDetail.consultation_status === 'show' ? 'Arrived' : appointmentDetail.consultation_status) || 'booked'}
                    </span>
                  }
                >
                  {status?.map((statusItem) => (
                    <Dropdown.Item
                      onClick={() => {
                        if (['show', 'no_show', 'arrived'].includes(statusItem.id)) {
                          setCreatedAppointment(appointmentDetail);
                          setOpen(true);
                          setStatusItem(statusItem);
                        } else if (statusItem.id === 'canceled') {
                          setCreatedAppointment(appointmentDetail);
                          setCancelModal(true);
                          setStatusItem(statusItem);
                        }
                      }}
                    >
                      {statusItem.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown>
              </div>
            )}
          </div>
          <div>Created By: {appointmentDetail?.admin?.full_name}</div>
        </div>
  
        <div className='schedulePopover-body'>
          <div className='d-flex justify-content-between schedule-part'>
            <div className='d-flex flex-column justify-content-start'>
              <div className='time'>
                {dateFormat(appointmentDetail?.consult_datetime)}, {timeFormat(appointmentDetail?.consult_datetime)} -{' '}
                {timeFormat(appointmentDetail?.consult_end_datetime)}
              </div>
              <span>With {appointmentDetail?.consultant?.full_name}</span>
              {appointmentDetail?.service?.title && (
                <div className='d-flex justify-content-start mt-1'>
                  <div className='follow'>{appointmentDetail?.service?.title}</div>
                </div>
              )}
            </div>
  
            {/* Edit & Cancel Buttons */}
            {appointmentDetail?.consultation_status !== 'canceled' &&
              appointmentDetail?.patient_attendance_status !== 'show' &&
              appointmentDetail?.patient_attendance_status !== 'no_show' && (
                <div className='d-flex justify-content-center align-items-center flex-column'>
                  {(appointmentDetail?.channel === "Hum Pum"
                    ? userData?.role === 'ee_manager'
                    : canEditOrCancel()) && (
                      <div
                        onClick={() => handleAppointmentEdit(appointmentDetail?.id)}
                        className='d-flex justify-content-center align-items-center popover-btn'
                      >
                        <span className='material-icons-outlined'>mode_edit_outline</span> Edit
                      </div>
                  )}
                  
                  {(appointmentDetail?.channel === "Hum Pum"
                    ? userData?.role === 'ee_manager'
                    : canEditOrCancel()) && (
                      <div
                        onClick={() => {
                          setCreatedAppointment(appointmentDetail);
                          setCancelModal(true);
                        }}
                        className='d-flex justify-content-center align-items-center popover-btn'
                      >
                        <span className='material-icons-outlined'>close</span> Cancel
                      </div>
                  )}
                </div>
            )}
          </div>
  
          {/* Join Button (unchanged) */}
          {appointmentDetail?.consult_type === 'virtual' && (
            <div className='d-flex justify-content-between schedule-part'>
              <div className='d-flex flex-column justify-content-start'>
                <span>
                  <img src={laptopLink} /> Online Consultation
                </span>
              </div>
              {((userData?.role !== 'ee' && userData?.role !== 'ee_manager') ||
                appointmentDetail?.admin?.id === userData?.id ||
                (userData?.role === 'ee_manager' && (appointmentDetail?.admin?.role === 'ee' || appointmentDetail?.admin?.role === 'ee_manager'))) &&
                appointmentDetail?.consultation_status !== 'canceled' &&
                appointmentDetail?.meeting_link && (
                <a target='_blank' href={appointmentDetail?.meeting_link} className='d-flex flex-column align-items-center'>
                  <div className='d-flex justify-content-center align-items-center popover-btn disabled'>Join</div>
                </a>
              )}
            </div>
          )}
  
          {/* Record & Mark Arrived */}
          {canRecordOrMarkArrived() && (
            <div className='d-flex justify-content-center align-items-end flex-column mt-2'>
              <div
                className='d-flex justify-content-center align-items-end popover-btn active uniform-btn'
                onClick={() => {
                  setCreatedAppointment(appointmentDetail);
                  setOpen(true);
                  setStatusItem('');
                }}
              >
                Record
              </div>
              <div
                className='d-flex justify-content-center align-items-end popover-btn uniform-btn'
                onClick={() => {
                  const confirmMarkArrived = window.confirm('Are you sure you want to mark this appointment as Arrived?');
                  if (confirmMarkArrived) {
                    handleRecordPayment(appointmentDetail?.id, { patient_attendance_status: 'arrived' });
                  }
                }}
              >
                Mark Arrived
              </div>
            </div>
          )}
        </div>
      </div>
    </Popover>
  );
  // const appointmentPopover = (
  //   <div style={{ height: 100, position: 'absolute' }}>
  //     <Popover visible>
  //       <div className='d-flex w-100 flex-column appointmentPopover schedulButton'>
  //         <div className='reusableBtnActive  reusableBtn text-nowrap  w-100 mb-2'>+ Add New Appointment</div>
  //         <div className='w-100 borderBtn mb-2 text-nowrap'>Mark As Unavailable</div>
  //       </div>
  //     </Popover>
  //   </div>
  // );

  // const actions = [
  //   { first: paymentLink, second: payment },
  //   { first: laptopLink, second: laptop },
  //   { first: user, second: userLink },
  // ];

  // const HoverImage = ({ first, second }) => {
  //   const [firstImage, setFirstImage] = useState(first);
  //   return (
  //     <div className='scheduleIcon'>
  //       <img src={firstImage} onMouseEnter={() => setFirstImage(second)} onMouseLeave={() => setFirstImage(first)} />

  //     </div>
  //   );
  // };

  return (
    <>
      <div className={`d-flex scheduleTile ${className}`} style={{ justifyContent: 'space-between', padding: '1px 3px' }}>
        <div style={{ width: '90%' }}>
          <Whisper placement='auto' trigger='hover' speaker={schedulPopover} controlId='control-id-hover-enterable' enterable>
            <span
              className='tileName'
              style={{
                color: '#333',
                fontFamily: 'Inter',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '12px',
              }}
            >
              {appointmentDetail?.user?.full_name} ({appointmentDetail?.user?.gender && appointmentDetail?.user?.gender[0].toUpperCase()},{' '}
              {appointmentDetail?.user?.date_of_birth && isBabyMd && ` ${ageInMonths} M`}
              {!isBabyMd && appointmentDetail?.user?.age})
            </span>
          </Whisper>
          {height > 32 && <p className='p-0 m-0'>{appointmentDetail?.user?.phone}</p>}
          {height > 40 && <p className='p-0 m-0'>Created By : {appointmentDetail?.admin?.full_name}</p>}
          {height > 60 && (
            <div className='d-flex justify-content-between'>
              {renderStatus()}
              {/* <div className='d-flex'>
              {actions.map((action, index) => (
                <HoverImage key={index} first={action.first} second={action.second} />
              ))}
            </div> */}
              {/* <div className='d-flex'>
            <div className={`scheduleIcon me-1 ${appointmentDetail.amount!=='null'?'active':''}`}>
                {!appointmentDetail.amount!=='null'?<img src={payment}/>:
                <img src={paymentLink}/>}
              </div>
              <div className={`scheduleIcon me-1 ${appointmentDetail.consult_type=='physical'?'active':''}`}>
                {!appointmentDetail.consult_type=='virtual'?<img src={laptop}/>:
                <img src={laptopLink}/>}
              </div>
              <div className={`scheduleIcon ${appointmentDetail.consult_type=='virtual'?'active':''}`}>
                {!appointmentDetail.consult_type=='virtual'?<img src={user}/>:
                <img src={userLink}/>}
              </div>
            </div> */}
            </div>
          )}
        </div>
        {appointmentDetail?.service?.title.toLowerCase() === 'procedure' ||
          appointmentDetail?.service?.title.toLowerCase() === 'consult' ||
          appointmentDetail?.service?.title.toLowerCase() === 'follow up' ||
          appointmentDetail?.service?.title.toLowerCase() === 'break' ||
          appointmentDetail?.service?.title.toLowerCase() === 'care plan' ? (
          <div
            style={{
              backgroundColor: '#DCDFE3',
              height: '14px',
              width: '14px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 0',
              borderRadius: '50%',
            }}
          >
            <p
              style={{
                color: '#243B86',
                fontFamily: 'Inter',
                fontSize: '6px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '0px',
              }}
            >
              {appointmentDetail?.service?.title.toLowerCase() === 'procedure' && 'P'}
              {appointmentDetail?.service?.title.toLowerCase() === 'consult' && 'C'}
              {appointmentDetail?.service?.title.toLowerCase() === 'follow up' && 'F'}
              {appointmentDetail?.service?.title.toLowerCase() === 'break' && 'B'}
              {appointmentDetail?.service?.title.toLowerCase() === 'care plan' && 'CP'}
            </p>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default DoctorSchedulTile;
